import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import SearchIcon from '@mui/icons-material/Search';
import HelpBlock from 'components/HelpBlock';
import searchIcon from 'assets/images/search_icon.svg'
import './search_bar.scss';

const SearchBar = (props) => {
  const [searchValue, setSearchValue] = useState("");
  const [showSearchIcon, setShowSearchIcon] = useState(true);
  const [errors, setErrors] = useState({});

  const location = useLocation();
  const locSearch = new URLSearchParams(location.search);

  useEffect(() => {
    setSearchValue("")
  }, [locSearch.get('active_tab_id')])


  return (
    <div className="order-search-form">
      <form className="search-bar-form-wrapper" id="search-form" onSubmit={(e) => props.onSubmit(e, searchValue)}>
        <div className="form-group search-bar-form-group">
          <div className="search-bar">
            <img src={searchIcon} className='search-bar-icon'></img>
            <input onFocus={() => setShowSearchIcon(false)} onBlur={() => setShowSearchIcon(true)} type="text" className="form-control" value={searchValue} onChange={(e) => setSearchValue(e.target.value)} placeholder={props.placeholder}></input>
            <button type="submit" form="search-form" className="search-bar-button">Search</button>
          </div>
          <HelpBlock value={errors.searchValue} />
        </div>
      </form>
    </div>
  )
}

export default SearchBar;