import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect, withRouter } from 'react-router-dom';
import { compose} from "redux";
import lodash from 'lodash';

import queryString from 'query-string';
import { signInUser, getTermsAndConditionsUrl, hideLoginErrorModalAction } from 'common/authThunkActions';
import { defaultLandingPageRouteGenerator } from 'common/authGuard';
import { getItemFromStorage } from 'services/storage';
import { showToaster } from 'common/toasterActions';
import isEmpty from 'lodash/isEmpty';

import validate, { clearErrorsForField } from 'common/validator';
import HelpBlock from 'components/HelpBlock';

import Modal from 'react-bootstrap/lib/Modal';
import InviteCode from 'patientApp/containers/InviteCode';
import LightboxModal from 'components/LightboxModal';

import FloatingLabelInput from 'components/FloatingLabelInput';
import PreLoginFooter from 'components/PreLoginFooter';
import WelcomeSection from '../Login/WelcomeSection';
import CareGuideProImage from '../../assets/images/CGP_logo_vertical.png';

import '../Login/login.scss';

import AppStore from '../../assets/images/app-store.png';
import GooglePlayStoreImg from '../../assets/images/google-play.png';
import { APP_STORE_URL, GOOGLE_PLAY_STORE_URL } from '../../services/constants';
import WelcomeSectionForPasswordLogin from 'containers/Login/WelcomeSectionForPasswordLogin';

const validationConfig = {
  fields: ['emailOrMobileNumber', 'password'],
  rules: {
    emailOrMobileNumber: [
      { rule: 'isEmail', message: 'Mobile Number or Email is invalid' },
      { rule: 'isRequired', message: 'Mobile Number or Email is required' },
    ],
    password: [{ rule: 'isRequired', message: 'Password is required' }],
  },
};

const phoneRule = [
  { rule: 'isPhone', message: 'Mobile Number or Email is invalid' },
  { rule: 'isRequired', message: 'Mobile Number or Email is required' },
];

const emailRule = [
  { rule: 'isEmail', message: 'Mobile Number or Email is invalid' },
  { rule: 'isRequired', message: 'Mobile Number or Email is required' },
];

class LoginWithPassword extends React.Component {
  constructor(props) {
    super(props);

    const queryStringObject = props.location.search
      ? queryString.parse(props.location.search)
      : {};
    this.state = {
      emailOrMobileNumber: props.location.state
        ? props.location.state.emailId
        : '',
      password: '',
      type: 'password',
      errors: {},
      showInviteCodeModal: false,
      showTermsAndConditionsModal: false,
      acceptTermAndConditionValue: false,
      shouldShowPatientLoginModal: false,
      errorInTermAccept: false,
      showSignInForm: queryStringObject.show_signin_form === '1',
      showVideoModal: false,
      videoUrl: 'https://www.youtube.com/embed/gToQePm2TxU?rel=0&autoplay=1',
      showContactviewModal: false,
      redirectUri: queryStringObject.redirect_uri,
    };
    this.onFormValidationSuccess = this.onFormValidationSuccess.bind(this);
  }

  onEmailChange = (e) => {
    this.setState({
      emailOrMobileNumber: e.target.value.trim(),
      errors: clearErrorsForField(this.state.errors, 'emailOrMobileNumber'),
    });
  };

  onPasswordChange = (e) => {
    const password = e.target.value;
    this.setState({
      password: password.trim(),
      errors: clearErrorsForField(this.state.errors, 'password'),
      type: password.length === 0 ? 'password' : this.state.type,
    });
  };

  onForgotPasswordEvent = (e) => {
    e.preventDefault();
    this.props.history.push('/forgot-password');
  };

  onFormValidationSuccess = () => {
    this.setState({
      errors: {},
    });
    this.props.getTermsAndConditionsUrl(this.state.emailOrMobileNumber);
    this.setState({
      showTermsAndConditionsModal: true,
    });
  };

  onFormValidationFailure = (errors) => {
    this.setState({ errors });
  };

  onSignUpClick = () => {
    this.setState({
      showInviteCodeModal: true,
    });
  };

  onContactUsClick = () => {
    this.setState({
      showContactviewModal: true,
    });
  };

  onNotNowClick = () => {
    this.setState({
      showTermsAndConditionsModal: false,
    });
  }

  onIAgreeClick = () => {
    this.setState({
      showTermsAndConditionsModal: false,
      shouldShowPatientLoginModal: true,
    });

    const { emailOrMobileNumber, password } = this.state;
    const params = {
      password,
    };
    if (emailOrMobileNumber.includes('@')) {
      params.email = emailOrMobileNumber;
    } else {
      const contactNumber = emailOrMobileNumber.replace(/[^a-zA-z0-9]/g, '');
      params.contact_number = contactNumber;
    }
    params.has_accepted_terms_and_condition = true;
    params.tnc_url = this.props.termsAndConditionsUrl;
    this.props.signInUser(params);
  }

  acceptTermAndCondition = () => {
    this.setState({
      acceptTermAndConditionValue: !this.state.acceptTermAndConditionValue,
      errorInTermAccept: false,
    });
  };

  showSignInForm = () => {
    this.setState({
      showSignInForm: true,
    });
  };

  backToLogin = () => {
    this.setState({
      showSignInForm: false,
    });
    this.props.history.replace('/signin-with-password');
  };

  showHide = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (this.state.password.length > 0) {
      this.setState({
        type: this.state.type === 'input' ? 'password' : 'input',
      });
    }
  };

  submitForm = (e) => {
    e.preventDefault();
    if (!this.state.emailOrMobileNumber.includes('@')) {
      validationConfig.rules.emailOrMobileNumber = phoneRule;
    } else {
      validationConfig.rules.emailOrMobileNumber = emailRule;
    }
    validate(
      validationConfig,
      this.state,
      this.onFormValidationFailure,
      this.onFormValidationSuccess
    );
  };

  closeInviteCodeModal = () => {
    this.setState({
      showInviteCodeModal: false,
    });
  };

  closeTermsAndConditionsModal = () => {
    this.setState({
      showTermsAndConditionsModal: false,
      shouldShowPatientLoginModal: false,
    });
  };

  closeShowPatientLoginModal = () => {
    this.props.hideLoginErrorModal();
  };

  hideContactviewModal = () => {
    this.setState({
      showContactviewModal: false,
    });
  };

  render() {
    if (this.props.isSignedIn) {
      if (this.state.redirectUri) {
        return <Redirect to={{ pathname: this.state.redirectUri, hash: this.props.location.hash }} replace />;
      }
      return <Redirect to={defaultLandingPageRouteGenerator()} replace />;
    }
    const shouldShowTermsAndConditionsModal = this.state.showTermsAndConditionsModal && !lodash.isEmpty(this.props.termsAndConditionsUrl);
    const shouldShowPatientLoginModal = this.props.shouldShowPatientLoginModal;
    const supportEmail = process.env.REACT_APP_SUPPORT_EMAIL;

    return (
      <div className="signin-container">
        <WelcomeSectionForPasswordLogin/>
        <div className="col-md-6 hidden-sm hidden-xs login-form-container no-padding">
          <div className="login-form-view">
            <div className="header text-center">Welcome</div>
            <div className="sub-header text-center">
              Sign in to CareGuidePro&trade;
            </div>

            <form name="form" onSubmit={this.submitForm}>
              <div
                className={`form-group ${
                  this.state.errors.emailOrMobileNumber !== undefined
                    ? 'has-error'
                    : ''
                }`}
              >
                <FloatingLabelInput
                  type="text"
                  className="form-control"
                  value={this.state.emailOrMobileNumber}
                  label="Mobile Number or Email"
                  onChange={this.onEmailChange}
                  name="emailOrMobileNumber"
                />
                <HelpBlock value={this.state.errors.emailOrMobileNumber} />
              </div>
              <div
                className={`form-group pos-rel ${
                  this.state.errors.password !== undefined ? 'has-error' : ''
                }`}
              >
                <FloatingLabelInput
                  type={this.state.type}
                  label="Password"
                  className="form-control"
                  name="password"
                  value={this.state.password}
                  onChange={this.onPasswordChange}
                />
                <span
                  role="button"
                  tabIndex="-1"
                  className={`password-show ${
                    this.state.password.length === 0 ? 'disabled' : ''
                  }`}
                  onClick={this.showHide}
                >
                  {this.state.type === 'input' ? 'Hide' : 'Show'}
                </span>
                <HelpBlock value={this.state.errors.password} />
              </div>

              <div className="clearfix"></div>
              <div className="forgot-password">
                <span
                  role="button"
                  tabIndex="0"
                  onClick={this.onForgotPasswordEvent}
                >
                  Forgot Password?
                </span>
              </div>

              <div className="text-center">
                <button
                  className="btn btn-primary"
                  onClick={this.onSubmitClick}
                >
                  Sign In
                </button>
              </div>
            </form>
          </div>
          <PreLoginFooter />
        </div>

        <div className="visible-sm visible-xs col-xs-12 mobile-view-container no-padding">
          {this.state.showSignInForm ? (
            <div className="col-xs-12 no-padding signin-form-container">
              <div className="col-xs-12 page-title">
                <i
                  className="icon icon-font-a-left-chevron back-to-login"
                  onClick={this.backToLogin}
                />
                Sign In
              </div>
              <div className="col-sm-offset-1 col-sm-10 col-xs-12 page-form">
                <form name="form" onSubmit={this.submitForm}>
                  <div
                    className={`form-group ${
                      this.state.errors.emailOrMobileNumber !== undefined
                        ? 'has-error'
                        : ''
                    }`}
                  >
                    <FloatingLabelInput
                      type="text"
                      className="form-control"
                      value={this.state.emailOrMobileNumber}
                      label="Mobile Number or Email"
                      onChange={this.onEmailChange}
                      name="emailOrMobileNumber"
                    />
                    <HelpBlock value={this.state.errors.emailOrMobileNumber} />
                  </div>
                  <div
                    className={`form-group pos-rel ${
                      this.state.errors.password !== undefined
                        ? 'has-error'
                        : ''
                    }`}
                  >
                    <FloatingLabelInput
                      type={this.state.type}
                      label="Password"
                      className="form-control"
                      name="password"
                      value={this.state.password}
                      onChange={this.onPasswordChange}
                    />
                    <span
                      tabIndex="-1"
                      role="button"
                      className={`password-show ${
                        this.state.password.length === 0 ? 'disabled' : ''
                      }`}
                      onClick={this.showHide}
                    >
                      {this.state.type === 'input' ? 'Hide' : 'Show'}
                    </span>
                    <HelpBlock value={this.state.errors.password} />
                  </div>

                  <div className="clearfix"></div>
                  <div className="forgot-password">
                    <span
                      role="button"
                      tabIndex="0"
                      className="cursor-pointer"
                      onClick={this.onForgotPasswordEvent}
                    >
                      Forgot Password?
                    </span>
                  </div>

                  <div className="text-center">
                    <button
                      className="btn btn-primary"
                      onClick={this.onSubmitClick}
                    >
                      Sign In
                    </button>
                  </div>
                </form>
              </div>
            </div>
          ) : (
            <div className="col-xs-12 no-padding page-container">
              <div className="col-xs-12 background-image-container">
                <div className="col-xs-12 no-padding">
                  <div className="col-xs-6 header-content float-right no-padding">
                      Knowing what&apos;s ahead. That&apos;s powerful.
                  </div>
                </div>
              </div>
              <div className="col-xs-12 portal-container">
                <div className="col-xs-12 patient-learn-more-container">
                  <div className="col-xs-12 signin-signup-button-row-container text-center">
                    <div className="col-xs-12 col-sm-6">
                      <button
                        className="btn btn-primary"
                        onClick={this.showSignInForm}
                      >
                          Sign In
                      </button>
                    </div>
                    </div>
                  <div className="clearfix"></div>
                  <div className="col-xs-offset-1 col-xs-10">
                    <div className="row-sep"></div>
                  </div>
                  <div className="clearfix"></div>
                  <i className="icon icon-font-a-profile"></i>
                  <div className="col-xs-offset-1 col-sm-11">
                    <span>
                        As a patient or caregiver, CareGuidePro&trade; gives you
                        visibility and tools to navigate the process that lies
                        ahead.
                    </span>
                    <span
                      className="link cursor-pointer"
                      onClick={this.showPatientVideo}
                    >
                        Patients learn more
                    </span>
                  </div>
                </div>
                <div className="col-xs-12 provider-learn-more-container">
                  <i className="icon icon-font-a-stethoscope"></i>
                  <div className="col-xs-offset-1 col-sm-11">
                    <span>
                        As a provider, CareGuidePro gives you visibility and
                        tools to navigate which patients are on-and-off-track, so
                        you can intervene to help them achieve healthy outcomes.
                    </span>
                    <span
                      className="link cursor-pointer"
                      onClick={this.showProviderVideo}
                    >
                        Providers learn more
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-xs-12 no-padding mobile-footer">
                <div
                  className="contact-us cursor-pointer"
                  onClick={this.onContactUsClick}
                >
                    Contact us
                </div>
                <div className="copy-right-container">
                  <div className="copy-right-label">
                      &copy; Higgs Boson Inc, All Rights Reserved
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        <LightboxModal show={this.props.isRequestInProgress} />

        <Modal
          show={shouldShowTermsAndConditionsModal}
          onHide={this.closeTermsAndConditionsModal}
          container={document.body}
          aria-labelledby="contained-modal-title"
          className="primary-modal terms-and-conditions-modal"
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title">
              Agree to Terms & Conditions
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="text-center term-and-condition-container">
              <div className="term-and-condition-label">
                I have read and agree to the
              </div>
              <div className="term-and-condition-link">
                <a
                  rel="noopener"
                  href={`${this.props.termsAndConditionsUrl}`}
                  target="_blank"
                >
                  <span>
                    Terms and Conditions
                  </span>
                </a>
              </div>
              <div className="text-center">
                <button
                  className="btn btn-default"
                  onClick={this.onNotNowClick}
                >
                  Not Now
                </button>
              </div>
              <div>
                <button
                  className="btn btn-primary"
                  onClick={this.onIAgreeClick}
                >
                  I Agree
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        <Modal
          show={shouldShowPatientLoginModal}
          onHide={this.closeShowPatientLoginModal}
          container={document.body}
          aria-labelledby="contained-modal-title"
          className="primary-modal patient-login-modal  "
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title">
              Please Download the Mobile App
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="patient-login-container">
              <div className="mobile-app-download-view-label">
                Our apologies, the CareGuidePro patient experience is only available via mobile app.
              </div>
            </div>
            <div className="mobile-app-download-view">
              <div className="mobile-app-download-text">We are available on both Android and Apple devices. Search for 'CareGuidePro' in your App
              Store on your mobile device to download.</div>
            </div>
            <div>
              <center>
              <img className="img-responsive mobile-app-download-client-image"
                alt="CareGuidePro"
                src={CareGuideProImage}
                id="cgp-vertical-image"
              />
              </center>
            </div>
            <div><hr/></div>
            <div className="download-app-container">
              <center>
                <a href={APP_STORE_URL}>
                  <img alt='Get it on Apple Store' className="mobile-appstore-download-image" src={AppStore}/>
                </a>
                <a href={GOOGLE_PLAY_STORE_URL}>
                  <img alt='Get it on Google Play' className="mobile-appstore-download-image" src={GooglePlayStoreImg} />
                </a>
              </center>
            </div>
          </Modal.Body>
        </Modal>

        <Modal
          show={this.state.showInviteCodeModal}
          onHide={this.closeInviteCodeModal}
          container={document.body}
          aria-labelledby="contained-modal-title"
          className="primary-modal"
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title">
              Enter Invite Code
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <InviteCode />
          </Modal.Body>
        </Modal>

        <Modal
          show={this.state.showContactviewModal}
          onHide={this.hideContactviewModal}
          container={document.body}
          aria-labelledby="contained-modal-title"
          className="primary-modal"
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title">Contact Us</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="footer-content-container">
              <div className="text">
                Feel free to contact us via email or phone number.
              </div>
              <div className="text">
                We would be happy to address your queries.
              </div>
              <div className="phone-container">
                <i className="icon icon-font-a-contact"></i>
                <span className="phone">(866) 70 - HIGGS</span>
              </div>
              <div className="email-container">
                <i className="icon icon-font-a-email"></i>
                <span className="email">{supportEmail}</span>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

LoginWithPassword.propTypes = {
  signInUser: PropTypes.func,
  getTermsAndConditionsUrl: PropTypes.func,
  history: PropTypes.object,
  isSignedIn: PropTypes.bool.isRequired,
  isRequestInProgress: PropTypes.bool.isRequired,
  location: PropTypes.object,
  termsAndConditionsUrl: PropTypes.string,
};

const mapStateToProps = (state) => ({
  userType: state.currentUser.attributes.type,
  isRequestInProgress: state.currentUser.isLoading,
  termsAndConditionsUrl: state.currentUser.termsAndConditionsUrl,
  isInviteCodeValid: state.patientProcedures.isInviteCodeValid,
  shouldShowPatientLoginModal: state.currentUser.shouldShowPatientLoginModal,
  isSignedIn:
    state.currentUser.isSignedIn || !isEmpty(getItemFromStorage('token')),
});

const mapDispatchToProps = (dispatch) => ({
  signInUser: (params) => dispatch(signInUser(params)),
  showToaster: (params) => dispatch(showToaster(params)),
  getTermsAndConditionsUrl: (emailOrContactNumber) => dispatch(getTermsAndConditionsUrl(emailOrContactNumber)),
  hideLoginErrorModal: () => dispatch(hideLoginErrorModalAction()),
});

export default compose(withRouter, connect(
  mapStateToProps,
  mapDispatchToProps
))(LoginWithPassword);
