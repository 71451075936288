import React from 'react';
import '../patientRegister.scss';
import Logo from 'assets/images/mms.png';
import HeroImage from 'assets/images/patient-register-hero.png';

export default function WelcomeView(props) {
  return (
    <div className="welcome-view-container">
      <div className="image-container">
        <img src={HeroImage} alt="Patient Register Hero" />
      </div>
      <div className="contents-container ">
        <div className="row welcome-section">
          <div className="col-xs-12">
            <h3>Welcome to</h3>
            <img src={Logo} className="mms-welcome-logo" alt="MMS" />
          </div>
        </div>
        <div className="row content">
          <div className="col-xs-12">
            <h2>
              SUFFERING FROM
              <br />
              CHRONIC PAIN                            
            </h2>
            <div className="content-text font-size-md">
              Oral opioids are not the only option for treating your chronic pain. Download the CareGuidePro™ app to learn more about Medtronic SCS (spinal cord stimulation) – a treatment option for chronic pain. This free app offers education and connects you to local specialists.              
            </div>
            <div className="button-container text-align-center">
              <button
                className="join-btn btn btn-primary"
                onClick={props.onNext}
              >
                Download the App 
              </button>              
            </div>
          </div>
        </div>
      </div>      
    </div>
  );
}
