import React, { useState, useCallback } from "react";
import PhysicalIndicators from "./PhysicalIndicators";
import BlackCheckBox from "../../../assets/images/check_box_black.png";
import "./component-style.scss";
import PainReductionChart from "./PainReductionChart";

function TrialSummaryQuickView(props) {
  const { data, getChartColors, getChartData } = props;

  const TRIAL_PROCEDURE_LABELS = [
    "Day 2",
    "Day 3",
    "Day 4",
    "Day 5",
    "Day 6",
    "Day 7",
    "Day 8",
    "Day 9",
    "Day 10",
    "Last",
  ];

  // For Trial-DTM Surveys are only till Day 7 and then Last Day's surveys and for Trial-Standard 10 days surveys are present.
  // This is achieved by using is_last_day, as pain reduction %'s are sent sequentially from server
  const formatDataAndLabels = useCallback((data, labelsArray) => {
    const newPainReductionPercentages = data?.pain_reduction?.pain_reduction_percentages;
    const newLabels = labelsArray.slice(0, data?.pain_reduction?.trial_survey_duration)

    newLabels[newLabels.length - 1] = labelsArray[labelsArray.length - 1];

    let formattedData = {
      ...data,
      "pain_reduction": {
          ...data?.pain_reduction,
          "pain_reduction_percentages": newPainReductionPercentages
      }
    };
    return {labels: newLabels, data: formattedData}
  }, [data]);

  return (
    <>
      <div className="summary-page-divider" />
      <div className="patient-goals-wrapper">
        <p className="patient-goals-title title-font-regular">Patient Goals</p>
        {data.patient_goals.length > 0 ? (
          data.patient_goals.map((goal, index) => {
            return (
              <p className="patient-goal" key={index}>
                <img src={BlackCheckBox} alt="Checked" style={{ maxWidth: "12px", marginRight: "4px" }}/>
                <span className="text-font-small">{goal}</span>
              </p>
            );
          })
        ) : (
          <p className="text-font-small">No Goals Reported</p>
        )}
      </div>
      <PainReductionChart data={formatDataAndLabels(data, TRIAL_PROCEDURE_LABELS).data} labels={formatDataAndLabels(data, TRIAL_PROCEDURE_LABELS).labels} getChartData={getChartData} getChartColors={getChartColors}/>
      <PhysicalIndicators physicalIndications={data.other_params} />
    </>
  );
}

export default TrialSummaryQuickView;
