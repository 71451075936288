import React from 'react';
import PropTypes from 'prop-types';
import '../patientRegister.scss';

export default function BackButton(props) {
  return (
    <div className="button-container text-align-center">
      <button
        className="back-btn btn btn-default"
        onClick={props.onBack}
      >
        Back
      </button>
    </div>
  );
}

BackButton.propTypes = {
  onBack: PropTypes.func.isRequired,
};
