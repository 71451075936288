import React, { useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import Header from "./Components/Header";
import ProgressBar from "./Components/ProgressBar";
import { useOrderScreens } from "./config";
import "./pats_orders.scss";
import ShareReportErrorBoundary from "containers/ShareReportErrorBoundary";
import LightboxModal from "components/LightboxModal";
import { changeSearchParams } from "utils/changeSearchParams";
import { STATE } from "enums";
import Toasters from 'components/MobileComponents/MobileToasters/Toasters'
import MobileLoader from "components/MobileComponents/MobileLoader";


const PatientPatsOrders = () => {
  const [activeStep, setActiveStep] = useState(1);
  const { orderScreens, isLoading } = useOrderScreens();

  const location = useLocation();
  const history = useHistory();


  const searchParams = new URLSearchParams(location.search);

  const updatePageInUrl = (pageNumber) => {
    searchParams.set("page_number", pageNumber)
    history.push({
      pathname: location.pathname,
      search: `?${searchParams.toString()}`,
      state: {
        page: activeStep
      }
    });
  }

  useEffect(() => {
    let pageNumber = parseInt(searchParams.get("page_number"));
    if (pageNumber) {
      setActiveStep(pageNumber)
    }

    const popstateHandler = (e) => {
      if (Number.parseInt(searchParams.get('page_number'), 10) === 1) changeSearchParams(STATE.DEFERRED);
    }

    window.addEventListener('popstate', popstateHandler);

    return () => {
      window.removeEventListener('popstate', popstateHandler);
    };
  }, [searchParams.get('page_number')])

  useEffect(() => {
    let pageNumber = searchParams.get("page_number");
    if (!pageNumber) {
      updatePageInUrl(1)
    }
    window.history.pushState(null, document.title, window.location.href);
  }, []);

  const moveBackward = (onBackCallBack) => {

    if (activeStep === 1) {
      changeSearchParams(STATE.DEFERRED)
    }
    else {
      history.goBack();
    }

    if (onBackCallBack)
      onBackCallBack()
  }

  const updateActiveStep = (step = null) => {
    if (step && step <= orderScreens.length) {
      updatePageInUrl(step)
    } else if (activeStep === orderScreens.length) {
      updatePageInUrl(1);
    } else {
      updatePageInUrl(activeStep + 1);
    }
  };

  const moveForward = () => {
    let currentPage = parseInt(searchParams.get("page_number"))
    if (currentPage === orderScreens.length) {
      updatePageInUrl(1)
    }
    else {
      updatePageInUrl(currentPage + 1)
    }
  }

  const renderView = () => {
    let screen = orderScreens.find((screen) => screen.id === activeStep)
    return (
      <screen.component {...screen.data} moveForward={moveForward} updateActiveStep={updateActiveStep} pageNumber={searchParams.get("page_number")} />
    );
  }



  const fallbackUI = () => {
    return (
      <div className="error-boundary-mobile-fallback-wrapper">
        <div className="error-boundary-mobile-fallback-wrapper__error-text">
          <h4>Error</h4>
          <h4>Something Went Wrong...</h4>
        </div>
      </div>
    )
  }

  return (
    <ShareReportErrorBoundary
      fallback={fallbackUI()}
      isLoading={isLoading}
    >
      <>
        <div className={`${activeStep === 7 ? "pats-order-form-wrapper-no-header" : 'pats-order-form-wrapper'}`}>
          {(orderScreens.find((screen) => screen.id === activeStep).showHeader && activeStep != orderScreens.length) ? (
            <div className="order-header-section">
              <Header
                title="Device Order Form"
                onBack={() => moveBackward(orderScreens.find((screen) => screen.id === activeStep).onBack)}
              />
            </div>
          ) : <div></div>}

          {(orderScreens.find((screen) => screen.id === activeStep).showProgress && activeStep != orderScreens.length) ? (
            <div className="progress-bar-section">
              <ProgressBar
                totalBars={orderScreens.filter((screen) => screen.showProgress).length}
                activeBar={activeStep}
              />
            </div>
          ) : <div></div>}

          <div className="pats-order-body">
            {renderView()}
          </div>
        </div>
      </>
      <Toasters/>
    </ShareReportErrorBoundary >
  );
};

export default PatientPatsOrders;
