import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import injectReducer from 'utils/injectReducer';
import NavigationPrompt from 'react-router-navigation-prompt';
import Modal from 'react-bootstrap/lib/Modal';
import includes from 'lodash/includes';
import isEqual from 'lodash/isEqual';
import { showToaster } from 'common/toasterActions';
import ConfirmationModal from 'patientApp/components/ConfirmationModal';
import MasqueradingHeader from 'patientApp/Caregiver/MasqueradingHeader';

import surveyFeedbackReducer from './reducers';
import { getSurveyFeedbackRequest, submitSuveryFeedbackRequest, clearSurveyQuestionsRequest } from './actions';
import LightboxModal from '../../../components/LightboxModal';

import './surveyFeedback.scss';

class SurveyFeedback extends React.Component {// eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props);
    this.state = {
      eventId: this.props.match.params.eventId,
      expectedQuestions: [],
      questionAnswerMap: {},
      showUserCommentToQuestionMap: {},
      questionIdsWithNoResponse: [],
      isFeedbackSurveyStarted: false,
      showConfirmSubmitSurveyModal: false,
      openingUrl: null,
    };
    this.onBack = this.onBack.bind(this);
  }

  componentDidMount() {
    this.props.getSurveyFeedback(this.props.match.params.eventId);
  }

  componentWillReceiveProps(nextProps) {
    if (!nextProps.isSubmitted && nextProps.questions && nextProps.questions.length && !isEqual(nextProps.questions, this.props.questions) && !this.shouldBlockNavigation()) {
      const expectedQuestions = nextProps.questions.map((question) => {
        const expectedQuestion = {
          id: question.id,
          section: question.expectedQuestion.section,
          questionText: question.expectedQuestion.text,
          isMultiSelectAllowed: question.expectedQuestion.isMultiSelectAllowed,
          isAnswerRequired: question.expectedQuestion.isAnswerRequired,
          questionType: question.expectedQuestion.questionType,
        };

        const possibleAnswers = question.expectedQuestion.possibleAnswers.map((pa) => {
          const answer = {
            id: pa.id,
            order: pa.order,
            text: pa.possibleAnswer.text,
            userResponseRequired: pa.possibleAnswer.userResponseRequired,
            followupQuestion: pa.possibleAnswer.followupQuestion,
            placeholder: pa.possibleAnswer.placeholder,
          };
          return answer;
        });
        expectedQuestion.possibleAnswers = possibleAnswers.sort((a, b) => a.order - b.order);

        const expectedAnswers = question.answers.map((ea) => ({ id: ea.id, text: ea.text, comment: ea.comment }));
        expectedQuestion.expectedAnswers = expectedAnswers;
        return expectedQuestion;
      });

      this.populateUserSelectedAnswers(expectedQuestions);
      this.populateQuestionHeader(expectedQuestions);

      this.setState({
        expectedQuestions,
        openingUrl: nextProps.openingUrl,
      });
    }
  }

  componentWillUnmount() {
    this.props.clearSurveyQuestions();
  }

  onBack() {
    this.props.history.goBack();
  }

  onConfirmSubmitSurveyFeedback = () => {
    this.onConfirmSumbitSurveyModalClose();
    const paramsData = [];
    const answers = [];
    // this.props.questions.forEach((question) => {
    //   const answer = {
    //     answer: this.state.questionAnswerMap[question.id].trim(),
    //     expected_question_id: question.id,
    //     comment: this.state.questionCommentMap[question.id] && this.state.questionCommentMap[question.id] !== '' ? this.state.questionCommentMap[question.id].trim() : null,
    //   };
    //   answers.push(answer);
    // });

    this.state.expectedQuestions.forEach((question) => {
      question.answersSelectedByUser.forEach((selectedAnswer) => {
        const answer = {
          answer: selectedAnswer.text.trim(),
          expected_question_id: question.id,
          comment: selectedAnswer.userComment ? selectedAnswer.userComment.trim() : null,
        };
        answers.push(answer);
      });
    });

    const eventIdParam = {
      event_id: this.state.eventId,
    };
    const answersParam = {
      answers,
    };

    paramsData.push(eventIdParam);
    paramsData.push(answersParam);

    const params = { data: paramsData };
    this.props.submitSuveryFeedback(this.state.eventId, params);
  }

  onConfirmSumbitSurveyModalClose = () => {
    this.setState({ showConfirmSubmitSurveyModal: false });
  }

  onContinueToSurveyClicked = () => {
    this.setState({
      openingUrl: null,
    });
  }

  onCloseOfOpeingUrlModal = () => {
    this.onContinueToSurveyClicked();
  }

  getSubmittedAnswer = (answers) => {
    const submittedAnswers = answers;
    let answerText = '';

    submittedAnswers.forEach((answer, index, array) => {
      // answer DOES NOT have a comment and answer IS NOT the last one in the array
      if ((answer.comment === null) && (index !== array.length - 1)) {
        answerText = `${answerText + answer.text}, `;
      } else if ((answer.comment) !== null && (index !== array.length - 1)) { // answer HAS A comment and answer IS NOT the last one in the array
        answerText = `${answerText + answer.text} - ${answer.comment}, `;
      } else if ((answer.comment) !== null && (index === array.length - 1)) { // answer HAS A comment and answer IS the last one in the array
        answerText = `${answerText + answer.text} - ${answer.comment}`;
      } else { // No comma for the last Ans in the array
        answerText = `${answerText + answer.text}`;
      }
    });

    return answerText;
  }

  getIconClassForPainSurveyPossibleAnswers = (index, isSelected) => {
    let iconClass = 'icon default';
    switch (index) {
      case 0:
        iconClass = 'icon zero ';
        break;
      case 1:
        iconClass = 'icon one ';
        break;
      case 2:
        iconClass = 'icon two ';
        break;
      case 3:
        iconClass = 'icon three ';
        break;
      case 4:
        iconClass = 'icon four ';
        break;
      default:
        iconClass = 'icon default ';
        break;
    }
    iconClass += (isSelected ? 'icon-font-a-check-mark' : 'icon-font-a-check-box');
    return iconClass;
  }

  getIconClassForPossibleAnswers(question, possibleAnswer) {
    const isSelected = this.getIsPossibleAnswerSelected(question, possibleAnswer);
    if (question.isMultiSelectAllowed) {
      return isSelected ? 'icon icon-2a-checked' : 'icon icon-2a-unchecked';
    }
    return isSelected ? 'icon icon-font-a-check-mark' : 'icon icon-font-a-check-box';
  }

  getIsPossibleAnswerSelected(question, possibleAnswer) {
    const selectedQuestion = this.state.expectedQuestions.filter((q) => q.id === question.id)[0];
    return selectedQuestion.answersSelectedByUser.some((answer) => answer.text === possibleAnswer.text);
  }

  getAdditionalComment(question, possibleAnswer) {
    const selectedQuestion = this.state.expectedQuestions.filter((q) => q.id === question.id)[0];
    const selectedAnswer = selectedQuestion.answersSelectedByUser.filter((answer) => answer.text === possibleAnswer.text)[0];
    if (selectedAnswer && selectedAnswer.userComment) {
      return selectedAnswer.userComment;
    }
    return '';
  }

  getPlaceholder(possibleAnswer) {
    return possibleAnswer.placeholder ? possibleAnswer.placeholder : 'Comment';
  }

  setUserCommentRequired(questionId, isUserCommentRequired) {
    const tempMap = this.state.showUserCommentToQuestionMap;
    tempMap[questionId] = isUserCommentRequired;
    this.setState({
      showUserCommentToQuestionMap: tempMap,
    });
  }

  shouldBlockNavigation = () => !this.props.isSubmitted && this.state.isFeedbackSurveyStarted;

  handleAdditionalComment = (event) => {
    const ids = event.target.id.split('_');
    const questionId = parseInt(ids[0], 10);
    const possibleAnswerId = parseInt(ids[1], 10);

    const newExpectedQuestions = [...this.state.expectedQuestions];
    const selectedQuestion = newExpectedQuestions.filter((q) => q.id === questionId)[0];
    const selectedPossibleAnswer = selectedQuestion.possibleAnswers.filter((pa) => pa.id === possibleAnswerId)[0];
    const selectedUserAnswer = selectedQuestion.answersSelectedByUser.filter((a) => a.text === selectedPossibleAnswer.text)[0];
    selectedUserAnswer.userComment = event.target.value;

    const indexOfAnswer = selectedQuestion.answersSelectedByUser.findIndex((answer) => answer.text === selectedUserAnswer.text);
    selectedQuestion.answersSelectedByUser[indexOfAnswer] = selectedUserAnswer;

    this.setState({
      expectedQuestions: newExpectedQuestions,
      isFeedbackSurveyStarted: true,
    });
  }

  handleFeedbackChange = (event) => {
    // this.setAnswer(event.target.id, event.target.value);
    // this.setUserCommentRequired(event.target.id, false);

    const questionId = parseInt(event.target.id, 10);
    const newExpectedQuestions = [...this.state.expectedQuestions];
    const selectedQuestion = newExpectedQuestions.filter((q) => q.id === questionId)[0];
    const selectedUserAnswer = selectedQuestion.answersSelectedByUser[0];
    if (selectedUserAnswer) {
      selectedUserAnswer.text = event.target.value;
    } else {
      selectedQuestion.answersSelectedByUser = [{ text: event.target.value }];
    }

    this.setState({
      expectedQuestions: newExpectedQuestions,
      isFeedbackSurveyStarted: true,
    });
  }

  handleOptionChange = (question, possibleAnswer) => {
    const newExpectedQuestions = [...this.state.expectedQuestions];
    const selectedQuestion = newExpectedQuestions.filter((q) => q.id === question.id)[0];
    const answersSelectedByUser = [...selectedQuestion.answersSelectedByUser];

    if (answersSelectedByUser.some((answer) => answer.text === possibleAnswer.text)) {
      const newAnswers = answersSelectedByUser.filter((answer) => answer.text !== possibleAnswer.text);
      answersSelectedByUser.splice(0);
      answersSelectedByUser.push(...newAnswers);
    } else if (question.isMultiSelectAllowed) {
      answersSelectedByUser.push(possibleAnswer);
    } else {
      answersSelectedByUser.splice(0);
      answersSelectedByUser.push(possibleAnswer);
    }
    selectedQuestion.answersSelectedByUser = answersSelectedByUser;

    const indexOfSelectedQuestion = newExpectedQuestions.findIndex((q) => q.id === selectedQuestion.id);
    newExpectedQuestions[indexOfSelectedQuestion] = selectedQuestion;

    this.setState({
      expectedQuestions: newExpectedQuestions,
      isFeedbackSurveyStarted: true,
    });
  }

  validateResult = () => {
    const questionIdsWithNoResponse = [];
    this.state.expectedQuestions.forEach((question) => {
      if (question.isAnswerRequired && question.answersSelectedByUser.length === 0) {
        questionIdsWithNoResponse.push(question.id);
      }
      question.answersSelectedByUser.forEach((answer) => {
        if (answer.userResponseRequired && !answer.userComment) {
          if (!questionIdsWithNoResponse.includes(question.id)) {
            questionIdsWithNoResponse.push(question.id);
          }
        }
      });
    });
    return questionIdsWithNoResponse;
  }

  sumbitSurveyFeedback = () => {
    const questionIdsWithNoResponse = this.validateResult();
    this.setState({
      questionIdsWithNoResponse,
    });
    if (questionIdsWithNoResponse.length === 0) {
      this.setState({
        showConfirmSubmitSurveyModal: true,
      });
    } else {
      this.props.showToaster('error', 'Please provide response to all questions');
    }
  }

  populateUserSelectedAnswers(expectedQuestions) {
    for (let i = 0; i < expectedQuestions.length; i += 1) {
      const expectedQuestion = expectedQuestions[i];
      const answersSelectedByUser = [];
      for (let j = 0; j < expectedQuestion.expectedAnswers.length; j += 1) {
        const expectedAnswer = expectedQuestion.expectedAnswers[j];
        if (expectedQuestion.possibleAnswers.length > 0) {
          const selectedAnswer = expectedQuestion.possibleAnswers.filter((pa) => pa.text === expectedAnswer.text)[0];
          if (selectedAnswer) {
            const answer = { ...expectedAnswer };
            answer.userComment = expectedAnswer.comment;
            answer.userResponseRequired = selectedAnswer.userResponseRequired;
            answersSelectedByUser.push(answer);
          }
        } else {
          const answer = { ...expectedAnswer };
          answer.userComment = expectedAnswer.comment;
          answersSelectedByUser.push(answer);
        }
      }
      expectedQuestion.answersSelectedByUser = answersSelectedByUser;
    }
  }

  populateQuestionHeader(expectedQuestions) {
    let previousSectionHeader = '';
    expectedQuestions.forEach((question) => {
      if (question.section && question.section !== previousSectionHeader) {
        question.shouldShowHeader = true;
        previousSectionHeader = question.section;
      }
    });
  }

  renderConfirmSumbitSurveyModal() {
    return (
      <ConfirmationModal
        show={this.state.showConfirmSubmitSurveyModal}
        text="You are about to submit your responses. Once you submit them your responses are final. Tap on 'Continue' to proceed and submit. Tap on 'Cancel' to stay on the survey and review your responses."
        title="Attention"
        onModalClose={this.onConfirmSumbitSurveyModalClose}
        onConfirmed={this.onConfirmSubmitSurveyFeedback}
        cancelBtnText="Cancel"
        okBtnText="Continue"
      />
    );
  }

  renderFeedbackQuestions(question) {
    return (
      <React.Fragment>
        {
          question.shouldShowHeader && question.section !== '' &&
          <div className="question-header">{question.section}</div>
        }
        {
          <div className="survey-feedback-question-text">{question.questionText}</div>
        }
        {
          this.props.isSubmitted ?
            (
              <div>
                <div className="survey-feedback-question-text">{question.expectedQuestion.text}</div>
                <div className="survey-feedback-answer-text">{question.answers.length === 0 ? 'Not Answered' : this.getSubmittedAnswer(question.answers)}</div>
              </div>
            ) :
            (
              <React.Fragment>
                {
                  question.possibleAnswers.length > 0 ?
                    (
                      <div>

                        <div className="possible-answers-container">
                          {
                            question.possibleAnswers.map((possibleAnswer, index) => (
                              <React.Fragment key={`${possibleAnswer.id}`}>
                                <div className={`col-xs-12 col-md-6 ${index % 2 === 0 ? 'clear-both' : ''}`}>
                                  <div className="answer-comment-group form-group">
                                    <div className="survey-feedback-possible-answers" key={possibleAnswer.id}>
                                      <div className="survey-feedback-possible-answers--answer" onClick={() => this.handleOptionChange(question, possibleAnswer)}>
                                        {
                                          question.questionType === 'Survey' ? (
                                            <span
                                              className={`${this.getIconClassForPainSurveyPossibleAnswers(index, this.state.questionAnswerMap[question.id] === possibleAnswer.text)} float-left`}
                                              style={{ color: possibleAnswer.data && possibleAnswer.data.hex ? possibleAnswer.data.hex : '#00B2D0' }}
                                            />
                                          ) :
                                            (
                                              <span
                                                className={`${this.getIconClassForPossibleAnswers(question, possibleAnswer)} float-left`}
                                              />
                                            )
                                        }
                                        <span className="survery-feedback-answer-label">{possibleAnswer.text}</span>
                                      </div>
                                    </div>
                                    {
                                      possibleAnswer.userResponseRequired && this.getIsPossibleAnswerSelected(question, possibleAnswer) ?
                                        (
                                          <div className="survey-feedback-additional-response">
                                            {possibleAnswer.followupQuestion ? <p className="help-block"><i>{possibleAnswer.followupQuestion}</i></p> : null}
                                            <textarea
                                              className="form-control"
                                              cols="50"
                                              name="answer"
                                              id={`${question.id}_${possibleAnswer.id}`}
                                              value={this.getAdditionalComment(question, possibleAnswer)}
                                              placeholder={this.getPlaceholder(possibleAnswer)}
                                              onChange={this.handleAdditionalComment}
                                            />
                                          </div>
                                        )
                                        :
                                        null
                                    }
                                  </div>
                                </div>
                              </React.Fragment>
                            ))
                          }
                          <div className="clearfix" />
                        </div>
                      </div>
                    )
                    :
                    (
                      <textarea
                        className="form-control survey-feedback-possible-answer-textarea"
                        cols="100"
                        name="answer"
                        id={question.id}
                        onChange={this.handleFeedbackChange}
                      />
                    )
                }
                {
                  this.state.questionIdsWithNoResponse && includes(this.state.questionIdsWithNoResponse, question.id)
                  && <div className="survey-feedback-error-no-user-response">This question requires a response.</div>
                }
              </React.Fragment>
            )
        }
      </React.Fragment>
    );
  }

  renderOpeningUrl() {
    return (
      <Modal
        show
        onHide={this.onCloseOfOpeingUrlModal}
        className="primary-modal survey-feedback-container"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title">{this.props.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="opening-url-container">
            <iframe title={this.state.openingUrl} src={this.state.openingUrl} width="100%"></iframe>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-primary" onClick={this.onContinueToSurveyClicked}>Continue to Survey</button>
        </Modal.Footer>
      </Modal>
    );
  }

  render() {
    if (this.props.isLoading) {
      return (
        <LightboxModal show={this.props.isLoading} />
      );
    }
    if (this.state.openingUrl) {
      return this.renderOpeningUrl();
    }
    return (
      this.props.questions && this.props.questions.length ?
        (
          <React.Fragment>
            <div className="survey-feedback-questions-container">
              <MasqueradingHeader />
              <div className="survey-feedback-header">
                <div role="presentation" className="col-xs-2 back-navigator cursor-pointer" onClick={this.onBack}>
                  <span className="icon icon-font-a-left-chevron" />
                  {/* <span className="back-label hidden-xs">Back</span> */}
                </div>
                <div className="col-xs-8 survey-feedback-title text-center">{this.props.title}</div>
              </div>
              <div className="clearfix" />
              <NavigationPrompt when={this.shouldBlockNavigation()}>
                {({ onConfirm, onCancel }) => (
                  <Modal
                    show
                    onHide={onCancel}
                    className="primary-modal"
                  >
                    <Modal.Header closeButton>
                      <Modal.Title id="contained-modal-title">Leave Page?</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <div className="confirmation-message">If you leave this page without submitting the survey, the current changes will be lost. Do you want to leave this page?</div>
                    </Modal.Body>
                    <Modal.Footer>
                      <button className="btn btn-default" onClick={onCancel}>Cancel</button>
                      <button className="btn btn-primary" onClick={onConfirm}>Ok</button>
                    </Modal.Footer>
                  </Modal>
                )}
              </NavigationPrompt>
              <div className="survey-feedback-questions">
                {
                  this.props.isSubmitted ?
                    (
                      this.props.questions.map((question) => (
                        <div className="question" key={question.id} id={question.id}>
                          {this.renderFeedbackQuestions(question)}
                        </div>
                      ))
                    )
                    :
                    (
                      this.state.expectedQuestions.map((question, index) => (
                        <div className="question" key={question.id} id={question.id}>
                          {this.renderFeedbackQuestions(question, index)}
                        </div>
                      ))
                    )
                }
                {/* {
                  this.props.questions.map((question) => (
                    <div className="question" key={question.id} id={question.id}>
                      {this.renderFeedbackQuestions(question)}
                    </div>
                  ))
                } */}
                {/* {
                  this.state.expectedQuestions.map((question, index) => (
                    <div className="question" key={question.id} id={question.id}>
                      {this.renderFeedbackQuestions(question, index)}
                    </div>
                  ))
                } */}
                {
                  !this.props.isSubmitted && (
                    <div className="survey-feedback-action-button-container clearfix">
                      <button className="btn btn-primary survey-feedback-action-button" onClick={this.sumbitSurveyFeedback}>Submit</button>
                    </div>
                  )}
                {
                  this.state.showConfirmSubmitSurveyModal &&
                  this.renderConfirmSumbitSurveyModal()
                }
              </div>
            </div>
          </React.Fragment>
        ) :
        (
          <div>Emptyview</div>
        )
    );
  }
}

SurveyFeedback.propTypes = {
  submitSuveryFeedback: PropTypes.func,
  getSurveyFeedback: PropTypes.func,
  questions: PropTypes.array,
  isLoading: PropTypes.bool,
  isSubmitted: PropTypes.bool,
  history: PropTypes.any,
  showToaster: PropTypes.func,
  match: PropTypes.object,
  title: PropTypes.string,
  clearSurveyQuestions: PropTypes.func,
  openingUrl: PropTypes.string,
};

const groupQuestions = (questions) => {
  const newQuestions = [];
  let lastHeader = '';
  questions.forEach((question) => {
    const sectionHeader = question.expectedQuestion.section ? question.expectedQuestion.section : '';
    question.shouldShowHeader = false;
    if (lastHeader !== sectionHeader && sectionHeader !== '') {
      lastHeader = sectionHeader;
      question.shouldShowHeader = true;
      question.section = question.expectedQuestion.section;
    }
    newQuestions.push(question);
  });
  return newQuestions;
};

const mapStateToProps = (state) => ({
  surveyFeedbackDetails: state.surveyFeedback,
  isLoading: state.surveyFeedback.isLoading,
  questions: groupQuestions(state.surveyFeedback.questions),
  isSubmitted: state.surveyFeedback.isSubmitted,
  eventType: state.surveyFeedback.type,
  title: state.surveyFeedback.title,
  openingUrl: state.surveyFeedback.openingUrl,
});

const mapDispatchToProps = (dispatch) => ({
  getSurveyFeedback: (eventId) => dispatch(getSurveyFeedbackRequest(eventId)),
  submitSuveryFeedback: (eventId, params) => dispatch(submitSuveryFeedbackRequest(eventId, params)),
  showToaster: (type, message) => dispatch(showToaster({ type, message })),
  clearSurveyQuestions: () => dispatch(clearSurveyQuestionsRequest()),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

const withReducer = injectReducer({
  key: 'surveyFeedback',
  reducer: surveyFeedbackReducer,
});


export default compose(
  withReducer,
  withConnect,
  withRouter,
)(SurveyFeedback);
