import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useCollapsingSideBarViewModel } from './viewModel';
import './collapsing-side-bar.scss';

const CollapsingSideBar = (props) => {
  const dispatch = useDispatch();
  const { barOpenStatus, setBarOpenStatus, barTextShowStatus, onItemClick, onLogout } = useCollapsingSideBarViewModel(props);
  const [expanded, setExpanded] = useState(barTextShowStatus === 'show');

  useEffect(() => {
    setExpanded(barTextShowStatus)
  }, [barTextShowStatus])

  const renderMenuItems = (items) => {
    return (
      items.map((item) => {
        return (<div key={item.id} className={`item ${item.id === props.activeTab ? 'active' : ''} ${expanded === 'show' ? 'expanded' : ''}`} onClick={() => onItemClick(item.id, item.key)}>
          <span className="icon">
            {item.id === props.activeTab ? item.selectedIcon : item.icon}
          </span>
          <span className={`text ${expanded}`} style={{ objectFit: "cover" }}>{item.name}</span>
        </div >)
      })
    )
  }

  return (
    <div className="collapsing-sidbar-wrapper">
      <div className={`bar ${barOpenStatus ? 'bar-close' : 'bar-open'}`}>
        <div className="menu-items">
          {
            renderMenuItems(props.menu.slice(0, 5))
          }
        </div>
        <div className="back-button" onClick={() => setBarOpenStatus(!barOpenStatus)}>
          <span className={`icon ${barOpenStatus ? 'icon-font-a-right-chevron' : 'icon-font-a-left-chevron'}`}></span>
        </div>
        <div className="menu-items">
          {
            renderMenuItems(props.menu.slice(5, props.menu.length))
          }
        </div>
      </div>
    </div >
  )
}

export default CollapsingSideBar;