import React from 'react';
import PropTypes from 'prop-types';
import head from 'lodash/head';
import findIndex from 'lodash/findIndex';
import { dateTimeStringToMMSDateFormat } from 'utils/dateUtils';
import './conversationListCard.scss';

class ConversationsListCard extends React.Component {
  constructor(props) {
    super(props);
    this.onConversationClicked = this.onConversationClicked.bind(this);
    this.state = {
      showResolutionStatusDetails: false,
    };
  }

  onConversationClicked = (questionId, patientId) => {
    this.props.onConversationClicked(questionId, patientId);
  }

  getConversationIcon = (resolvedAt) => {
    if (resolvedAt) {
      return (<span className="icon icon-font-a-check-mark app-green question-list-status" />);
    }
    return (<span className="icon icon-font-a-pending app-orange question-list-status" />);
  }

  getConversationResolutionTooltipContent = (conversation) => {
    if (conversation.resolvedAt) {
      return `The conversation is answered${conversation.resolutionMethod === 'manual' ? ' Manually' : ''} by ${conversation.resolvedBy.fullName} at ${dateTimeStringToMMSDateFormat(conversation.resolvedAt)}.`;
    }
    return 'The conversation is not yet answered.';
  }

  getLastRepResponse = (messages) => messages[findIndex(messages, (message) => (
    message.sentBy.type === 'Patient' || message.sentBy.type === 'Rep'
  ))]

  onResolutionStatusMouseEnter = () => {
    this.setState({ showResolutionStatusDetails: true });
  }

  onResolutionStatusMouseLeave = () => {
    this.setState({ showResolutionStatusDetails: false });
  }

  resolveConversation = (conversation) => {
    if (!conversation.resolvedAt) {
      this.props.onResolveConversationClicked(conversation);
    }
  }

  render() {
    const { item, patientId } = this.props;
    return (
      <React.Fragment>
        <div
          className="conversation-list-row"
          key={item.id}
        >
          <div className="row conversation">
            <div
              className="col-xs-9 conversation-metadata cursor-pointer">
              <span
                className="conversation-list-status cursor-pointer float-left"
                onClick={() => item.resolvedAt ? this.onConversationClicked(item.id, patientId) : this.resolveConversation(item)}
                onMouseEnter={this.onResolutionStatusMouseEnter}
                onMouseLeave={this.onResolutionStatusMouseLeave}
              >
                {this.getConversationIcon(item.resolvedAt)}
                {this.state.showResolutionStatusDetails && (
                  <React.Fragment>
                    <span className="tip"></span>
                    <span className="tooltip-content">
                      {this.getConversationResolutionTooltipContent(item)}
                    </span>
                  </React.Fragment>
                )}
              </span>
              <div className="col-xs-11" onClick={() => this.onConversationClicked(item.id, patientId)}>
                <div className="conversation-title">
                  {item.subject}
                </div>
                <div className="margin-top-5 font-size-sm">
                  <span className="app-theme font-weight-300">{ item.startedBy.type === 'Patient' ? 'Patient Created' : 'Rep Created' }</span>
                  <span className="dot"></span>
                  { item.startedWith &&
                    <>
                      <span>Started With: {item.startedWith.fullName}</span>
                      <span className="dot"></span>
                    </>
                  }
                  <span className="sent-on">Sent on:</span>
                  &nbsp;
                  <span className="date">
                    { dateTimeStringToMMSDateFormat(item.createdAt) }
                  </span>
                </div>
                <div className="margin-top-5">
                  <span className="font-weight-300">{ head(item.messages).body }</span>
                </div>
                <div className="font-size-sm margin-top-5">
                  { this.getLastRepResponse(item.messages) &&
                    <>
                      <span className="app-theme font-weight-300">Last Response</span>
                      <span className="dot"></span>
                      <span className="font-weight-300">{ this.getLastRepResponse(item.messages).sentBy.fullName }</span>
                      <span className="dot"></span>
                      <span className="sent-on">Sent on:</span>
                      &nbsp;
                      <span className="date">
                        { dateTimeStringToMMSDateFormat(this.getLastRepResponse(item.messages).createdAt) }
                      </span>
                    </>
                  }
                </div>
              </div>
            </div>
            <div className="col-xs-3 text-right conversation-metadata cursor-pointer app-link" onClick={() => this.onConversationClicked(item.id, patientId)}>
              <span>
View Thread (
                {item.messages.length}
)
              </span>
              &nbsp;
              <span className="icon icon-font-a-right-chevron"></span>
            </div>
          </div>
          <hr />
        </div>
      </React.Fragment>
    );
  }
}

ConversationsListCard.propTypes = {
  item: PropTypes.object,
  patientId: PropTypes.number,
  onConversationClicked: PropTypes.func,
  onResolveConversationClicked: PropTypes.func,
};

export default ConversationsListCard;
