import * as Constants from "./constants";

const initialState = {
  isLoading: false,
  hasError: false,
  outcomesURL: null,
  filters: {}, 
  locations: [],
  data: {},
};

export const hcpOutcomesReducer = (state = initialState, action) => {
  switch (action.type) {
    case Constants.HCP_OUTCOMES_CHART_REQUEST_SENT:
      return {
        ...state,
        isLoading: true,
      };
    case Constants.HCP_OUTCOMES_CHART_REQUEST_SUCCEED:
      return {
        ...state,
        isLoading: false,
        hasError: false,
        outcomesURL: action.payload.outcomesUrl
      };
    case Constants.HCP_OUTCOMES_CHART_REQUEST_FAILED:
      return {
        ...state,
        isLoading: false,
        hasError: true,
      };
    case Constants.GET_HCP_OUTCOMES_FILTERS_REQUEST_SENT:
      return {
        ...state,
        isLoading: true,
      };
    case Constants.GET_HCP_OUTCOMES_FILTERS_REQUEST_SUCCEED:
      return {
        ...state,
        isLoading: false,
        hasError: false,
        filters: action.payload
      };
    case Constants.GET_HCP_OUTCOMES_FILTERS_REQUEST_FAILED:
      return {
        ...state,
        isLoading: false,
        hasError: true,
      };
    case Constants.GET_HCP_OUTCOMES_LOCATIONS_FILTER_REQUEST_SENT:
      return {
        ...state,
        isLoading: true,
      };
    case Constants.GET_HCP_OUTCOMES_LOCATIONS_FILTER_REQUEST_SUCCEED:
      return {
        ...state,
        isLoading: false,
        hasError: false,
        locations: action.payload.locations
      };
    case Constants.GET_HCP_OUTCOMES_LOCATIONS_FILTER_REQUEST_FAILED:
      return {
        ...state,
        isLoading: false,
        hasError: true,
      };
    case Constants.SEND_HCP_OUTCOMES_FILTERS_DATA_REQUEST_SENT:
      return {
        ...state,
        isLoading: true,
      };
    case Constants.SEND_HCP_OUTCOMES_FILTERS_DATA_REQUEST_SUCCEED:
      return {
        ...state,
        isLoading: false,
        hasError: false,
        data: action.payload
      };
    case Constants.SEND_HCP_OUTCOMES_FILTERS_DATA_REQUEST_FAILED:
      return {
        ...state,
        isLoading: false,
        hasError: true,
      };
    default:
      return state;
  }
}
