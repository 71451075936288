import React from "react";
import PatsBodyWrapper from "../Components/PatsOrderBodyWrapper";
import AddressCard from "../Components/AddressCard";
import EditIcon from 'assets/iconImages/edit_icon.svg'

function OrderReview(props) {
  const {
    deviceTypeData,
    devicePart,
    title,
    updateActiveStep,
    addressData,
    pageNumber,
    moveForward,
    editShippingDetailsData,
    onEditShippingDetails,
    onEditDevicePartDetails,
    submitOrderData } = props;

  const jumpOnPage = (jumpTo) => {
    // we are removing the intermediate states from stack to maintain the back button 
    // flow as per our requirement not browser's stack
    window.history.go(jumpTo - parseInt(pageNumber));
    updateActiveStep(jumpTo)
  }

  const getBody = () => {
    return (
      <>
        <div className="order-review-wrapper" key={"order-review-screen"}>

          <div className="order-details flex-justify-content margin-bottom-12">
            <span className="heading-font">Order Details</span>
            <span
              className="heading-font edit-icon"
              onClick={() => {
                onEditDevicePartDetails((jumpTo) => {
                  jumpOnPage(jumpTo)
                })
              }
              }
            >
              <img src={EditIcon}></img>
              <span> Edit</span>
            </span>
          </div>
          <div className="device-type-info flex-justify-content margin-bottom-12">
            <span className="heading-font">Device</span>
            <span className="content-font-1">{deviceTypeData.deviceType}</span>
          </div>
          <div
            className="device-part-info flex-justify-content"
            style={{ marginBottom: "40px" }}
          >
            <span className="heading-font">Part</span>
            <span className="content-font-1">
              {devicePart.internal_name}
            </span>
          </div>

          <div className="shipping-details flex-justify-content margin-bottom-12">
            <span className="heading-font">Shipping Details</span>
            <span
              className="heading-font edit-icon"
              onClick={() => {
                onEditShippingDetails((jumpTo) => {
                  editShippingDetailsData.toggleEditShippingDetails()
                  jumpOnPage(jumpTo)
                })

              }}
            >
              <img src={EditIcon}></img>
              <span style={{ lineHeight: "19px" }}>Edit</span>
            </span>
          </div>
          <AddressCard addressData={addressData} />
        </div >
      </>
    );
  };

  return (
    <PatsBodyWrapper
      title={<h4 className="title-font">{title}</h4>}
      body={getBody()}
      buttons={[
        <button className="pats-btn-primary" onClick={() => submitOrderData(moveForward)}>
          Submit Order
        </button>,
      ]}
    />
  );
}

export default OrderReview;