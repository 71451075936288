import {
  GET_SAML_VERIFICATION_SUCCESS, GET_SAML_VERIFICATION_FAILED,
} from './constants';

const initialState = {
  isLoading: false,
};


export const authSamlReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_SAML_VERIFICATION_SUCCESS:
      return {
        ...state,
        isLoading: false,

      };
    case GET_SAML_VERIFICATION_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return {
        ...state,
        isLoading: false,
        isSignedIn: false,
      };
  }
};
