import InputMask from "react-input-mask";
import { makeStyles } from '@mui/styles';
import TextField from '@mui/material/TextField';
import { PHONE_NUMBER_MASK } from "constants";
import './styles.scss';

const MobilePhoneNumberField = (props) => {
  const useStyles = makeStyles({
    root: {
      "& .MuiOutlinedInput-input": {
        fontSize: "14px !important",
        color: "black !important",
        '&::placeholder': {
          textOverflow: 'ellipsis !important',
          color: "#999",
          fontSize: "14px",
          fontWeight: 400,
          lineHeight: "24px",
          borderRadius: "6px"
        }
      },
      "& .Mui-error": {
        fontSize: "1.2rem !important",
        color: "#F4787D !important",
        "& .MuiOutlinedInput-notchedOutline": {
          border: "1px solid #F4787D !important",
          borderRadius: "6px"
        },
      },
      "& .MuiOutlinedInput-notchedOutline": {
        border: "1px solid #999 !important",
        borderRadius: "6px",
        "& .Mui-error": {
          border: "1px solid #F4787D !important",
        }
      },
    }
  });

  const classes = useStyles();
  return (
    <div className="phone-field-wrapper">
      <div className={`input-label ${props.required ? 'required' : ''}`}>{props.overHeadLabel}</div>
      <InputMask
        mask={PHONE_NUMBER_MASK}
        maskChar=""
        value={props.value}
        onChange={(e) => props.onChange(e.target.value)}
        placeholder={props.placeholder}
      >
        {() => (
          <TextField error={props.error ? true : false} helperText={props.error} sx={{ ...props.sx }} id="outlined-basic" className={classes.root} placeholder={props.placeholder} type={props.type} variant="outlined" />
        )}
      </InputMask>
    </div>
  )
}
export default MobilePhoneNumberField;