import { ClientInterface } from "utils/NativeNavigation/ClientInterface";
import ClientListenerIdentifier from "utils/NativeNavigation/ClientListenerIdentifier";

const useClientListener = (): ClientInterface => {
  const searchParams = new URLSearchParams(window.location.search);
  const platform = searchParams.get("Platform") || "0";
  const manager = new ClientListenerIdentifier(platform);
  return manager.getClientListenerObject();
};

export default useClientListener;
