import {
  GET_USER_CONSENT_REQUEST_SENT,
  GET_USER_CONSENT_REQUEST_SUCCEED,
  GET_USER_CONSENT_REQUEST_FAILED,

  POST_USER_CONSENT_AGREED_REQUEST_SENT,
  POST_USER_CONSENT_AGREED_REQUEST_SUCCEED,
  POST_USER_CONSENT_AGREED_REQUEST_FAILED,

  POST_USER_CONSENT_DISAGREED_REQUEST_SUCCEED,
  POST_USER_CONSENT_DISAGREED_REQUEST_SENT,
  POST_USER_CONSENT_DISAGREED_REQUEST_FAILED,

  USER_CONSENT_CLEAR_STATE,
} from './constants';

import { showToaster } from '../../../common/toasterActions';

import { getUserConsentForm, agreeUserConsent, disAgreeUserConsent } from '../../../services/patient/patientDashboard';

export const getUserConsentFormRequestSent = () => ({
  type: GET_USER_CONSENT_REQUEST_SENT,
});

export const getUserConsentFormRequestSucceeded = (data) => ({
  type: GET_USER_CONSENT_REQUEST_SUCCEED,
  data,
});

export const getUserConsentFormRequestFailed = () => ({
  type: GET_USER_CONSENT_REQUEST_FAILED,
});


export const agreeUserConsentRequestSent = () => ({
  type: POST_USER_CONSENT_AGREED_REQUEST_SENT,
});

export const agreeUserConsentRequestSucceeded = (data) => ({
  type: POST_USER_CONSENT_AGREED_REQUEST_SUCCEED,
  data,
});

export const agreeUserConsentRequestFailed = () => ({
  type: POST_USER_CONSENT_AGREED_REQUEST_FAILED,
});


export const disAgreeUserConsentRequestSent = () => ({
  type: POST_USER_CONSENT_DISAGREED_REQUEST_SENT,
});

export const disAgreeUserConsentRequestSucceeded = (data) => ({
  type: POST_USER_CONSENT_DISAGREED_REQUEST_SUCCEED,
  data,
});

export const disAgreeUserConsentRequestFailed = () => ({
  type: POST_USER_CONSENT_DISAGREED_REQUEST_FAILED,
});

export const clearUserConsentFormState = () => ({
  type: USER_CONSENT_CLEAR_STATE,
});


// Redux Thunk actions:

export const getUserConsentFormRequest = (procedureId) => (dispatch) => {
  dispatch(getUserConsentFormRequestSent());
  getUserConsentForm(procedureId)
    .then((response) => {
      if (response.data) {
        dispatch(getUserConsentFormRequestSucceeded(response.data));
      }
    })
    .catch((error) => {
      dispatch(getUserConsentFormRequestFailed());
      dispatch(showToaster({ type: 'error', message: error.response.data.error }));
    });
};

export const agreeUserConsentRequest = (params) => (dispatch) => {
  dispatch(agreeUserConsentRequestSent());
  agreeUserConsent(params)
    .then((response) => {
      dispatch(agreeUserConsentRequestSucceeded(response.data));
    })
    .catch((error) => {
      dispatch(agreeUserConsentRequestFailed());
      dispatch(showToaster({ type: 'error', message: error.response.data.error }));
    });
};

export const disAgreeUserConsentRequest = (params) => (dispatch) => {
  dispatch(disAgreeUserConsentRequestSent());
  disAgreeUserConsent(params)
    .then((response) => {
      dispatch(disAgreeUserConsentRequestSucceeded(response.data));
    })
    .catch((error) => {
      dispatch(disAgreeUserConsentRequestFailed());
      dispatch(showToaster({ type: 'error', message: error.response.data.error }));
    });
};

export const clearUserConsentState = () => (dispatch) => {
  dispatch(clearUserConsentFormState());
};
