
export const FETCH_PATIENT_DEVICE_ORDERS_REQUEST_SENT = 'src/patientOrderSupport/common/constants/FETCH_PATIENT_DEVICE_ORDERS_REQUEST_SENT'
export const FETCH_PATIENT_DEVICE_ORDERS_REQUEST_SUCCEEDED = 'src/patientOrderSupport/common/constants/FETCH_PATIENT_DEVICE_ORDERS_REQUEST_SUCCEEDED'
export const FETCH_PATIENT_DEVICE_ORDERS_REQUEST_FAILED = 'src/patientOrderSupport/common/constants/FETCH_PATIENT_DEVICE_ORDERS_REQUEST_FAILED'


export const FETCH_PATIENT_DEVICE_ORDERS_DETAILS_REQUEST_SENT = 'src/patientOrderSupport/common/constants/FETCH_PATIENT_DEVICE_ORDERS_DETAILS_REQUEST_SENT'
export const FETCH_PATIENT_DEVICE_ORDERS_DETAILS_REQUEST_SUCCEEDED = 'src/patientOrderSupport/common/constants/FETCH_PATIENT_DEVICE_ORDERS_DETAILS_REQUEST_SUCCEEDED'
export const FETCH_PATIENT_DEVICE_ORDERS_DETAILS_REQUEST_FAILED = 'src/patientOrderSupport/common/constants/FETCH_PATIENT_DEVICE_ORDERS_DETAILS_REQUEST_FAILED'
export const RESET_ORDER_DETAILS_DATA = 'src/patientOrderSupport/common/constants/RESET_ORDER_DETAILS_DATA'

export const ARCHIVE_PATIENT_DEVICE_ORDERS_REQUEST_SENT = 'src/patientOrderSupport/common/constants/ARCHIVE_PATIENT_DEVICE_ORDERS_REQUEST_SENT'
export const ARCHIVE_PATIENT_DEVICE_ORDERS_REQUEST_SUCCEEDED = 'src/patientOrderSupport/common/constants/ARCHIVE_PATIENT_DEVICE_ORDERS_REQUEST_SUCCEEDED'
export const ARCHIVE_PATIENT_DEVICE_ORDERS_REQUEST_FAILED = 'src/patientOrderSupport/common/constants/ARCHIVE_PATIENT_DEVICE_ORDERS_REQUEST_FAILED'

export const SET_ACTIVE_TAB_ON_SIDEBAR = 'src/patientOrderSupport/common/constants/SET_ACTIVE_TAB_ON_SIDEBAR'

export const SET_SIDE_BAR_OPEN_STATUS = 'src/patientOrderSupport/common/constants/SET_SIDE_BAR_OPEN_STATUS'

export const UPDATE_ORDER_DATA_AFTER_SORTING = 'src/patientOrderSupport/common/constants/UPDATE_ORDER_DATA_AFTER_SORTING'

export const SET_SEARCH_STRING = 'src/patientOrderSupport/common/constants/SET_SEARCH_STRING'