import axios from '../base';

import {
  SEARCH_QUESTIONS,
  CREATE_QUESTION,
  PUT_EDIT_QUESTION,
  DO_DELETE_QUESTION,
  GET_ALL_FAQ_QUESTIONS,
  GET_ALL_FAQ_QUESTIONS_METADATA,
  GET_PATIENT_QUESTION_DETAILS,
  POST_ANSWER_REVIEW,
  POST_ASK_QUESTION,
  GET_ALL_MY_QUESTIONS,
  POST_REQUEST_CALLBACK,
  ASK_PENDING_QUESTION,
} from '../constants';

export const searchQuestionsService = (params) => axios.get(SEARCH_QUESTIONS, { params });

export const createQuestionService = (reqObj) => axios.post(CREATE_QUESTION, reqObj);

export const getAllFaqsService = (compositeProcedureId) => {
  const params = {
    procedure_id: compositeProcedureId,
  };
  return axios.get(GET_ALL_FAQ_QUESTIONS_METADATA, { params });
};

export const getAllFaqQuestions = (userProcedureId, tag) => {
  const params = {
    user_procedure_id: userProcedureId,
  };

  if (tag === 'Recently Viewed') {
    params.recent = true;
  } else {
    params.tag = tag;
  }

  return axios.get(GET_ALL_FAQ_QUESTIONS, { params });
};

export const getQuestionDetails = (questionId) => axios.get(`${GET_PATIENT_QUESTION_DETAILS}${questionId}`);

export const postAnswerReviewService = (reqObj) => axios.post(POST_ANSWER_REVIEW, reqObj);

export const postAskQuestionService = (userProcedureId, questionId, text, mediaResources = [], isAsked) => {
  const reqObj = {
    question_id: questionId,
    text,
    is_asked: isAsked,
    user_procedure_id: userProcedureId,
    media_resources: mediaResources,
  };
  return axios.post(POST_ASK_QUESTION, reqObj);
};

export const putEditQuestionService = (userProcedureId, questionId, text, mediaResources = [], isAsked) => {
  const reqObj = {
    text,
    is_asked: isAsked,
    media_resources: mediaResources,
    user_procedure_id: userProcedureId,
  };
  const url = `${PUT_EDIT_QUESTION}${questionId}`;
  return axios.put(url, reqObj);
};

export const doDeleteQuestionService = (questionId) => {
  const param = {
    id: questionId,
  };

  return axios.delete(DO_DELETE_QUESTION, { params: param });
};

export const getAllMyQuestions = (limit = 500) => {
  const params = {
    limit,
  };
  return axios.get(GET_ALL_MY_QUESTIONS, { params });
};

export const postRequestCallback = (reqObj) => axios.post(POST_REQUEST_CALLBACK, reqObj);

export const putAskPendingQuestion = (questionId) => axios.put(`${ASK_PENDING_QUESTION}${questionId}`);
