import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getHCPOutcomesResult,
  getHCPOutcomesLocationFiltersResult,
  getHCPOutcomesFilterResult,
} from "./actions";
import LightboxModal from "components/LightboxModal";
import "./index.scss";
import ShareHCPOutcomesReportModal from "components/ShareHCPOutcomesReportModal";

export default function HCPOutcomes() {
  const dispatch = useDispatch();
  const [shareClicked, setShareClicked] = useState(false);

  const outcomesURL = useSelector((store) => store.hcpOutcomesReducer.outcomesURL);

  useEffect(() => {
    dispatch(getHCPOutcomesResult());
    dispatch(getHCPOutcomesLocationFiltersResult());
  }, []);

  const onShareClicked = () => {
    setShareClicked(!shareClicked);
  };

  const onShareReportModalClose = () => {
    setShareClicked(false);
  };

  return (
    <>
      <LightboxModal show={!outcomesURL} />
      <div className="hcp-dashboard-component-wrapper">
        <button type="button" id="share-outcomes" class="btn btn-primary hcp-outcomes-button" onClick={onShareClicked}>
          Share Report
        </button>
        <iframe className="hcp-outcomes-iframe" allow src={outcomesURL} />
      </div>
      <ShareHCPOutcomesReportModal
        show={shareClicked}
        hide={onShareReportModalClose}
      />
    </>
  );
}
