import {
  CREATE_USER_REQUEST_SENT,
  CREATE_USER_REQUEST_SUCCEED,
  CREATE_USER_REQUEST_FAILED,
  WELCOME_VIEW,
  NEXT_PAGE,
  PREV_PAGE,
} from './constants';

const initialState = {
  isLoading: false,
  registrationSuccess: false,
  page: WELCOME_VIEW,
};

export const createUserReducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_USER_REQUEST_SENT:
      return {
        ...state,
        isLoading: true,
        isRequestInProgress: true,
        registrationSuccess: false,
      };
    case CREATE_USER_REQUEST_SUCCEED:
      return {
        ...state,
        isLoading: false,
        isRequestInProgress: false,
        registrationSuccess: true,
      };
    case CREATE_USER_REQUEST_FAILED:
      return {
        ...state,
        isLoading: false,
        isRequestInProgress: false,
        registrationSuccess: false,
      };
    case NEXT_PAGE:
      return {
        ...state,
        page: state.page + 1,
      };
    case PREV_PAGE:
      return {
        ...state,
        page: state.page - 1,
      };
    default:
      return state;
  }
};
