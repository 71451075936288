import React, { useState, useEffect } from "react";
import { getPatientQuickViewSummary } from "./service";
import TrialSummaryQuickView from "./Components/TrialSummaryQuickView";
import PermSummaryQuickView from "./Components/PermSummaryQuickView";
import Header from "./Components/Header";
import { dateStringToDateWithMMDDYYYYFormat } from "utils/dateUtils";
import "./styles.scss";
import { changeSearchParams } from "utils/changeSearchParams";
import { STATE } from "enums";
import Loader from "../../assets/images/loading.gif";
import ShareReportErrorBoundary from "containers/ShareReportErrorBoundary";
import useClientListener from "hooks/useClientListener";
import useBackHandler from "hooks/useBackHandler";

function ProcedureSummaryQuickView(props) {
  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isTrialProcedure, setIsTrialProcedure] = useState(false);

  const clientListener = useClientListener();
  useBackHandler("Procedure Summary Quick Overview", clientListener);

  useEffect(() => {

    const searchParams = new URLSearchParams(window.location.search);
    const userProcedureId = searchParams.get("UserProcedureId") ?? "0";
    if (userProcedureId == "0" || userProcedureId === null) {
      throw new Error("Invalid User Procedure");
    }
    setIsLoading(true);
    getPatientQuickViewSummary(userProcedureId)
      .then((response) => {
        setData(response.data)
        setIsTrialProcedure(response.data.procedure_name.toLowerCase() === "scs trial");
        setIsLoading(false);
      }).
      catch((e) => {
        console.log(JSON.stringify(e));
        changeSearchParams(STATE.ERROR, e.message || null);
        setIsLoading(false);
      })
  }, []);

  const getChartData = (painReductionPercentages) => {
    const values = painReductionPercentages?.map((el) => {
      if (el.value === -1 || el.value === null) {
        return null;
      }
      return el.value;
    });
    return values;
  };

  const getChartColors = (painReductionPercentages) => {
    const colors = painReductionPercentages?.map((el) => {
      if ("color" in el) {
        return el.color;
      }
      return null;
    });
    return colors;
  };

  const onBack = () => {
    clientListener.onCancelled();
  };

  const getProcedureDate = () => {
    let dateString = "";
    if (isTrialProcedure) {
      dateString =
        `${dateStringToDateWithMMDDYYYYFormat(data.trial_start_date)} - ${dateStringToDateWithMMDDYYYYFormat(data.trial_end_date)}`;
    } else {
      dateString = dateStringToDateWithMMDDYYYYFormat(data.implant_date);
    }
    return dateString;
  };

  const fallbackUI = () => {
    return (
      <div className="share-report-mobile-fallback-wrapper">
        <div className="share-report-mobile-fallback-wrapper__error-text">
          <h4>Error</h4>
          <h4>Something Went Wrong...</h4>
        </div>
      </div>
    );
  };

  return (
    <ShareReportErrorBoundary
      fallback={fallbackUI()}
      isLoading={isLoading}
    >
      {Object.keys(data).length != 0 ?
        <>
          <div className="summary-page-wrapper">
            <Header name={data.full_name} procedureDate={getProcedureDate()} onBackClick={onBack} isTrialProcedure={isTrialProcedure} />
            {isTrialProcedure ? (
              <TrialSummaryQuickView data={data} getChartData={getChartData} getChartColors={getChartColors} />
            ) : (
              <PermSummaryQuickView data={data} getChartData={getChartData} getChartColors={getChartColors} />
            )}
          </div>
        </>
        : <div className="loading-status">Loading the Data....</div>}
    </ShareReportErrorBoundary>
  );
}

export default ProcedureSummaryQuickView;
