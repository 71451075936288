
import { useState } from 'react';
import FormControl from '@mui/material/FormControl';
import { FormHelperText } from '@mui/material';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import InputLabel from '@mui/material/InputLabel';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { makeStyles } from '@mui/styles';
import './styles.scss'
import caretDown from 'assets/iconImages/caret_down.svg'
import EventIcon from '@mui/icons-material/Event';

import { generateUUID } from 'utils/common';


const MobileSingleSelect = (props) => {

  const [menuOpen, setMenuOpen] = useState(false);



  const useStyles = makeStyles({
    root: {
      "& .MuiFormHelperText-contained": {
        fontSize: "1.2rem !important",
      },
      "& .MuiOutlinedInput-input": {
        textOverflow: 'ellipsis !important',
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "24px",
      }
    }
  });

  const classes = useStyles();

  const menuOptions = () => {
    if (props.placeholder) {
      return [{ value: "undefined", label: <span className="placeholder-text">{props.placeholder}</span> }, ...props.menuItems]
    }
    return props.menuItems
  }

  const customDropDownIcon = () => {
    return (
      <span>
        <svg onClick={() => setMenuOpen(!menuOpen)} width="24" height="24" style={{ margin: "0 10px" }} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M19.5 9L12 16.5L4.5 9" stroke="#959594" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
      </span>

    )
  }

  return (
    <div className="single-select-wrapper">
      <div className="input-label required">{props.overHeadLabel}</div>
      <FormControl required sx={{ width: "100%" }}>
        {props.floatingLabel ? <InputLabel id="demo-simple-select-required-label">{props.floatingLabel}</InputLabel> : <></>}
        <Select
          onOpen={() => setMenuOpen(true)}
          onClose={() => setMenuOpen(false)}
          open={menuOpen}
          style={props.styles}
          IconComponent={customDropDownIcon}
          inputProps={{ textOverflow: "ellipsis" }}
          labelId="demo-simple-select-required-label"
          id="demo-simple-select-required"
          sx={{ fontSize: "14px", width: "100%" }}
          value={props.value || "undefined"}
          label={props.floatingLabel}
          onChange={(e) => props.onChange(e.target.value)}
          error={props.error ? true : false}
          className={classes.root}
        >
          {
            menuOptions().map((menu) => (
              <MenuItem className={classes.menu} key={generateUUID()} sx={{ whiteSpace: "initial", fontSize: "14px" }} disabled={menu.disabled} value={menu.value}>{menu.label}</MenuItem>
            ))
          }
        </Select>
        <FormHelperText sx={{ fontSize: "1.2rem", color: "#d32f2f" }} className={classes.root}>{props.error}</FormHelperText>
      </FormControl>
    </div>
  )
}
export default MobileSingleSelect;