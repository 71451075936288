import {
  Webkit,
  ClientInterface,
} from "utils/NativeNavigation/ClientInterface";

class IOSClientListener implements ClientInterface {
  private platformObject: Webkit;

  constructor() {
    // @ts-ignore
    this.platformObject = window?.webkit as Webkit;
  }

  openInFullWebContainer = (
    message: string = "open In Full Web Container"
  ): void => {
    this.platformObject.messageHandlers.openInFullWebContainer.postMessage(
      message
    );
  };

  onCompleted = (message: string = "on Completed"): void => {
    this.platformObject.messageHandlers.onCompleted.postMessage(message);
  };

  onCancelled = (message: string = "on Cancelled"): void => {
    this.platformObject.messageHandlers.onCancelled.postMessage(message);
  };

  onError = (message: string = "on Error"): void => {
    this.platformObject.messageHandlers.onError.postMessage(message);
  };
}

export default IOSClientListener;
