import React, { useEffect } from "react";
import PatsBodyWrapper from "containers/PatsPatientOrders/Components/PatsOrderBodyWrapper";
import MobileTextField from "components/MobileComponents/MobileTextField";
import MobilePhoneNumberField from "components/MobileComponents/MobilePhoneNumberField";
import MobileSingleSelect from "components/MobileComponents/MobileSingleSelect";
import { SPECIAL_CHARS_TO_SKIP_FOR_NAME, SPECIAL_CHARS_TO_SKIP_FOR_ADDRESS } from "constants";
import MobileInfoBlock from "components/MobileComponents/MobileInfoBlock";

export default function EditShippingDetails(props) {
  const {
    title,
    addressData,
    onSubmitShippingDetails,
    setEditShippingDetails,
    statesOptions
  } = props;

  const {
    firstName,
    lastName,
    addressLine1,
    addressLine2,
    city,
    state,
    zipCode,
    country,
    phoneNumber } = addressData;

  useEffect(() => {
    Object.keys(addressData).every((key) => {
      if (addressData[key].error) {
        addressData[key].ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        return false
      }
      return true
    })
  }, [addressData])

  const getBody = () => {
    return (
      <>
        <div className="edit-shipping-details-wrapper" key={"edit-shipping-details-screen"}>

          <div className="name-fields-wrapper flex-row gap-16">
            <div className="first-name-container flex-child" ref={firstName.ref}>
              <MobileTextField
                specialCharsToSkip={SPECIAL_CHARS_TO_SKIP_FOR_NAME}
                error={firstName.error}
                onChange={firstName.setValue}
                value={firstName.value}
                maxLength={100}
                skipSpecialChars={true}
                required
                placeholder="Enter First Name"
                overHeadLabel="First Name" />
            </div>
            <div className="last-name-container flex-child" ref={lastName.ref}>
              <MobileTextField
                specialCharsToSkip={SPECIAL_CHARS_TO_SKIP_FOR_NAME}
                error={lastName.error}
                onChange={lastName.setValue}
                value={lastName.value}
                maxLength={100}
                skipSpecialChars={true}
                required
                placeholder="Enter Last Name"
                overHeadLabel="Last Name" />
            </div>
          </div>

          <div className="address-field-1-wrapper" ref={addressLine1.ref}>
            <MobileTextField
              specialCharsToSkip={SPECIAL_CHARS_TO_SKIP_FOR_ADDRESS}
              error={addressLine1.error}
              onChange={addressLine1.setValue}
              value={addressLine1.value}
              maxLength={150}
              required
              sx={{ width: "100%" }}
              placeholder="Enter Address Line 1"
              overHeadLabel="Address Line 1" />
          </div>

          <div className="address-field-2-wrapper" ref={addressLine2.ref}>
            <MobileTextField
              specialCharsToSkip={SPECIAL_CHARS_TO_SKIP_FOR_ADDRESS}
              error={addressLine2.error}
              maxLength={150}
              onChange={addressLine2.setValue}
              value={addressLine2.value}
              sx={{ width: "100%" }}
              placeholder="Enter Address Line 2"
              overHeadLabel="Address Line 2" />
            <MobileInfoBlock text="We do not ship to P.O. boxes." />
          </div>


          <div className="city-state-wrapper flex-row gap-16">
            <div className="city-container flex-child" ref={city.ref}>
              <MobileTextField
                specialCharsToSkip={SPECIAL_CHARS_TO_SKIP_FOR_NAME}
                error={city.error}
                maxLength={30}
                onChange={city.setValue}
                placeholder="Enter City"
                value={city.value}
                required
                sx={{ width: "100%" }}
                overHeadLabel="City" />
            </div>
            <div className="state-container flex-child" ref={state.ref}>
              <MobileSingleSelect
                error={state.error}
                placeholder="Select State"
                onChange={state.setValue}
                value={state.value}
                required
                menuItems={statesOptions}
                sx={{ width: "100%" }}
                overHeadLabel="State" />
            </div>
          </div>

          <div className="country-zip-wrapper">
            <div className="country-zip">
              <div className="zip-container flex-child" ref={zipCode.ref}>
                <MobileTextField
                  error={zipCode.error}
                  onChange={zipCode.setValue}
                  value={zipCode.value}
                  placeholder="Enter Zip Code"
                  required
                  sx={{ width: "100%" }}
                  overHeadLabel="Zip Code" />
              </div>
              <div className="country-container flex-child" ref={phoneNumber.ref}>
                <MobilePhoneNumberField
                  error={phoneNumber.error}
                  onChange={phoneNumber.setValue}
                  value={phoneNumber.value} r
                  required
                  sx={{ width: "100%" }}
                  placeholder="999-999-9999"
                  overHeadLabel="Phone Number" />
              </div>
            </div>
            <MobileInfoBlock text="We only deliver to the United States" />
          </div>
        </div>
      </>
    );
  };

  return (
    <PatsBodyWrapper
      title={<h4 className="title-font">{title}</h4>}
      body={getBody()}
      buttons={[
        <button
          disabled={
            !firstName.value?.trim() || !lastName.value?.trim() || !addressLine1.value?.trim() || !city.value?.trim() || !state?.value || !zipCode.value?.trim() || !phoneNumber.value?.trim()

          }
          className="pats-btn-primary"
          onClick={() => {
            onSubmitShippingDetails();
          }}
        >
          Next: Order Review
        </button>,
      ]}
    />
  );
}
