import React, { useState, useEffect } from "react";
import Indicator1 from "../../../assets/images/Indicator1.png";
import Indicator2 from "../../../assets/images/Indicator2.png";
import Indicator3 from "../../../assets/images/Indicator3.png";
import "./component-style.scss";

const IndicationImageMapping = {
  Indicator1: Indicator1,
  Indicator2: Indicator2,
  Indicator3: Indicator3,
};

function PhysicalIndicators(props) {
  const { physicalIndications } = props;
  const [indications, setIndications] = useState([]);

  useEffect(() => {
    let tempIndications = [];
    physicalIndications?.forEach((indication) => {
      if ("change_indicator" in indication && indication.change_indicator != -1) {
        tempIndications.push(indication)
      }
    });
    setIndications(tempIndications);
  }, [physicalIndications]);

  return (
    <>
      {indications.length > 0 && (
        <>
          <div className="summary-page-divider" />
          <div className="indications-wrapper">
            {indications.map((indication) => {
              return (
                <div className="indication" key={indication.title}>
                  <div className="indication__title title-font-regular">{indication.title}</div>
                  <span className="indication__value">
                    <img src={IndicationImageMapping[`Indicator${indication.change_indicator}`]} alt="Indicator Arrow" className="indication__value__image" />
                    <span className="indication__value__label title-font-small">{indication.current_value}</span>
                  </span>
                </div>
              );
            })}
          </div>
        </>
      )}
    </>
  );
}

export default PhysicalIndicators;
