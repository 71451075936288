export const nameValidationConfig = {
  fields: ['firstName', 'lastName'],
  rules: {
    firstName: [
      { rule: 'isRequired', message: 'First name is required' },
    ],
    lastName: [
      { rule: 'isRequired', message: 'Last name is required' },
    ],
  },
};

export const phoneValidationConfig = {
  fields: ['phone'],
  rules: {
    phone: [
      { rule: 'isPhone', message: 'Mobile Number is invalid' },
      { rule: 'isRequired', message: 'Mobile Number is required' },
    ],
  },
};

export const otpValidationConfig = {
  fields: ['otp'],
  rules: {
    otp: [
      { rule: 'isRequired', message: 'OTP is required' },
    ],
  },
};
