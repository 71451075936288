export const GET_PATIENTS_FOR_CAREGIVER_REQUEST_SENT = 'src/caregiver/GET_PATIENTS_FOR_CAREGIVERT_REQUEST_SENT';

export const GET_PATIENTS_FOR_CAREGIVER_REQUEST_SUCCEEDED = 'src/caregiver/GET_PATIENTS_FOR_CAREGIVERT_REQUEST_SUCCEEDED';

export const GET_PATIENTS_FOR_CAREGIVER_REQUEST_FAILED = 'src/caregiver/GET_PATIENTS_FOR_CAREGIVER_REQUEST_FAILED';

export const POST_START_CAREGIVER_MASQUERADING_REQUEST_SENT = 'src/caregiver/POST_START_CAREGIVER_MASQUERADING_REQUEST_SENT';

export const POST_START_CAREGIVER_MASQUERADING_REQUEST_SUCCEEDED = 'src/caregiver/POST_START_CAREGIVER_MASQUERADING_REQUEST_SUCCEEDED';

export const POST_START_CAREGIVER_MASQUERADING_REQUEST_FAILED = 'src/caregiver/POST_START_CAREGIVER_MASQUERADINGREQUEST_FAILED';

export const CLEAR_MASQUERADING_FLAGS = 'src/caregiver/CLEAR_MASQUERADING_FLAGS';
