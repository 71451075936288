import axios from "axios";
import {
  DEFAULT_URL,
  GET_NEW_DEVICE_ORDER_INFO_REQUEST,
  GET_DEVICE_PARTS,
  PLACE_NEW_DEVICE_ORDER_REQUEST
} from "services/constants";
import { getAuthValues } from "utils/webContainerAuth";

export const getOrderSummaryAndData = () => {
  return axios.get(`${DEFAULT_URL + GET_NEW_DEVICE_ORDER_INFO_REQUEST}`, {
    headers: getAuthValues(),
  });
};

export const getDeviceParts = (params) => {
  return axios.get(`${DEFAULT_URL + GET_DEVICE_PARTS}`, {
    headers: getAuthValues(),
    params
  });
};

export const placeNewDeviceOrder = (data) => {
  return axios.post(`${DEFAULT_URL + PLACE_NEW_DEVICE_ORDER_REQUEST}`, data, {
    headers: getAuthValues(),
  });
};