import axios from '../base';

import {
  FETCH_PATIENT_DEVICE_ORDERS, FETCH_PATIENT_DEVICE_ORDER_DETAIL, ARCHIVE_PATIENT_DEVICE_ORDER
} from './constants'

export const fetchDeviceOrdersCall = (params) => axios.get(FETCH_PATIENT_DEVICE_ORDERS, { params });

export const fetchDeviceOrderDetailsCall = (params) => axios.get(FETCH_PATIENT_DEVICE_ORDER_DETAIL + '/' + params.id)

export const archiveDeviceOrderCall = (id) => axios.put(ARCHIVE_PATIENT_DEVICE_ORDER, { order_id: id })