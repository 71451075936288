import React, { useState } from "react";
import "./mobile-style.scss";

export default function WarningPopup(props) {
  const { show, clearAllFilters, keepAllFilters } = props;

  return (
    show && (
      <div className="pop-up-wrapper">
        <div className="backdrop"/>        
        <div className="warning-modal">
            <div className="warning-text-wrapper">
              <p className="warning-heading">Are you sure?</p>
              <p className="warning-text">
                Removing or adding an account will reset all other filters. eg. Device Type, Physicians, Indications and Therapy
              </p>
            </div>
            <div className="warning-actions">
              <button onClick={keepAllFilters} className="warning-button no-filter-change">No, don't reset</button>
              <button onClick={clearAllFilters} className="warning-button filter-change">Yes, reset</button>
            </div>
          </div>
      </div>
    )
  );
}
