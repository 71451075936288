import React from "react";
import Strings from "strings";
import heroImage from "../../../assets/images/hero-image.png";

export default function WelcomeSection() {
  const heroSectionContent = (
    <div className="main-content-wrapper">
      <div className="hero-content">
        <p>The CareGuidePro<sup className="trade-mark-super-script">TM</sup> app is like having a helpful companion with you during the spinal cord stimulation process. It helps you stay informed, connected and on schedule.</p>
        <p>{Strings.LOGIN_SCREEN_HERO_SECONDARY_MESSAGE}</p>
      </div>
      <div className="more-info-link">
        <p className="helper-text"> For more information visit</p>
        <a className="client-web-page" href="https://www.medtronic.com/CareGuidePro" target="_blank">
          Medtronic.com/CareGuidePro
        </a>
      </div>
    </div>
  );

  return (
    <div className="col-md-8 no-padding left-section-wrapper">
      <div className="hero-content-wrapper">
        <div className="hero-image-container">
          <img src={heroImage} alt="hero-image" />
        </div>
        <div className="hero-heading-wrapper">
          <p className="hero-heading-bold no-margin">BE MORE</p>
          <p className="hero-heading-bold no-margin">CONNECTED</p>
          <p className="hero-heading no-margin">
            with the CareGuidePro <sup className="trade-mark-super-script">TM</sup> App.
          </p>
        </div>
      </div>
      {heroSectionContent}
    </div>
  );
}
