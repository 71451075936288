import React from 'react';
import InputMask from 'react-input-mask';
import PropTypes from 'prop-types';
import './floatingPhoneInput.scss';

class FloatingPhoneInput extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isInputFocused: props.isFocused ? props.isFocused : false,
    };

    this.setTextInputRef = (element) => {
      this.textInput = element;
    };
  }

  handleFocus = () => this.setState({ isInputFocused: true });

  handleBlur = () => this.setState({ isInputFocused: false });

  focusField = () => {
    this.textInput.focus();
  };

  render() {
    const { label, isFocused, ...props } = this.props;
    const { isInputFocused } = this.state;
    const classNames = isInputFocused ? 'input-label-is-focused input-label-with-value' : (this.props.value !== '' ? 'input-label-with-value' : 'input-label-without-value');
    const mask = (this.props.country === 'AU') ? "+61\ 09 9999 9999" : "+1\ 999 999 9999"
    return (
      <div className="input-container phone-input-container pos-rel">
        <span role="presentation" onClick={this.focusField} className={classNames}>
          {label}
        </span>
        <InputMask
          {...props}
          type="tel"
          onFocus={this.handleFocus}
          onBlur={this.handleBlur}
          mask={mask}
          maskChar={null}
          inputRef={this.setTextInputRef}
          autoFocus
        />
      </div>
    );
  }
}

FloatingPhoneInput.propTypes = {
  isFocused: PropTypes.bool,
  label: PropTypes.any,
  value: PropTypes.any,
};

export default FloatingPhoneInput;
