import React from 'react';
import PropTypes from 'prop-types';
import '../patientRegister.scss';

export default function NextButton(props) {
  return (
    <div className="button-container text-align-center">
      <button
        className="next-btn btn btn-primary"
        onClick={props.onNext}
      >
        Next
      </button>
    </div>
  );
}

NextButton.propTypes = {
  onNext: PropTypes.func.isRequired,
};
