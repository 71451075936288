import React, { useState } from "react";
import PatsBodyWrapper from "../Components/PatsOrderBodyWrapper";
import MobileDatePicker from "components/MobileComponents/MobileDatePicker";
import MobileTextField from "components/MobileComponents/MobileTextField";
import MobileInfoBlock from "components/MobileComponents/MobileInfoBlock";
import cardImage from 'assets/images/card_image.png'
import { DEVICE_SERIAL_NUMBER_MASK } from 'constants';
import './styles.scss';
import { LoaderModal, PrimaryModal } from "components/MobileComponents/MobileModal";

const DeviceNumberDOB = (props) => {
  const [showCardImage, setShowCardImage] = useState(false);
  const { title, dobData, serialNumberData, moveForward, validateData } = props;

  const getBody = () => {
    return (
      <div className="device-number-dob-wrapper">
        <MobileDatePicker
          sx={{ width: "100%" }}
          required
          disableFuture={true}
          value={dobData.patientDOB}
          onChange={dobData.setPatientDOB}
          error={dobData.error}
          overHeadLabel={<div className="input-label">Date of Birth</div>} />

        <div className="device-number-wrapper">
          <MobileTextField
            inputMask={DEVICE_SERIAL_NUMBER_MASK}
            showErrorForMasking={true}
            upperCase={true}
            sx={{ width: "100%" }}
            error={serialNumberData.error}
            value={serialNumberData.deviceSerialNumber}
            onChange={serialNumberData.setDeviceSerialNumber}
            overHeadLabel={<div className="input-label">Implanted Device Serial Number</div>}
            placeholder="Example: NXXX######H" />
          <MobileInfoBlock
            textClickable={true}
            text="Your serial number is a 11 digit code starting with N and can be found on your Medtronic patient ID card."
            onClick={() => setShowCardImage(true)}
          />
        </div>
        <LoaderModal
          show={showCardImage}
          onHide={() => setShowCardImage(false)}
          closeIcon={true}
          img={{
            image: <img src={cardImage} style={{ width: "343px", height: "216px" }} alt="Loading..." className="card-image" />
          }}
        />
      </div >
    );
  };

  return (
    <PatsBodyWrapper
      title={<h4 className="title-font">{title}</h4>}
      body={getBody()}
      buttons={[
        <button
          disabled={!dobData.patientDOB}
          className="pats-btn-primary"
          onClick={() => validateData({ deviceSerialNumber: serialNumberData.deviceSerialNumber, patientDOB: dobData.patientDOB }, () => moveForward())}
        >
          Next: Device Type
        </button>,
      ]}
    />
  );
};

export default DeviceNumberDOB;
