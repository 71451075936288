import {
  FETCH_PATIENT_DEVICE_ORDERS_REQUEST_SENT,
  FETCH_PATIENT_DEVICE_ORDERS_REQUEST_SUCCEEDED,
  FETCH_PATIENT_DEVICE_ORDERS_REQUEST_FAILED,
  FETCH_PATIENT_DEVICE_ORDERS_DETAILS_REQUEST_SENT,
  FETCH_PATIENT_DEVICE_ORDERS_DETAILS_REQUEST_FAILED,
  FETCH_PATIENT_DEVICE_ORDERS_DETAILS_REQUEST_SUCCEEDED,
  RESET_ORDER_DETAILS_DATA,
  ARCHIVE_PATIENT_DEVICE_ORDERS_REQUEST_SENT,
  ARCHIVE_PATIENT_DEVICE_ORDERS_REQUEST_FAILED,
  ARCHIVE_PATIENT_DEVICE_ORDERS_REQUEST_SUCCEEDED,
  SET_ACTIVE_TAB_ON_SIDEBAR,
  SET_SIDE_BAR_OPEN_STATUS,
  UPDATE_ORDER_DATA_AFTER_SORTING,
  SET_SEARCH_STRING
} from "./constants"

const sideBarInitialState = {
  activeTabId: 1,
  activeTabKey: 'inbox',
  barOpenStatus: false
}

export const sideBarReducer = (state = sideBarInitialState, action) => {
  switch (action.type) {
    case SET_ACTIVE_TAB_ON_SIDEBAR:
      return {
        ...state,
        activeTabId: action.activeTabId,
        activeTabKey: action.activeTabKey
      }
    case SET_SIDE_BAR_OPEN_STATUS:
      return {
        ...state,
        barOpenStatus: action.barOpenStatus
      }

    default:
      return state;
  }
}

const orderDetailsTemplate = {
  id: "",
  fullName: "",
  deviceType: "",
  devicePart: "",
  provider: "",
  damageDate: "",
  address: {
    phoneNumber: "",
    email: "",
    addressLine1: "",
    addressLine2: "",
    city: "",
    state: "",
    zipCode: ""
  }
}

const initialState = {
  data: [],
  isLoading: false,
  searchString: null,
  pagination: {},
  orderDetails: { ...orderDetailsTemplate }
}
export const patientOrdersReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PATIENT_DEVICE_ORDERS_REQUEST_SENT:
    case FETCH_PATIENT_DEVICE_ORDERS_DETAILS_REQUEST_SENT:
    case ARCHIVE_PATIENT_DEVICE_ORDERS_REQUEST_SENT:
      return {
        ...state,
        isLoading: true
      }
    case FETCH_PATIENT_DEVICE_ORDERS_REQUEST_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        data: [...action.data],
        pagination: action.pagination
      }
    case FETCH_PATIENT_DEVICE_ORDERS_DETAILS_REQUEST_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        orderDetails: {
          ...state.orderDetails,
          ...action.data
        }
      }
    case ARCHIVE_PATIENT_DEVICE_ORDERS_REQUEST_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        data: state.data.filter((d) => d.id !== action.id),
        pagination: { ...state.pagination, total: state.pagination.total - 1, endNumber: state.pagination.endNumber - 1 }
      }
    case SET_SEARCH_STRING:
      return {
        ...state,
        searchString: action.data
      }
    case FETCH_PATIENT_DEVICE_ORDERS_REQUEST_FAILED:
    case FETCH_PATIENT_DEVICE_ORDERS_DETAILS_REQUEST_FAILED:
    case ARCHIVE_PATIENT_DEVICE_ORDERS_REQUEST_FAILED:
      return {
        ...state,
        isLoading: false
      }
    case UPDATE_ORDER_DATA_AFTER_SORTING:
      return {
        ...state,
        data: action.data,
      }
    case RESET_ORDER_DETAILS_DATA:
      return {
        ...state,
        orderDetails: { ...orderDetailsTemplate }
      }
    default:
      return state
  }
}