import React from 'react';
import './patientRegister.scss';

import validate, { clearErrorsForField } from 'common/validator';
import Logo from 'assets/images/CGP-logo.png';
import GooglePlayBadge from 'assets/images/google-play.png';
import AppStoreBadge from 'assets/images/app-store.png';

import { connect } from 'react-redux';
import { combineReducers, compose } from 'redux';

import injectReducer from 'utils/injectReducer';
import { createUserReducer } from './reducer';

import WelcomeView from './components/WelcomeView';
import NameView from './components/NameView';
import ContactView from './components/ContactView';
import OtpView from './components/OtpView';
import LightboxModal from 'components/LightboxModal';
import HeroImage from 'assets/images/patient-register-hero.png';

import { nameValidationConfig, phoneValidationConfig, otpValidationConfig } from './validators';

import * as Constants from './constants';
import { createUserAction, generateOtpAction, nextPageAction, prevPageAction } from './actions';

class PatientRegister extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isRequestInProgress: false,
      page: Constants.WELCOME_VIEW,
      firstName: '',
      lastName: '',
      phone: '',
      otp: '',
      errors: {},
    };
  }

  componentWillReceiveProps = (nextProps) => {
    this.setState({
      isRequestInProgress: nextProps.isRequestInProgress,
      page: nextProps.page,
    });
  };

  handleNext = () => {
    const { page } = this.state;

    let config = {};
    switch (page) {
      case Constants.NAME_VIEW:
        config = nameValidationConfig;
        break;
      case Constants.CONTACT_VIEW:
        config = phoneValidationConfig;
        break;
      case Constants.OTP_VIEW:
        config = otpValidationConfig;
      default:
        break;
    }

    if (page !== Constants.WELCOME_VIEW) {
      validate(config, this.state, this.onValidationFailure, this.onNextValidationSuccess);
    } else {
      this.props.nextPage(page + 1);
    }
  };

  handleBack = () => {
    const { page } = this.state;
    this.props.prevPage(page - 1);
  }

  handleChange = (e) => {
    const obj = {};
    obj[e.target.name] = e.target.value;
    obj.errors = clearErrorsForField(this.state.errors, e.target.name);
    this.setState(obj);
  };

  onNextValidationSuccess = () => {
    const { page, phone } = this.state;

    if (page === Constants.CONTACT_VIEW) {
      this.props.requestOtp(phone);
    } else if (page === Constants.OTP_VIEW) {
      this.submitForm();
    } else {
      this.props.nextPage(page + 1);
    }
  };

  onValidationFailure = (errors) => {
    this.setState({ errors });
  };

  submitForm = () => {
    const {
      firstName,
      lastName,
      phone,
      otp,
    } = this.state;

    let formattedPhone = phone.replace('+1', '');
    formattedPhone = formattedPhone.replace(/ /g, '');

    const params = {
      first_name: firstName.trim(),
      last_name: lastName.trim(),
      contact_number: formattedPhone.trim(),
      verification_code: otp.trim(),
    };

    this.props.createUser(params);
  }

  renderSuccessView = () => (
    <div className="success-view-container">
      <div className="image-container form-hero">
        <img src={HeroImage} alt="Patient Register Hero" />
      </div>
      <div className="row success-section contents-container text-align-center">
        <div className="col-xs-10 col-xs-offset-1">
          <img src={Logo} className="mms-welcome-logo" alt="MDT logo" />
          <h2>
            You&lsquo;re all set,
            {' '}
            {this.state.firstName}
            !
          </h2>
          <p>Please follow the link below to download the app. We&lsquo;ve also texted you a link.</p>
          <p>
            Remember to sign in using
            {' '}
            {this.state.phone}
            .
          </p>
        </div>
        <div className="download-container">
          <a href="https://apps.apple.com/in/app/careguidepro/id1558168399" target="_blank">
            <img src={AppStoreBadge} className="mms-welcome-logo" alt="App store badge" />
          </a>
          <a href="https://play.google.com/store/apps/details?id=com.higgsboson.health.mms.mdt" target="_blank">
            <img src={GooglePlayBadge} className="mms-welcome-logo" alt="Google play store badge" />
          </a>
        </div>
      </div>
    </div>
  );

  render() {
    const {
      page,
      firstName,
      lastName,
      phone,
      otp,
      errors,
    } = this.state;

    return (
      <div className="container-fluid app-theme font-wt-300 register-container">
        <LightboxModal show={this.state.isRequestInProgress} />
        {
          page === Constants.WELCOME_VIEW &&
          <WelcomeView onNext={this.handleNext} />
        }
        {
          page === Constants.NAME_VIEW && (
            <NameView
              firstName={firstName}
              lastName={lastName}
              errors={errors}
              onNext={this.handleNext}
              onBack={this.handleBack}
              onChange={this.handleChange}
            />
          )
        }
        {
          page === Constants.OTP_VIEW && (
            <OtpView
              otp={otp}
              phone={phone}
              errors={errors}
              onNext={this.handleNext}
              onBack={this.handleBack}
              onChange={this.handleChange}
            />
          )
        }
        {
          page === Constants.CONTACT_VIEW && (
            <ContactView
              firstName={firstName}
              onNext={this.handleNext}
              onChange={this.handleChange}
              onBack={this.handleBack}
              errors={errors}
              phone={phone}
            />
          )
        }
        { page === Constants.SUCCESS_VIEW && this.renderSuccessView()}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  isRequestInProgress: state.patientRegister.createUserReducer.isRequestInProgress,
  page: state.patientRegister.createUserReducer.page,
});

const mapDispatchToProps = (dispatch) => ({
  createUser: (params) => dispatch(createUserAction(params)),
  requestOtp: (phone) => dispatch(generateOtpAction(phone)),
  nextPage: (page) => dispatch(nextPageAction(page)),
  prevPage: (page) => dispatch(prevPageAction(page)),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

const withReducer = injectReducer({
  key: 'patientRegister',
  reducer: combineReducers({ createUserReducer }),
});

export default compose(
  withReducer,
  withConnect,
)(PatientRegister);
