import { useSelector, useDispatch } from 'react-redux';
import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { setSideBarOpenStatus } from 'patientOrderSupport/common/actions';

export const useCollapsingSideBarViewModel = (props) => {
  const dispatch = useDispatch();
  const [barTextShowStatus, setBarTextShowStatus] = useState('show');

  const history = useHistory();

  const barOpenStatus = useSelector((store) => store.sideBarReducer.barOpenStatus)

  const onMenuBarOpenCloseStatusChanged = () => {
    dispatch(setSideBarOpenStatus(!barOpenStatus))
  }

  useEffect(() => {
    if (barOpenStatus) {
      setTimeout(() => setBarTextShowStatus("hide"), 300);
    }
    else
      setBarTextShowStatus("show")
  }, [barOpenStatus])

  const onItemClick = (id, key) => {
    props.updateActiveTabData(id, key);
  }
  const onLogout = () => {
    history.push('/logout')
  }
  return {
    onItemClick,
    setBarTextShowStatus,
    barOpenStatus,
    setBarOpenStatus: onMenuBarOpenCloseStatusChanged,
    barTextShowStatus,
    onLogout
  }
}