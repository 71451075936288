import './styles.scss';

const AddressCard = (props) => {
  const { addressData } = props;
  const { firstName, lastName, addressLine1, addressLine2, city, state, zipCode, country, phoneNumber } = addressData;

  return (
    <div>
      <div className="name-show-wrapper">
        <span className={`field-value ${props.highlightName ? 'bold' : ''}`}>{firstName.value}</span>{' '}
        <span className={`field-value ${props.highlightName ? 'bold' : ''}`}>{lastName.value}</span>
      </div>
      <div className="field-value">{addressLine1.value}</div>
      <div className="field-value">{addressLine2.value}</div>
      <div className="address-show-wrapper">
        {city.value ? <><span className="field-value">{city.value}</span>{', '}</> : <></>}
        {state.value ? <><span className="field-value">{state.value}</span>{', '}</> : <></>}
        {zipCode.value ? <><span className="field-value">{zipCode.value}</span>{', '}</> : <></>}
        <span className="field-value">{country.value}</span>
        {phoneNumber.value ?
          <div className="phone-show-wrapper">
            <span className="field-value">Phone Number:</span>{' '}
            <span className="field-value">{phoneNumber?.value}</span>
          </div> : <></>}
      </div>
    </div>
  )
}

export default AddressCard;