import axios from './base';
import {
  GET_PATIENT_DETAILS, GET_PATIENT_TASKS, GET_OPTIONAL_TASKS, ASSIGN_OPTIONAL_TASKS,
  GET_PATIENT_QUESTIONS, GET_PATIENT_APPOINTMENTS,
  GET_PATIENT_NOTIFICATIONS, POST_PROVIDER_CREATE_TASK,
  GET_PROCEDURE_LOCATIONS, PUT_UPDATE_EVENT, UPDATE_APPOINTMENT_NOTES,
  DELETE_TASK, TASK_DETAILS, DELETE_ALL_RECURRING_TASKS,
  DELETE_PATIENT,
  POST_CREATE_APPOINTMENT,
  DELETE_APPOINTMENT,
  GET_CAREGIVERS,
  SKIP_APPOINTMENT,
  UPDATE_PATIENT_NEEDS_ATTENTION,
  GET_PATIENT_NOT_MOVING_FORWARD_REASONS,
  UPDATE_NOT_MOVING_FORWARD,
  POST_MARK_TASK_COMPLETE,
  POST_SUBMIT_SURVEY_FEEDBACK,
  RESEND_INVITE,
  UPDATE_PATIENT_INFO,
  GET_PROCEDURE_MANAGERS,
  ADD_PROCEDURE_MANAGERS,
  REMOVE_PROCEDURE_MANAGERS,
  POST_DISCARD_PROCEDURE,
  PUT_TERMINATE_TRIAL,
  GET_PATIENT_CONVERSATIONS,
  GET_CONVERSATION_MESSAGES,
  START_CONVERSATION,
  RESOLVE_CONVERSATION,
  SEND_MESSAGE,
  GET_MESSAGE_TEMPLATES,
  CREATE_IN_NLINK,
  TRACK_PATIENT_AS_VIEWED
} from './constants';
import { currentTimezoneName } from '../utils/dateUtils';

export const getPatientDetails = (id, userProcedureId) => {
  const params = {
    user_procedure_id: userProcedureId,
  };
  return axios.get(`${GET_PATIENT_DETAILS}${id}`, { params });
};

export const trackPatientAsViewed = (id, userProcedureId) => {
  const url = TRACK_PATIENT_AS_VIEWED.replace(':id', id);
  const params = {
    user_procedure_id: userProcedureId,
  };
  return axios.post(url, params);
};

export const getPatientTasks = (id, userProcedureId, procedurePhaseId = null, showAll) => {
  const params = {
    patient_id: id,
    user_procedure_id: userProcedureId,
    show_all: showAll ? 1 : 0,
  };

  if (procedurePhaseId) {
    params.procedure_phase_id = procedurePhaseId;
  }
  return axios.get(GET_PATIENT_TASKS, {
    params,
  });
};

export const getOptionalTasks = (userProcedureId) => {
  const params = {
    user_procedure_id: userProcedureId,
  };

  return axios.get(GET_OPTIONAL_TASKS, {
    params,
  });
};

export const assignTasks = (patientId, userProcedureId, data) => {
  const reqBody = data;
  reqBody.patient_id = patientId;
  reqBody.user_procedure_id = userProcedureId;
  return axios.post(ASSIGN_OPTIONAL_TASKS, reqBody);
};

export const getMessageTemplates = (userProcedureId) => {
  const params = {
    user_procedure_id: userProcedureId,
  };

  return axios.get(GET_MESSAGE_TEMPLATES, {
    params,
  });
};

export const getPatientQuestions = (id, userProcedureId, procedurePhaseId = null) => {
  const params = {
    patient_id: id,
    user_procedure_id: userProcedureId,
  };
  if (procedurePhaseId) {
    params.procedure_phase_id = procedurePhaseId;
  }
  return axios.get(GET_PATIENT_QUESTIONS, {
    params,
  });
};

export const getPatientConversations = (compositeProcedureId, procedurePhaseId = null, page = null) => {
  const params = {
    composite_procedure_id: compositeProcedureId,
  };
  if (procedurePhaseId) {
    params.procedure_phase_id = procedurePhaseId;
  }
  if (page) {
    params.page = page;
  }
  return axios.get(GET_PATIENT_CONVERSATIONS, {
    params,
  });
};

export const startConversation = (userProcedureId, subject, message, mediaResources, template_id) => {
  const params = {
    user_procedure_id: userProcedureId,
    subject,
    message_body: message,
    message_template_id: template_id,
  };
  if (mediaResources !== undefined || mediaResources !== null) {
    const mediaResourcesParams = [];
    for (let cnt = 0; cnt < mediaResources.length; cnt += 1) {
      const mediaResourceParam = {};
      mediaResourceParam.id = mediaResources[cnt].id;
      mediaResourceParam.duration = mediaResources[cnt].duration;
      mediaResourceParam.size = mediaResources[cnt].size;
      mediaResourceParam.name = mediaResources[cnt].name;
      mediaResourcesParams.push(mediaResourceParam);
    }
    params.media_resources = mediaResourcesParams;
  }
  return axios.post(START_CONVERSATION, params);
};

export const resolveConversation = (conversationId) => {
  return axios.post(RESOLVE_CONVERSATION.replace(':id', conversationId));
};

export const postSendMessage = (conversationId, message, mediaResources) => {
  const params = {
    conversation_id: conversationId,
    body: message,
  };
  if (mediaResources !== undefined || mediaResources !== null) {
    const mediaResourcesParams = [];
    for (let cnt = 0; cnt < mediaResources.length; cnt += 1) {
      const mediaResourceParam = {};
      mediaResourceParam.id = mediaResources[cnt].id;
      mediaResourceParam.duration = mediaResources[cnt].duration;
      mediaResourceParam.size = mediaResources[cnt].size;
      mediaResourceParam.name = mediaResources[cnt].name;
      mediaResourcesParams.push(mediaResourceParam);
    }
    params.media_resources = mediaResourcesParams;
  }
  return axios.post(SEND_MESSAGE, params);
};

export const getConversationMessages = (conversationId) => axios.get(GET_CONVERSATION_MESSAGES.replace(':id', conversationId));

export const getPatientAppointments = (id, compositeProcedureId, procedurePhaseId) => {
  const params = {
    patient_id: id,
    composite_procedure_id: compositeProcedureId,
    skip_survey_events: true,
  };

  if (procedurePhaseId) {
    params.procedure_phase_id = procedurePhaseId;
  }

  params.timezone = currentTimezoneName;
  return axios.get(GET_PATIENT_APPOINTMENTS, {
    params,
  });
};

export const getPatientNotifications = (id, userProcedureId, procedurePhaseId = null) => {
  const params = {
    patient_id: id,
    user_procedure_id: userProcedureId,
  };
  if (procedurePhaseId) {
    params.procedure_phase_id = procedurePhaseId;
  }
  return axios.get(GET_PATIENT_NOTIFICATIONS, {
    params,
  });
};

export const sendTaskReminder = (taskId) => axios.post(`/v2/tasks/${taskId}/remind`);

export const createTask = (patientId, userProcedureId, data) => {
  const reqBody = data;
  reqBody.patient_id = patientId;
  reqBody.user_procedure_id = userProcedureId;
  return axios.post(POST_PROVIDER_CREATE_TASK, reqBody);
};

export const getEventQuestions = (eventId) => axios.get(`/v2/events/${eventId}/questions/`);

export const getLocationOptions = (locationId) => {
  const params = {
    id: locationId,
  };

  return axios.get(GET_PROCEDURE_LOCATIONS, {
    params,
  }).then((response) => ({
    options: response.data.locations.map((item) => ({
      value: item.id,
      label: item.name,
    })),
  }));
};

export const updateAppointment = (eventId, params) => {
  params.id = eventId;
  return axios.put(
    `${PUT_UPDATE_EVENT}${eventId}`,
    {
      event: params,
    },
  );
};

export const updateAppointmentNotes = (eventId, note) => {
  const params = {
    id : eventId,
    additional_note : note,
  };
  const url = UPDATE_APPOINTMENT_NOTES.replace(':id', eventId);
  return axios.put(
    url,
    {
      event: params,
    },
  );
};

export const deleteSelectedTask = (taskId) => axios.delete(DELETE_TASK, { params: { task_id: taskId } });

export const getPatientTaskDetails = (taskId, patientId) => {
  if (patientId) {
    const params = {
      patient_id: patientId,
    };
    return axios.get(`${TASK_DETAILS}${taskId}`, { params });
  }
  return axios.get(`${TASK_DETAILS}${taskId}`);
};

export const getReminderHistory = (userTaskId) => axios.get(`v2/tasks/${userTaskId}/reminder_history`);

export const deleateAllRecurringTasksInstance = (userTaskId) => axios.delete(DELETE_ALL_RECURRING_TASKS, { params: { user_task_id: userTaskId } });

export const removePatient = (params) => {
  const url = DELETE_PATIENT.replace(':id', params.id);
  return axios.delete(url, { params });
};

export const createAppointment = (params) => axios.post(POST_CREATE_APPOINTMENT, params);

export const deleteAppointment = (eventId) => {
  const url = DELETE_APPOINTMENT.replace(':id', eventId);
  return axios.delete(url);
};


export const fetchCaregiversList = () => axios.get(GET_CAREGIVERS);

export const skipAppointment = (eventId) => {
  const url = SKIP_APPOINTMENT.replace(':id', eventId);
  return axios.post(url);
};

export const updateNeedsAttention = (params) => axios.post(UPDATE_PATIENT_NEEDS_ATTENTION, params);

export const getPatientNotMovingForwardReasons = (params) => axios.get(GET_PATIENT_NOT_MOVING_FORWARD_REASONS, { params });

export const updateNotMovingForward = (params) => {
  const url = UPDATE_NOT_MOVING_FORWARD.replace(':id', params.id);
  return axios.post(url, params);
};

export const postMarkTaskCompleteService = (reqObj) => axios.post(POST_MARK_TASK_COMPLETE, reqObj);

export const getSurveyFeedback = (eventId, patientId) => {
  const params = {
    patient_id: patientId,
  };
  return axios.get(`/v2/events/${eventId}/expected_questions`, { params });
};


export const submitSuveryFeedback = (params) => axios.post(POST_SUBMIT_SURVEY_FEEDBACK, params);

export const createInNlink = (params) => {
  const url = CREATE_IN_NLINK.replace(':id', params.id);
  return axios.post(url, params);
};

export const resendInvite = (params) => {
  const url = RESEND_INVITE.replace(':id', params.id);
  return axios.post(url, params);
};

export const updatePatientInfo = (params) => {
  const url = UPDATE_PATIENT_INFO.replace(':id', params.id);
  return axios.put(url, params);
};

export const getProcedureManagers = (params) => axios.get(GET_PROCEDURE_MANAGERS, { params });

export const addProcedureManagers = (params) => axios.post(ADD_PROCEDURE_MANAGERS, params);

export const removeProcedureManagers = (params) => {
  const url = REMOVE_PROCEDURE_MANAGERS.replace(':id', params.id);
  return axios.delete(url, { params });
};

export const terminateProcedureTrial = (userProcedureId) => axios.put(PUT_TERMINATE_TRIAL, { user_procedure_id: userProcedureId });
