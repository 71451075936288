import React, { useState, useEffect } from "react";
import BarChart from "./barChart";
import "./component-style.scss";

function StimulatorFrequencyChart(props) {
  const { data, labels, getChartData, getChartColors } = props;

  const setYLabelsForStimulatorFreq = () => {
    const values = [];
    data.stimulator_usage_y_labels?.forEach((item) => {
      for (const key in item) {
        values.push(item[key]);
      }
    });
    return values;
  };

  const [frequencyYLabels, setFrequencyYLabels] = useState(setYLabelsForStimulatorFreq());

  const chartData = {
    labels: labels ?? [],
    datasets: [
      {
        label: "",
        data: getChartData(data?.stimulator_usage) ?? [],
        backgroundColor: getChartColors(data?.stimulator_usage),
      },
    ],
  };

  const options = {
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          font: {
            size: 8,
          },
          autoSkip: false,
        },
      },
      y: {
        beginAtZero: true,
        max: frequencyYLabels.length,
        ticks: {
          callback: (value) => {
            const index = value - 1;
            return frequencyYLabels[index];
          },
          font: {
            size: 10,
          },
          autoSkip: false,
        },
      },
    },
  };

  return (
    <>
      <div className="summary-page-divider" />
      <BarChart options={options} data={chartData} chartTitle={"Frequency Of Stimulator Use"} />
    </>
  );
}

export default StimulatorFrequencyChart;
