import axios from 'axios';
import {
  SHOW_SHARE_OUTCOMES_REQUEST,
  RESEND_SHARE_OUTCOMES_REQUEST_PIN,
  DOWNLOAD_SHARE_OUTCOMES_REPORT_FILE,
  SHOW_HCP_SHARE_OUTCOMES_REQUEST,
  RESEND_HCP_SHARE_OUTCOMES_REQUEST_PIN
} from '../constants';

export const showShareOutcomesRequest = (params) => axios.post(SHOW_SHARE_OUTCOMES_REQUEST, params);

export const resendShareOutcomesRequestPin = (params) => axios.post(RESEND_SHARE_OUTCOMES_REQUEST_PIN, params);

export const downloadShareOutcomesReport = (params) => axios.post(DOWNLOAD_SHARE_OUTCOMES_REPORT_FILE, params);

export const showHcpShareOutcomesRequest = (params) => axios.post(SHOW_HCP_SHARE_OUTCOMES_REQUEST, params);

export const resendHcpShareOutcomesRequestPin = (params) => axios.get(RESEND_HCP_SHARE_OUTCOMES_REQUEST_PIN, { params });