import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import './styles.scss';
// import { colors } from './StyleGuideCSS';

const reasonsToAvoidModalHide = ["backdropClick", "escapeKeyDown"];

const modalDefaultStyle = {
  position: 'absolute',
  outline: 'none',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  // border: `1px solid ${colors.colorDisabledGrey}`,
  boxShadow: 24,
  padding: '10px',
  boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.12), 0px 20px 20px rgba(0, 0, 0, 0.08)',
  borderRadius: '8px',
  // width: '545px',
};

export default function CustomModal(props) {
  useEffect(() => {
    if (Object.keys(props).includes('form')) {
      if (props.form.form) {
        if (props.form.form.props ? !props.form.form.props.id : false) {
          console.warn('Form Object In Modal Should have id attribute to have Button to submit the form');
        } else if (props.buttons) {
          const isButtonHasFormAttribute = props.buttons.some((button) => button.props.form);
          if (!isButtonHasFormAttribute) {
            console.warn('Atleast one button in modal should have a form attribute when there is a form in modal');
          } else {
            const buttonFormId = props.buttons.some((button) => {
              if (button.props.form) {
                if (props.form.form.props)
                  return button.props.form === props.form.form.props.id;
              }
            });
            if (!buttonFormId) {
              console.warn('form attribute in button should have same value as id attribute to the form');
            }
          }
        }
      }
    }
  }, []);


  const handleClose = (event, reason) => {
    if (reason && reasonsToAvoidModalHide.includes(reason))
      return;
    props.onHide();
  }


  const getInlineStyleForTextAndIcon = () => (
    {
      flexDirection: props.bodyDirection ? props.bodyDirection : 'row',
      justifyContent: props.bodyIcon ? (props.bodyPosition ? (props.bodyPosition) : ('flex-start')) : ('center'),
      alignItems: props.bodyPosition ? (props.bodyPosition) : ('center'),
      gap: props.bodyDirection ? (props.bodyDirection == 'column' ? ('28px') : ('24px')) : ('24px'),
    }
  );

  const getInlineStyleForText = (textObject) => (
    {
      textAlign: textObject.align ? textObject.align : 'center',
    }
  );

  const getInlineStyleForButtons = () => (
    {
      flexDirection: props.buttonsDirection ? props.buttonsDirection : 'row',
      gap: props.buttonsDirection ? (props.buttonsDirection === 'row') ? ('24px') : ('16px') : '24px',
    }
  );

  return (
    <div className="modal-box">
      <Modal
        open={props.show}
        onClose={handleClose}
        className={props.className}
      >

        <Box sx={props.modalStyle ? ({ ...modalDefaultStyle, ...props.modalStyle }) : ({ ...modalDefaultStyle })}>
          {/* modal Header */}
          <div id="modal-header-content" className="modal-header-content">
            {
              props.closeIcon && (
                <span id="modal-close-button" className={`modal-close-button ${props.loaderModal ? 'loader-close-icon' : ''}`}>
                  <IconButton onClick={props.onHide}>
                    <CloseIcon sx={{ fontSize: "18px", color: "white" }} />
                  </IconButton>
                </span>
              )
            }
          </div>

          {/* modal content */}
          <div className="modal-details">
            {/* modal image and content */}
            <div id="modal-content" className="modal-content" style={props.loaderModal ? { boxShadow: "none", background: "transparent" } : {}}>
              {/* modal image */}
              {
                props.img && (
                  props.loaderModal ? (
                    props.img.image
                  ) : (
                    <div className="modal-image">
                      {props.img.image}
                    </div>
                  )
                )
              }

              {/* modal content icon and text */}
              <div id="modal-text-and-icon" className="modal-text-and-icon" style={getInlineStyleForTextAndIcon()}>
                {/* modal content icon */}
                {
                  props.bodyIcon && (
                    props.bodyIcon.icon
                  )
                }

                {/* modal content text */}
                <span id="modal-text" className="modal-text" style={{ width: props.bodyIcon ? "calc( 100% - 80px )" : "100%" }}>
                  {props.bodyHeader && (<span id="modal-content-header" className="modal-content-header" style={getInlineStyleForText(props.bodyHeader)}>{props.bodyHeader.text}</span>)}
                  {props.bodyContent && (<span id="modal-content-body" className="modal-content-body" style={getInlineStyleForText(props.bodyContent)}>{props.bodyContent.text}</span>)}
                </span>
              </div>

              {
                props.form &&
                (
                  <div id="modal-form" className="modal-form">
                    {props.form && (
                      props.form.form
                    )}
                  </div>
                )

              }
            </div>

            {/* modal content buttons */}
            <div id="modal-buttons" className="modal-buttons" style={getInlineStyleForButtons()}>
              {
                props.buttons ? props.buttons.map((button) => button) : null
              }
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
