import React, { Component } from 'react';
import DefaultTimePicker from 'rc-time-picker';
import PropTypes from 'prop-types';
import moment from 'moment';
import isEqual from 'lodash/isEqual';
import './style.scss';

class TimePicker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      value: props.defaultValue ? props.defaultValue : null,
    };
  }

  static getDerivedStateFromProps(props, state) {
    const newState = {};
    if (!isEqual(state.value, props.value) && props.isValueAltered) {
      newState.value = props.value ? props.value : null;
      return newState;
    }
    return null;
  }

  setOpen = ({ open }) => {
    this.setState({ open });
  }

  addbtn = () => (
    <button className="close-icon" onClick={this.toggleOpen}>Done</button>
  )

  handleValueChange = (value) => {
    this.setState({ value }, () => {
      this.props.onChange(value);
    });
  }

  toggleOpen = () => {
    const currentDateTime = moment();
    if (!this.state.value) {
      this.setState({ value: currentDateTime, open: !this.state.open });
      this.props.onChange(currentDateTime);
    } else {
      this.setState({ open: !this.state.open });
    }
  }

  render() {
    return (
      <div style={{ position: 'relative' }}>
        <DefaultTimePicker
          {...this.props}
          placeholder={this.props.placeholder ? this.props.placeholder : 'Select Time'}
          style={{ width: '100%', position: 'relative' }}
          showSecond={false}
          onChange={this.handleValueChange}
          format="hh:mm a"
          use12Hours
          value={this.state.value}
          getPopupContainer={(triggerNode) => triggerNode.parentNode}
          inputReadOnly
          open={this.state.open}
          clearIcon={<i className="icon clear-icon icon-font-a-clear-all primary-text-color" />}
          inputIcon={<i className="icon clear-icon icon icon-font-a-arrival-time primary-text-color" />}
          onClose={this.setOpen}
          onOpen={this.setOpen}
          addon={this.addbtn}
        />
      </div>
    );
  }
}

TimePicker.propTypes = {
  onChange: PropTypes.func.isRequired,
  defaultValue: PropTypes.any,
  placeholder: PropTypes.string,
  isValueAltered: PropTypes.bool,
};

TimePicker.defaultProps = {
  isValueAltered: false,
};

export default TimePicker;
