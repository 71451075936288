import React, { useState } from 'react';
import Modal from 'react-bootstrap/lib/Modal';
import { useDispatch, useSelector } from 'react-redux';
import './order-archive-modal.scss'
import { archivePatientOrder } from 'patientOrderSupport/common/actions';
import { dateStringToDateWithDateFormat } from 'utils/dateUtils';

const OrderArchiveModal = (props) => {

  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const dispatch = useDispatch();
  const orderDetails = useSelector((store) => store.patientOrdersReducer.orderDetails)

  const archiveTheOrder = (callBack) => {
    dispatch(archivePatientOrder(orderDetails.id, callBack))
  }
  const getFormatttedPhoneNumber = (phoneNumber) => {
    let phone = phoneNumber;
    phone = phone?.replace('+1', '')
    return phone?.substr(0, 3) + '-' + phone?.substr(3, 3) + '-' + phone?.substr(6, 4)
  }

  return (
    <>
      <Modal
        show={props.show}
        className="manager-modal"
        onHide={props.onHide}
        backdrop="static"
        aria-labelledby="contained-modal-title"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title">{props.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="order-detail-modal">
            <div className="patient-name">{orderDetails.fullName}</div>
            <div className="order-details">
              <div className="details-outer-wrapper">
                <div className="details-wrapper">
                  <div className="details-full">
                    <div className="key-value">
                      <span className="key">Date of Birth</span>
                      <span className="value">{orderDetails.dob}</span>
                    </div>
                    <div className="key-value">
                      <span className="key">Implanted Device Serial Number</span>
                      <span className="value">{orderDetails.deviceSerialNumber}</span>
                    </div>
                  </div>
                  <div className="title order-details-title">
                    <span>Order #{orderDetails.orderId} </span>
                    <div className="order-submitted-data last">
                      <span className="key">Order submitted on:&nbsp;</span>
                      <span className="value">{dateStringToDateWithDateFormat(orderDetails.orderPlacedOn)}</span>
                    </div>
                  </div>
                  <div className="details-full">
                    <div className="key-value">
                      <span className="key">Device Type</span>
                      <span className="value">{orderDetails.deviceType}</span>
                    </div>
                    <div className="key-value">
                      <span className="key">Part</span>
                      <span className="value">{orderDetails.devicePart}</span>
                    </div>
                    <div className="key-value">
                      <span className="key">Provider</span>
                      <span className="value">{orderDetails.provider}</span>
                    </div>
                  </div>
                  <div className="details-full">
                    <div className="key-value">
                      <span className="key">Date Noticed</span>
                      <span className="value">{orderDetails.damageDate}</span>
                    </div>
                    <div className="key-value">
                      <span className="key">Reason for replacement</span>
                      <span className="value">{orderDetails.reason}</span>
                    </div>
                  </div>
                  {orderDetails.reason === 'Other' ? <div className="key-value">
                    <span className="key">Additional Details</span>
                    <span className="value">{orderDetails.otherReasonComment}</span>
                  </div> : ""
                  }
                </div>
              </div>
              <div className="details-wrapper">
                <div className="details-outer-wrapper">
                  <div className="title shipping-info-title">Shipping Info</div>
                  <div className="details-wrapper">
                    <div className="details-full">
                      <div className="key-value">
                        <span className="key">Phone Number</span>
                        <span className="value">{getFormatttedPhoneNumber(orderDetails.address.phoneNumber)}</span>
                      </div>
                      <div className="key-value">
                        <span className="key">Email Address</span>
                        <span className="value">{orderDetails.address.emailAddress}</span>
                      </div>
                    </div>
                    <div className="details-full">
                      <div className="key-value">
                        <span className="key">Address</span>
                        <span className="value">{orderDetails.address.addressLine1}</span>
                      </div>
                      <div className="key-value">
                        <span className="key">Apt./Suite/etc.</span>
                        <span className="value">{orderDetails.address.addressLine2 || "NA"}</span>
                      </div>
                    </div>
                    <div className="details-full">
                      <div className="key-value">
                        <span className="key">City</span>
                        <span className="value">{orderDetails.address.city}</span>
                      </div>
                      <div className="key-value">
                        <span className="key">State</span>
                        <span className="value">{orderDetails.address.state}</span>
                      </div>
                      <div className="key-value">
                        <span className="key">Zip Code</span>
                        <span className="value">{orderDetails.address.zipCode}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          {orderDetails.status === 0 ? <div className="buttons-wrapper">
            <button className="btn btn-primary complete-btn" onClick={() => {
              setShowConfirmModal(true)
            }}>Process</button>
          </div> : <></>}
        </Modal.Footer>
      </Modal >

      <Modal
        show={showConfirmModal}
        onHide={() => setShowConfirmModal(false)}
        backdrop="static"
        className="confirm-modal"
        aria-labelledby="contained-modal-title"
        style={{ backgroundColor: "rgba(0,0,0,0.75)" }}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title"><span className="confirm-text-title">Confirmation</span></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="confirm-text">Clicking ‘Confirm’ will move this item to the archive</div>
        </Modal.Body>
        <Modal.Footer><>
          <button className="btn btn-default confirm-btn" onClick={() => setShowConfirmModal(false)}>Cancel</button>
          <button className="btn btn-primary confirm-btn" onClick={() => archiveTheOrder(() => {
            setShowConfirmModal(false);
            props.onHide();
          })}>Confirm</button>
        </>
        </Modal.Footer >
      </Modal >
    </>
  )
}

export default OrderArchiveModal;