import { isiOS } from 'utils/common';

export const USER_TYPE_PATIENT = 'Patient';

export const USER_TYPE_PROVIDER = 'Provider';

export const USER_TYPE_PATIENT_ADVOCATE = 'PatientAdvocate';

export const USER_TYPE_CAREGIVER = 'Caregiver';

export const USER_TYPE_PATIENT_ORDER_SUPPORT = 'PatientOrderSupport'

export const RESET_GLOBAL_REDUCERS = 'RESET_GLOBAL_REDUCERS';

export const SEARCHABLE_QUESTION_QUERY_LENGTH = 3;


export const IMAGE_FILE_FORMATS = ['image/jpg', 'image/bmp', 'image/gif', 'image/png', 'image/jpeg', 'image/tiff'];

export const DOC_FILE_FORMATS = ['application/vnd.openxmlformats-officedocument.presentationml.presentation', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/pdf', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/x-msi'];

export const AUDIO_FORMATS = ['audio/x-ms-wma', 'audio/mpeg', 'audio/mp4', 'audio/wavpack'];

export const MS_DOC_EXTENSIONS = ['application/msword', 'application/vnd.ms-excel', 'application/vnd.ms-powerpoint'];

export const ALL_FILE_FORMATS = IMAGE_FILE_FORMATS.concat(DOC_FILE_FORMATS).concat(AUDIO_FORMATS).concat(MS_DOC_EXTENSIONS);

export const COMMUNICATION_LOG_SUPPORTED_FILE_FORMATS = isiOS ? '*/*' : ALL_FILE_FORMATS.toString();

export const MM_DD_YYYY = "MM-DD-YYYY";

export const YYYY_MM_DD = "YYYY-MM-DD";

export const PATS_DATE_FORMAT = "MM/DD/YYYY"

export const PHONE_NUMBER_MASK = `999-999-9999`;

export const DEVICE_SERIAL_NUMBER_MASK = `Naaa999999a`;

export const SPECIAL_CHARS_TO_SKIP_FOR_NAME = /[~!@#$%^&*()\-_+={}\[\]|/\\:;"<>,.?]/

export const SPECIAL_CHARS_TO_SKIP_FOR_ADDRESS = /[`~!@$%^&*()\_+={}\[\]|/\\:;"<>?]/

export const PATS_ORDERS_DEFAULT_ACTIVE_TAB = 1
