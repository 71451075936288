import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import queryString from 'query-string';
import LightboxModal from 'components/LightboxModal';
import { compose, combineReducers } from 'redux';
import injectReducer from 'utils/injectReducer';
import { verifySamlAssertion } from './actions';
import { authSamlReducer } from './reducer';

class SamlCallback extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
    };
  }

  componentDidMount() {
    const isLoading = true;
    this.setState({
      isLoading,
    });
    this.props.onVerifySamlAssertion(this.getCode());
  }

  getCode = () => queryString.parse(this.props.location.search).code


  render() {
    return <LightboxModal show={this.state.isLoading} />;
  }
}

SamlCallback.propTypes = {
  onVerifySamlAssertion: PropTypes.func,
};
const mapStateToProps = (state, props) => ({
  isLoading: state.isLoading.authSamlReducer.isLoading,
});
const mapDispatchToProps = (dispatch) => ({
  onVerifySamlAssertion: (code) => dispatch(verifySamlAssertion(code)),
});


const withConnect = connect(mapStateToProps, mapDispatchToProps);

const withReducer = injectReducer({
  key: 'isLoading',
  reducer: combineReducers({
    authSamlReducer,
  }),
});
export default compose(
  withReducer,
  withConnect,
)(SamlCallback);
