import {
  VERIFY_PIN_REQUEST_SENT,
  VERIFY_PIN_REQUEST_SUCCEED,
  VERIFY_PIN_REQUEST_FAILED,
  RESEND_PIN_REQUEST_SENT,
  RESEND_PIN_REQUEST_SUCCEED,
  RESEND_PIN_REQUEST_FAILED,
  DOWNLOAD_SHARE_OUTCOMES_REPORT_FILE_REQUEST_SENT,
  DOWNLOAD_SHARE_OUTCOMES_REPORT_FILE_REQUEST_SUCCEED,
  DOWNLOAD_SHARE_OUTCOMES_REPORT_FILE_REQUEST_FAILED,
  VERIFY_PIN_REQUEST_FOR_HCP_OUTCOME_SENT,
  VERIFY_PIN_REQUEST_FOR_HCP_OUTCOME_SUCCEED,
  VERIFY_PIN_REQUEST_FOR_HCP_OUTCOME_FAILED,
  RESEND_PIN_REQUEST_FOR_HCP_OUTCOME_SENT,
  RESEND_PIN_REQUEST_FOR_HCP_OUTCOME_SUCCEED,
  RESEND_PIN_REQUEST_FOR_HCP_OUTCOME_FAILED,
} from './constants';
import { RESET_GLOBAL_REDUCERS } from '../../../constants';

const initialState = {
  isLoading: false,
  shareOutcomesRequest: null,
  shareReportRequest: null,
};

const viewShareOutcomesRequestReducer = (state = initialState, action) => {
  switch (action.type) {
    case VERIFY_PIN_REQUEST_SENT:
      return {
        ...state,
        isLoading: true,
      };
    case VERIFY_PIN_REQUEST_SUCCEED:
      return {
        ...state,
        isLoading: false,
        shareOutcomesRequest: action.data.share_outcomes_request,
      };
    case VERIFY_PIN_REQUEST_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    case DOWNLOAD_SHARE_OUTCOMES_REPORT_FILE_REQUEST_SENT:
      return {
        ...state,
        isLoading: true,
      };
    case DOWNLOAD_SHARE_OUTCOMES_REPORT_FILE_REQUEST_SUCCEED:
      return {
        ...state,
        isLoading: false,
      };
    case DOWNLOAD_SHARE_OUTCOMES_REPORT_FILE_REQUEST_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    case RESEND_PIN_REQUEST_SENT:
      return {
        ...state,
        isLoading: true,
      };
    case RESEND_PIN_REQUEST_SUCCEED:
      return {
        ...state,
        isLoading: false,
      };
    case RESEND_PIN_REQUEST_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    case RESET_GLOBAL_REDUCERS:
      return {
        ...state,
        isLoading: false,
        shareOutcomesRequest: null,
      };
    case VERIFY_PIN_REQUEST_FOR_HCP_OUTCOME_SENT:
      return {
        ...state,
        isLoading: true,
        shareOutcomesRequest: null,
        shareReportRequest: null,
      };
    case VERIFY_PIN_REQUEST_FOR_HCP_OUTCOME_SUCCEED:
      return {
        ...state,
        isLoading: false,
        shareOutcomesRequest: null,
        shareReportRequest: action.data.data.share_report_request,
      };
    case VERIFY_PIN_REQUEST_FOR_HCP_OUTCOME_FAILED:
      return {
        ...state,
        isLoading: false,
        shareOutcomesRequest: null,
        shareReportRequest: null,
      };
    case RESEND_PIN_REQUEST_FOR_HCP_OUTCOME_SENT:
      return {
        ...state,
        isLoading: true,
      };
    case RESEND_PIN_REQUEST_FOR_HCP_OUTCOME_SUCCEED:
      return {
        ...state,
        isLoading: false,
      };
    case RESEND_PIN_REQUEST_FOR_HCP_OUTCOME_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
};

export default viewShareOutcomesRequestReducer;
