import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { getActiveCompositeProcedureId } from 'services/storage';

import './styles.scss';

class QuestionSearchResultCard extends Component { // eslint-disable-line react/prefer-stateless-function
  onSearchItemClicked = () => {
    const compositeProcedureId = getActiveCompositeProcedureId();
    const { item } = this.props;
    if (item.isFaq) {
      // `/mydashboard/{compositeProcedureId}/questions/faqs/{userProcedureId}/{tagname}/{questionId}`
      // this.props.history.push(`/mydashboard/${compositeProcedureId}/questions/faqs/123/Overview%20of%20Spinal%20Cord%20Stimulation/${item.id}`);
      this.props.history.push(`/questions/${compositeProcedureId}/details/${item.id}`);
    } else {
      this.props.history.push(`/questions/${compositeProcedureId}/details/${item.id}`);
    }
  }

  render() {
    const { item } = this.props;
    return (
      <div role="button" tabIndex="0" className="search-result-row" onClick={this.onSearchItemClicked}>
        <div className="col-xs-11 no-padding no-margin">
          <div className="text">{item.text}</div>
          {item.questionCategory && <span className="category">{item.questionCategory}</span>}
          {item.procedureName && <div className="procedureName">{item.procedureName}</div>}
        </div>
        <span className="icon icon-font-a-right-chevron primary-text-color chevron-class float-right"></span>
        <div className="clearfix" />
      </div>
    );
  }
}

QuestionSearchResultCard.propTypes = {
  item: PropTypes.object,
  history: PropTypes.object,
};

export default compose(withRouter)(QuestionSearchResultCard);
