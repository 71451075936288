import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import "./component-style.scss";
import ChartDataLabels from "chartjs-plugin-datalabels";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels
);

const commonOptions = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      enabled: false,
    },
    datalabels: {
      align: "end",
      anchor: "end",
      offset: 0,
      font: {
        size: 8,
      },
      color: "black",
      formatter: (value) => {
        if (value == null) {
          return "N/A";
        } else {
          return ""; // Return an empty string to hide the label
        }
      },
    },
  },
  animation: {
    duration: 0,
  },
};

function BarChart(props) {
  const data = props.data;
  const includeCommonOptions = props.includeCommonOptions ?? true;
  const optionProps = props.options ?? {};
  const options = includeCommonOptions
    ? { ...commonOptions, ...optionProps }
    : { ...optionProps };
  const title = props.chartTitle;

  return (
    <>
      <p className={`title-font-regular chart-title ${props.titleClassNames}`}>
        {title}
      </p>
      <div className="chart-wrapper">
        <Bar options={options} data={data} />
      </div>
    </>
  );
}

export default BarChart;
