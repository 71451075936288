import React from 'react';
import Header from 'patientOrderSupport/components/Header';

const withPatientOrderSupportComponent = (childProps) => {

  const childComponent = (props) => (
    <childProps.component {...props} />
  )

  return (props) => {
    return (
      <div className="patient-order-support-container">
        {childProps.isHeaderNeeded ? <div className="patient-order-support-with-header">
          <Header />
          {childComponent(props)}
        </div> : <div className="patient-order-support-with-no-header">
          {childComponent(props)}
        </div>
        }
      </div >
    )
  }
}

export default withPatientOrderSupportComponent;