export const GET_USER_CONSENT_REQUEST_SENT = 'src/patientApp/GET_USER_CONSENT_REQUEST_SENT';
export const GET_USER_CONSENT_REQUEST_SUCCEED = 'src/patientApp/GET_USER_CONSENT_REQUEST_SUCCEED';
export const GET_USER_CONSENT_REQUEST_FAILED = 'src/patientApp/GET_USER_CONSENT_REQUEST_FAILED';

export const POST_USER_CONSENT_AGREED_REQUEST_SENT = 'src/patientApp/POST_USER_CONSENT_AGREED_REQUEST_SENT';
export const POST_USER_CONSENT_AGREED_REQUEST_SUCCEED = 'src/patientApp/POST_USER_CONSENT_AGREED_REQUEST_SUCCEED';
export const POST_USER_CONSENT_AGREED_REQUEST_FAILED = 'src/patientApp/POST_USER_CONSENT_AGREED_REQUEST_FAILED';

export const POST_USER_CONSENT_DISAGREED_REQUEST_SUCCEED = 'src/patientApp/POST_USER_CONSENT_DISAGREED_REQUEST_SUCCEED';
export const POST_USER_CONSENT_DISAGREED_REQUEST_SENT = 'src/patientApp/POST_USER_CONSENT_DISAGREED_REQUEST_SENT';
export const POST_USER_CONSENT_DISAGREED_REQUEST_FAILED = 'src/patientApp/POST_USER_CONSENT_DISAGREED_REQUEST_FAILED';

export const USER_CONSENT_CLEAR_STATE = 'src/patientApp/USER_CONSENT_CLEAR_STATE';

// CONSTANTS FROM WHERE CONSENT FORM IS SHOWN, TO DETERMINE ACTIONS ON CONSENT FORM
export const LAUNCHED_FROM_ADD_PROCEDURE = 'LAUNCHED_FROM_ADD_PROCEDURE';
export const LAUNCHED_FROM_MANAGE_PROCEDURE = 'LAUNCHED_FROM_MANAGE_PROCEDURE';
export const LAUNCHED_FROM_DASHBOARD = 'LAUNCHED_FROM_DASHBOARD';
