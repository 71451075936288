import './styles.scss';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const MobileInfoBlock = (props) => {
  return (
    <div onClick={props.onClick ? props.onClick : () => { }} className="info-block-wrapper" style={{ ...props.style }}>
      <InfoOutlinedIcon sx={{ fontSize: "20px", color: "#004B87" }} />
      <div className={`info-block-text ${props.textClickable ? 'text-clickable' : ''}`}>{props.onClick ? <a>{props.text}</a> : props.text}</div>
    </div >
  )
}

export default MobileInfoBlock;