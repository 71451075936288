import React, { useState, useEffect } from "react";
import "./mobile-style.scss";
import { throttle } from "utils/common";

const filteredOptionsUniqueIndexKey = "ybchs";

const MobileCustomMultiSelect = (props) => {
  const { options, onConfirmSelection, showOptions, selectedOptions } = props;

  const [tempSelectedOptions, setTempSelectedOptions] = useState([]);
  const [filteredOptions, setFilteredOptions] = useState([]);

  // Function to handle option selection
  const handleOptionSelect = (option) => {
    if (tempSelectedOptions.includes(option)) {
      // If the option is already selected, remove it from the tempSelectedOptions array
      setTempSelectedOptions(
        tempSelectedOptions.filter(
          (selectedOption) => selectedOption !== option
        )
      );
    } else {
      // If the option is not selected, add it to the tempSelectedOptions array
      setTempSelectedOptions([...tempSelectedOptions, option]);
    }
  };

  const handleAllOptionSelect = (event) => {
    event.target.checked ? setTempSelectedOptions(filteredOptions.map(item => item.value)) : setTempSelectedOptions([]);
  }

  const confirmSelection = () => {
    onConfirmSelection(tempSelectedOptions);
  };
  
  const updateFilterOptions = (e) => {
    e.preventDefault();
    let searchInput = document.getElementById('mobile-dropdown-searchInput').value.toLowerCase();
    setFilteredOptions(options.filter((option) => option.label.toLowerCase().includes(searchInput)))
  }
  
  const isChecked = () => {
    let values = filteredOptions.map(item => item.value);
    return (values.length && values.every(value => tempSelectedOptions.includes(value)));
  }

  const onSearchInputChanged = throttle(updateFilterOptions, 300);

  useEffect(() => {
    setTempSelectedOptions(selectedOptions ?? []);
    setFilteredOptions(options ?? []);
  }, [showOptions, options]);

  return showOptions ? (
    <div className="mobile-dropdown-wrapper">
      <form className="mobile-dropdown-wrapper__search-container">
      <input type="text" className="mobile-search-input" id="mobile-dropdown-searchInput" placeholder="Search..." onChange={onSearchInputChanged} autoFocus/>
        <span className="icon icon-font-a-close" onClick={(e) => {document.getElementById('mobile-dropdown-searchInput').value = ""; updateFilterOptions(e)}}/>
      </form>

      <div className="options-wrapper">
        <label key="select-all" id="select-all" className="option-label">
            <input
              type="checkbox"
              value="select-all"
              checked = {isChecked()}
              onChange={(event) => handleAllOptionSelect(event)}
            />
            <span>Select All</span>
        </label>
        {filteredOptions.map((option, index) => (
          <label key={`${filteredOptionsUniqueIndexKey} + ${index}`} id={option.label} className="option-label">
            <input
              type="checkbox"
              value={option.value}
              checked={tempSelectedOptions.includes(option.value)}
              onChange={() => handleOptionSelect(option.value)}
            />
            <span>{option.label}</span>
          </label>
        ))}
      </div>
      <button
        className="report-modal-button custom-dropdown-confirm-btn"
        onClick={confirmSelection}
      >
        Okay
      </button>
    </div>
  ) : (
    ""
  );
};

export default MobileCustomMultiSelect;
