import { currentTimezoneName } from 'utils/dateUtils';
import axios, { basicInstance } from './base';
import {
  GET_PROVIDER_FILTERS, ADD_NEW_PATIENT, GET_PROCEDURE_LOCATIONS, GET_PROCEDURE_PROVIDERS, GET_PROCEDURE_MEDTRONIC_MENTORS, GET_APPOINTMENTS_WITHIN_RANGE, GET_APPOINTMENTS_FOR_DATE, GET_HCP_OUTCOMES, GET_HCP_OUTCOMES_FILTERS,
  GET_HCP_OUTCOMES_LOCATIONS_FILTERS, SEND_HCP_FILTERS_DATA_REQUEST } from './constants';

export const getAppointmentsSearchResults = (params) => {
  return axios.get(GET_APPOINTMENTS_FOR_DATE, { params });
};

export const getAppointmentsSearchWithinRangeResults = (params) => {
  return basicInstance.get(GET_APPOINTMENTS_WITHIN_RANGE, { params });
};

export const getProviderFacets = (params) => axios.get(GET_PROVIDER_FILTERS, { params });

export const addNewPatient = (params) => axios.post(ADD_NEW_PATIENT, params);

export const getProcedureLocations = (params) => axios.get(GET_PROCEDURE_LOCATIONS, { params });

export const getProcedureProviders = (params, userType = 'Provider') => {
  if (userType === 'Provider') {
    return axios.get(GET_PROCEDURE_PROVIDERS, { params });
  }
  return axios.get(GET_PROCEDURE_MEDTRONIC_MENTORS, { params });
};

export const getHCPOutcomes = () => axios.get(GET_HCP_OUTCOMES);

export const getHCPOutcomesFilters = (locations) => axios.get(GET_HCP_OUTCOMES_FILTERS, { params: {location_ids: locations} });

export const getHCPOutcomesLocationFilters = () => axios.get(GET_HCP_OUTCOMES_LOCATIONS_FILTERS);

export const sendHCPFiltersData = (params) => axios.post(SEND_HCP_FILTERS_DATA_REQUEST, params);
