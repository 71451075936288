import React from "react";
import PatsBodyWrapper from "../Components/PatsOrderBodyWrapper";
import MobileDatePicker from "components/MobileComponents/MobileDatePicker";
import MobileSingleSelect from "components/MobileComponents/MobileSingleSelect";
import MobileTextAreaField from "components/MobileComponents/MobileTextAreaField";

function LostAndDamagedParts(props) {
  const {
    title,
    damageNoticedDateData,
    reasonsOptions,
    reasonForReplacementData,
    reasonCommentData,
    moveForward,
    validateData
  } = props;

  const validateGivenData = () => {
    let data = {
      reasonForReplacement: reasonForReplacementData.reasonForReplacement,
      damageNoticedDate: damageNoticedDateData.damageNoticedDate
    }
    if (reasonForReplacementData.reasonForReplacement === 'Other')
      data.reasonComment = reasonCommentData.reasonComment
    validateData(data, () => moveForward())
  }


  const checkIfButtonDisabled = () => {
    let ifDisabled = !reasonForReplacementData.reasonForReplacement || reasonForReplacementData.reasonForReplacement === 'undefined' || !damageNoticedDateData.damageNoticedDate
    if (reasonForReplacementData.reasonForReplacement === 'Other') {
      ifDisabled = ifDisabled || !reasonCommentData.reasonComment?.trim()
    }
    return ifDisabled
  }

  const getBody = () => {
    return (
      <div className="lost-and-damaged-parts-wrapper">

        <MobileDatePicker
          error={damageNoticedDateData.error}
          required
          disableFuture={true}
          overHeadLabel={<span className="input-label">Select the date you first noticed the damage/lost item:</span>}
          value={damageNoticedDateData.damageNoticedDate}
          onChange={damageNoticedDateData.setDamageNoticedDate} />

        <MobileSingleSelect
          error={reasonForReplacementData.error}
          required
          placeholder="Select Reason"
          value={reasonForReplacementData.reasonForReplacement}
          onChange={reasonForReplacementData.setReasonForReplacement}
          overHeadLabel={<span className="input-label">Select the reason for replacement:</span>}
          menuItems={reasonsOptions} />

        {reasonForReplacementData.reasonForReplacement === 'Other' ?
          <MobileTextAreaField
            error={reasonCommentData.error}
            maxLength={25}
            sx={{ width: "100%" }}
            value={reasonCommentData.reasonComment}
            onChange={reasonCommentData.setReasonComment}
            type="textarea"
            placeholder="*Briefly describe other damage to your device part here. Ex. ran over by car" /> :
          <></>}
      </div>
    )
  };

  return (
    <PatsBodyWrapper
      title={<h4 className="title-font">{title}</h4>}
      body={getBody()}
      buttons={
        [
          <button
            disabled={checkIfButtonDisabled()}
            className="pats-btn-primary"
            onClick={() => validateGivenData()}
          >
            Next: Shipping Info
          </button>,
        ]
      }
    />
  );
}

export default LostAndDamagedParts;
