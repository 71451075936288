import * as Constants from "./constants";
import { showToaster } from "common/toasterActions";

import { getHCPOutcomes, getHCPOutcomesFilters, getHCPOutcomesLocationFilters, sendHCPFiltersData } from "services/dashboard";

export const getHCPOutcomesChartRequestSent = () => ({
  type: Constants.HCP_OUTCOMES_CHART_REQUEST_SENT,
});

export const getHCPOutcomesChartRequestSucceeded = (payload) => ({
  type: Constants.HCP_OUTCOMES_CHART_REQUEST_SUCCEED,
  payload,
});

export const getHCPOutcomesChartRequestFailed = () => ({
  type: Constants.HCP_OUTCOMES_CHART_REQUEST_FAILED,
});

export const getHCPOutcomesResult = () => (dispatch) => {
  dispatch(getHCPOutcomesChartRequestSent());
  getHCPOutcomes()
  .then((response) => {
    dispatch(getHCPOutcomesChartRequestSucceeded(response.data));
  })
  .catch(() => {
    dispatch(getHCPOutcomesChartRequestFailed());
  });
};

export const getHCPOutcomesFiltersRequestSent = () => ({
  type: Constants.GET_HCP_OUTCOMES_FILTERS_REQUEST_SENT,
});

export const getHCPOutcomesFiltersRequestSucceeded = (payload) => ({
  type: Constants.GET_HCP_OUTCOMES_FILTERS_REQUEST_SUCCEED,
  payload,
});

export const getHCPOutcomesFiltersRequestFailed = () => ({
  type: Constants.GET_HCP_OUTCOMES_FILTERS_REQUEST_FAILED,
});

export const getHCPOutcomesFilterResult = (locations) => (dispatch) => {
  dispatch(getHCPOutcomesFiltersRequestSent());
  getHCPOutcomesFilters(locations)
  .then((response) => {
    dispatch(getHCPOutcomesFiltersRequestSucceeded(response.data));
  })
  .catch(() => {
    dispatch(getHCPOutcomesFiltersRequestFailed());
  });
};

export const getHCPOutcomesLocationFiltersRequestSent = () => ({
  type: Constants.GET_HCP_OUTCOMES_LOCATIONS_FILTER_REQUEST_SENT,
});

export const getHCPOutcomesLocationFiltersRequestSucceeded = (payload) => ({
  type: Constants.GET_HCP_OUTCOMES_LOCATIONS_FILTER_REQUEST_SUCCEED,
  payload,
});

export const getHCPOutcomesLocationFiltersRequestFailed = () => ({
  type: Constants.GET_HCP_OUTCOMES_LOCATIONS_FILTER_REQUEST_FAILED,
});

export const getHCPOutcomesLocationFiltersResult = () => (dispatch) => {
  dispatch(getHCPOutcomesLocationFiltersRequestSent());
  getHCPOutcomesLocationFilters()
  .then((response) => {
    dispatch(getHCPOutcomesLocationFiltersRequestSucceeded(response.data));
  })
  .catch(() => {
    dispatch(getHCPOutcomesLocationFiltersRequestFailed());
  });
};

export const sendHCPOutcomesFiltersDataRequestSent = () => ({
  type: Constants.SEND_HCP_OUTCOMES_FILTERS_DATA_REQUEST_SENT,
});

export const sendHCPOutcomesFiltersDataRequestSucceeded = (payload) => ({
  type: Constants.SEND_HCP_OUTCOMES_FILTERS_DATA_REQUEST_SUCCEED,
  payload,
});

export const sendHCPOutcomesFiltersDataRequestFailed = () => ({
  type: Constants.SEND_HCP_OUTCOMES_FILTERS_DATA_REQUEST_FAILED,
});

export const sendHCPOutcomesFiltersDataRequest = (data) => (dispatch) => {
  dispatch(sendHCPOutcomesFiltersDataRequestSent());
  sendHCPFiltersData(data)
  .then((response) => {
    dispatch(showToaster({ message: response.data.message }));
    dispatch(sendHCPOutcomesFiltersDataRequestSucceeded(response.data));
  })
  .catch(() => {
    dispatch(sendHCPOutcomesFiltersDataRequestFailed());
  });
};
