import * as Constants from './constants';


const initialState = {
  isLoading: false,
  patients: [],
  isMasqueradingSuccessful: false,
  masqueradingPatient: null,
};

const caregiverReducer = (state = initialState, action) => {
  switch (action.type) {
    case Constants.GET_PATIENTS_FOR_CAREGIVER_REQUEST_SENT:
      return {
        ...state,
        isLoading: true,
      };
    case Constants.GET_PATIENTS_FOR_CAREGIVER_REQUEST_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    case Constants.GET_PATIENTS_FOR_CAREGIVER_REQUEST_SUCCEEDED:
      return {
        ...state,
        patients: action.data,
        isLoading: false,
      };
    case Constants.POST_START_CAREGIVER_MASQUERADING_REQUEST_SENT:
      return {
        ...state,
        isLoading: true,
        isMasqueradingSuccessful: false,
      };
    case Constants.POST_START_CAREGIVER_MASQUERADING_REQUEST_FAILED:
      return {
        ...state,
        isLoading: false,
        isMasqueradingSuccessful: false,
      };
    case Constants.POST_START_CAREGIVER_MASQUERADING_REQUEST_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        isMasqueradingSuccessful: true,
        masqueradingPatient: action.data,
      };
    case Constants.CLEAR_MASQUERADING_FLAGS:
      return {
        ...state,
        isMasqueradingSuccessful: false,
        isLoading: false,
      };
    default:
      return state;
  }
};

export default caregiverReducer;
