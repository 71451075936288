import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { sideBarMenu } from "patientOrderSupport/containers/Dashboard/configs";
import { fetchPatientOrders, setSearchString } from "patientOrderSupport/common/actions";

const useHeaderViewModel = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const locSearch = new URLSearchParams(location.search);

  const activeTabKey = () => {
    let activeTabKey = sideBarMenu.find((menu) => menu.id === parseInt(locSearch.get('active_tab_id')))
    return activeTabKey.key
  }

  const onSearchSubmit = (e, value) => {
    e.preventDefault();
    if (!(value?.length > 0 && value?.trim()?.length === 0)) {
      dispatch(fetchPatientOrders(1, activeTabKey(), value?.trim()?.length ? value?.trim() : null));
      dispatch(setSearchString(value))
    }
  }

  return {
    onSearchSubmit
  }

}

export default useHeaderViewModel;