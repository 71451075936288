import React from "react";
import { generateUUID } from "utils/common";
import "./styles.scss";

const PatsBodyWrapper = (props) => {
  const { title, body, buttons } = props;
  return (
    <div className="pats-order-form-body-wrapper">
      <div className="pats-order-form-body">
        <div className="pats-order-form-title">
          {title}
        </div>
        <div className="pats-order-form-content">
          {body}
        </div>

      </div>
      {buttons.length > 0 && (
        <div className="patient-form-buttons-container">
          {buttons.map((button) => <span key={generateUUID()}>{button}</span>)}
        </div>
      )
      }
    </div >
  );
};

export default PatsBodyWrapper;
