import './styles.scss'
type ToastProps = {
    type?: 'success' | 'error';
    message?: string;
}
const Toast: React.FC<ToastProps> = (props) => {

    const { type, message } = props;
    const getToastClassFromType = (type: 'success' | 'error' | undefined) => {
        console.log(type)
        switch (type) {
            case "success":
                return "toast-success"
            case "error":
                return "toast-error"
            default:
                return "toast-success"
        }
    }

    return(
    <div data-testid="mobile-toaster" className={`toast-ios ${getToastClassFromType(type)}`}>
      <p data-testid="mobile-toaster-text" className='toast-text'>{message}</p>
    </div>
    )
}

export default Toast;
