import React from 'react';
import MessagesListCard from 'components/MessagesListCard';
import MessageResponse from 'containers/MessageResponse';
import LightboxModal from 'components/LightboxModal';
import Emptyview from 'components/Emptyview';
import NoQuestions from 'assets/images/no_questions.png';
import { getConversationMessages, sendMessage } from 'containers/Messages/actions';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import './index.scss';

class Messages extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      attachments: [],
    };
  }

  componentDidMount() {
    this.props.fetchConversationMessages(this.props.conversationId);
  }

  componentDidUpdate() {
    if (this.props.redirectToConversations) {
      this.props.goToConversations();
    }
  }

  sendMessage = (message) => {
    const mediaResources = [...this.state.attachments];
    this.props.sendMessage(this.props.conversationId, message, mediaResources);
  }

  onAttachmentsAdded = (attachments) => {
    this.setState({
      attachments,
    });
  }

  render() {
    const { patient, messagingFeature } = this.props.patientInfo;
    const allowMessaging = patient.signInCount > 0 && !messagingFeature.is_messaging_disabled;

    return (
      <div>
        <div className="tab-details-title hidden-lg hidden-md">
Messages for
          {this.props.conversationId}
        </div>
        <div className="row" style={{ marginBottom: '20px', marginTop: '10px' }}>
          <div className="col-xs-10">
Subject:&nbsp; 
            { this.props.conversation ? this.props.conversation.subject : '' }
            { this.props.conversation && this.props.conversation.startedWith &&  
              <span>&nbsp;(Started With: {this.props.conversation.startedWith.fullName})</span>
            }
          </div>
        </div>
        { allowMessaging &&
          <MessageResponse
            onSentMessage={(message) => this.sendMessage(message)}
            onAttachmentsAdded={this.onAttachmentsAdded}
          />
        }
        <LightboxModal show={this.props.isLoading} />
        <div className='row'>
          <div className='col-xs-12' style={allowMessaging ? { marginTop: '50px' } : {}}>
            {
              this.props.messages && this.props.messages.length > 0 ?
                this.props.messages.map((item) => (
                  <MessagesListCard
                    key={item.id}
                    item={item}
                    sent_by={item.sent_by}
                  />
                )) : <Emptyview imgSrc={NoQuestions} className="" message="No Messages" />
            }
          </div>  
        </div>
        {
          this.props.question && 
          <div className="light-font faq-section">
            <div>FAQ Question: { this.props.question.text }</div>
            <pre className="faq-answer">Answer: { this.props.question.answer.text }</pre>
            <span className="faq-tag">CareGuidePro FAQ</span>
          </div>
        }
      </div>
    );
  }
}

Messages.propTypes = {
  isLoading: PropTypes.bool,
  messages: PropTypes.array,
  conversation: PropTypes.object,
  conversationId: PropTypes.number,
  fetchConversationMessages: PropTypes.func,
  sendMessage: PropTypes.func,
  redirectToConversations: PropTypes.bool,
  goToConversations: PropTypes.func,
  question: PropTypes.object,
  patientInfo: PropTypes.object,
};

const mapStateToProps = (state) => ({
  isLoading: state.patientDetails.messages.isLoading,
  messages: state.patientDetails.messages.messages,
  conversation: state.patientDetails.messages.conversation,
  redirectToConversations: state.patientDetails.messages.redirect,
  question: state.patientDetails.messages.question,
  patientInfo: state.currentPatientInfo,
});

const mapDispatchToProps = (dispatch) => ({
  fetchConversationMessages: (conversationId) => dispatch(getConversationMessages(conversationId)),
  sendMessage: (conversationId, message, attachments) => dispatch(sendMessage(conversationId, message, attachments)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Messages);
