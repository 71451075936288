import { getAllFaqsService } from 'services/patient/questions';
import { setProcedureDetails } from '../actions';
import {
  GET_ALL_FAQS_REQUEST_SENT,
  GET_ALL_FAQS_REQUEST_SUCCEED,
  GET_ALL_FAQS_REQUEST_FAILED,
  RESET_ALL_FAQS_METADATA,
} from './constants';


export const getAllFaqsRequestSent = () => ({
  type: GET_ALL_FAQS_REQUEST_SENT,
});

export const getAllFaqsRequestSucceeded = (data) => ({
  type: GET_ALL_FAQS_REQUEST_SUCCEED,
  data,
});

export const getAllFaqsRequestFailed = () => ({
  type: GET_ALL_FAQS_REQUEST_FAILED,
});

export const resetFaqsAction = () => ({
  type: RESET_ALL_FAQS_METADATA,
});

// //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Redux Thunk actions:
// //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export const getAllQuestionFaqsRequest = (compositeProcedureId) => (dispatch) => {
  dispatch(getAllFaqsRequestSent());
  getAllFaqsService(compositeProcedureId)
    .then((response) => {
      dispatch(getAllFaqsRequestSucceeded(response.data));
      dispatch(setProcedureDetails(response.data.miniUserProcedures));
    })
    .catch(() => {
      dispatch(getAllFaqsRequestFailed());
    });
};
