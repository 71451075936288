import React from 'react';
import Pagination from 'components/Pagination';
import './data-table.scss'
import useDataTableViewModel from './viewModel';
import { generateUUID } from 'utils/common';


const DataTable = (props) => {

  const { dataTableHeaders, getHeaderColumn, data, pagination, handlePagination } = useDataTableViewModel(props);
  return (
    <div className={`${data.length ? 'data-table-wrapper' : 'data-table-wrapper-no-data'}`}>
      <div className="table-wrapper">
        <table className="data-table">
          <thead className="table-header">
            <tr className="table-row">
              {
                dataTableHeaders.map((header) => {
                  return getHeaderColumn(header)
                })
              }
            </tr>
          </thead>
          <tbody className="table-body">
            {data.length ?
              data.map((data) => {
                return (<tr key={data.id} onClick={data.onClick} className={`table-row ${data.rowHighlight ? 'highlight' : ''}`}>
                  {dataTableHeaders.map((header) => (
                    <td key={generateUUID()}><span className={header.classes}>{data[header.key]}</span></td>
                  ))
                  }
                </tr>)
              }) :
              <td colspan={dataTableHeaders.length}>
                <div className="no-order-text-section">
                  {props.emptyDataMessage}
                </div>
              </td>
            }
          </tbody>
        </table>
      </div>
      {data.length ?
        <div className="pagination-wrapper">
          <Pagination pagination={pagination} title="Orders" handlePagination={handlePagination} />
        </div> : <></>
      }
    </div >
  )
}

export default DataTable;