import { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import InputMask from "react-input-mask";
import { makeStyles } from '@mui/styles';
import './styles.scss';

const MobileTextField = (props) => {

  const [error, setError] = useState(props.error);

  useEffect(() => {
    setError(props.error)
  }, [props.error])

  const useStyles = makeStyles({
    root: {
      "& .MuiOutlinedInput-input": {
        fontSize: "14px !important",
        color: "black !important",
        '&::placeholder': {
          textOverflow: 'ellipsis !important',
          color: "#999",
          fontSize: "14px",
          fontWeight: 400,
          lineHeight: "24px",
          borderRadius: "6px"
        }
      },
      "& .Mui-error": {
        fontSize: "1.2rem !important",
        color: "#F4787D !important",
        "& .MuiOutlinedInput-notchedOutline": {
          border: "1px solid #F4787D !important",
          borderRadius: "6px"
        },
      },
      "& .MuiOutlinedInput-notchedOutline": {
        border: "1px solid #999 !important",
        borderRadius: "6px",
        "& .Mui-error": {
          border: "1px solid #F4787D !important",
        }
      },
    },

  });

  const maskErrorMessage = (value) => {
    switch (props.inputMask[value.length]) {
      case 'a':
        return "Invalid Input: Please enter a character (e.g., A, B, C)."
      case '9':
        return "Invalid Input: Please enter a number (e.g., 1, 2, 3)."
      default:
        return ''
    }
  }


  const formatMaskedValueForSetting = (value) => props.upperCase ? value.toUpperCase() : value

  const updateValue = (e) => {
    if (props.specialCharsToSkip && e.target.value.match(props.specialCharsToSkip)) {
      return
    }
    props.onChange(formatMaskedValueForSetting(e.target.value))
  }

  const classes = useStyles();

  return (
    <div className="text-field-wrapper">
      <div className={`input-label ${props.required ? 'required' : ''}`}>{props.overHeadLabel}</div>
      {props.inputMask ?
        <InputMask
          mask={props.inputMask}
          maskChar=""
          value={props.value}
          onChange={(e) => {
            if (props.showErrorForMasking) {
              if (e.nativeEvent.inputType !== "deleteContentBackward") {
                if (e.target.value.length === props.value?.length) {
                  setError(maskErrorMessage(e.target.value))
                } else {
                  setError(props.error)
                }
              }
            }
            updateValue(e)
          }}
          placeholder={props.placeholder}
        >
          {() => (
            < TextField
              autoComplete="off"
              disabled={props.disabled}
              sx={{ ...props.sx }} error={error ? true : false}
              helperText={error} id="outlined-basic"
              className={classes.root}
              placeholder={props.placeholder}
              type={props.type}
              onKeyUp={(e) => {
                if (props.showErrorForMasking) {
                  if (e.key === 'Backspace')
                    setError(props.error)
                }
              }
              }
              variant="outlined"
            />)}
        </InputMask> :

        <TextField
          disabled={props.disabled}
          sx={{ ...props.sx }} error={error ? true : false}
          onChange={(e) =>
            updateValue(e)
          }
          helperText={error} id="outlined-basic"
          className={classes.root} value={props.value}
          placeholder={props.placeholder}
          type={props.type}
          variant="outlined"
          inputProps={{ maxLength: props.maxLength || 3000 }}
          data-testid="text-field-input"
        />
      }
    </div >
  );
}

export default MobileTextField;
