import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { resetOrderDetailsData, fetchPatientOrderDetail, fetchPatientOrders, updateOrderDataAferSorting } from 'patientOrderSupport/common/actions';
import { isToday, isYesterday, dateTimeStringToMMSTimeFormat, dateTimeOnly } from 'utils/dateUtils';
import { sortData } from 'utils/common';
import { sideBarMenu } from "./configs";

const usePatientSupportDashboardViewModel = () => {

  const activeTabType = {
    id: 1,
    key: "",
    activeTabTableHeaders: []
  }

  const [dataLocally, setDataLocally] = useState([])
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const locSearch = new URLSearchParams(location.search);

  const fetchTabData = () => {
    let activeTabId = locSearch.get('active_tab_id');
    if (activeTabId) {
      let activeTab = sideBarMenu.find((menu) => menu.id === parseInt(activeTabId));
      return {
        activeTabId: parseInt(activeTabId), activeTabKey: activeTab.key, activeTabTableHeaders: activeTab.tableHeaders
      }
    }
    return activeTabType
  }
  const [activeTab, setActiveTab] = useState(activeTabType)

  const [showArchiveModal, setShowArchiveModal] = useState(false);

  const isLoading = useSelector((store) => store.patientOrdersReducer.isLoading);
  const searchString = useSelector((store) => store.patientOrdersReducer.searchString);

  const fetchOrderDetails = (id) => {
    dispatch(fetchPatientOrderDetail(id, () => setShowArchiveModal(true)))
  }

  const onArchiveModalHide = () => {
    setShowArchiveModal(false);
    dispatch(resetOrderDetailsData);
  }

  const updateActiveTabData = (activeTabId) => {
    history.push(location.pathname + '?active_tab_id=' + activeTabId)
  }

  // if default tab is set in config, it will automatically redirect there
  useEffect(() => {
    let menu = sideBarMenu.find((menu) => menu.isDefault)
    if (locSearch.get("active_tab_id")) {
      updateActiveTabData(locSearch.get("active_tab_id"))
    }
    else if (menu)
      updateActiveTabData(menu.id)
  }, [])

  useEffect(() => {
    setActiveTab(fetchTabData())
  }, [locSearch.get("active_tab_id")])


  const getBeautifiedDateFormat = (date) => {
    if (date) {
      if (isToday(date))
        return `Today, ${dateTimeStringToMMSTimeFormat(date)}`
      else
        return dateTimeOnly(date)
    }
    return null
  }

  const sortDataLocally = (field, order) => {
    let isDateField = activeTab.activeTabTableHeaders.find((header) => header.key === field)?.isDateField
    dispatch(updateOrderDataAferSorting(sortData(data, field, order, isDateField)))
  }

  const data = useSelector((store) => {
    return store.patientOrdersReducer.data
  })

  useEffect(() => {
    setDataLocally(data.map((data) => {
      return ({
        ...data,
        fullName: data.fullName,
        onClick: () => fetchOrderDetails(data.id),
        orderPlacedOn: getBeautifiedDateFormat(data.orderPlacedOn),
        rowHighlight: isToday(data.orderPlacedOn),
        orderProcessedOn: getBeautifiedDateFormat(data.orderProcessedOn)
      })
    }))
  }, [data])

  const pagination = useSelector((store) => store.patientOrdersReducer.pagination)

  useEffect(() => {
    if (activeTab.activeTabKey)
      dispatch(fetchPatientOrders(1, activeTab.activeTabKey))
  }, [activeTab.activeTabId])

  const handlePagination = (page) => {
    dispatch(fetchPatientOrders(page, activeTab.activeTabKey, searchString))
  }

  const getEmptyDataMessage = () => (
    <span className="no-order-text">{searchString ? <span>No results matched your search. < br /> Please try again.</span> : <span>This folder is empty.</span>}</span>
  )


  return ({
    activeTab,
    searchString,
    updateActiveTabData,
    data: dataLocally,
    pagination,
    isLoading,
    showArchiveModal,
    onArchiveModalHide,
    sortData: sortDataLocally,
    handlePagination,
    emptyDatMessage: getEmptyDataMessage()
  })
}

export default usePatientSupportDashboardViewModel;