import { create } from 'zustand';

export type ToastType = {
    id: number,
    type: 'error' | 'success';
    duration?: number;
    message?: string;
}

interface Toasters {
    toasters: ToastType[];
    addToaster: (toast: ToastType) => void;
    removeToaster: (id:number) => void;
}




export const useToastStore = create<Toasters>((set, get)=>({
    toasters:[],
    addToaster: (toast: ToastType) => set((state) =>({toasters: [...get().toasters, {...toast, id: get().toasters.length + 1}]})),
    removeToaster: (id:number) => set((state) => ({toasters: get().toasters.filter((toast) => toast.id !== id)}))
}));
