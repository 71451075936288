export const SET_PROCEDURES_DETAILS = 'src/patientApp/Questions/SET_PROCEDURES_DETAILS';
export const RESET_PROCEDURES_DETAILS = 'src/patientApp/Questions/RESET_PROCEDURES_DETAILS';
export const SEARCH_QUESTION_REQUEST_SENT = 'src/patientApp/CreateQuestion/SEARCH_QUESTION_REQUEST_SENT';
export const SEARCH_QUESTION_REQUEST_SUCCEED = 'src/patientApp/CreateQuestion/SEARCH_QUESTION_REQUEST_SUCCEED';
export const SEARCH_QUESTION_REQUEST_FAILED = 'src/patientApp/CreateQuestion/SEARCH_QUESTION_REQUEST_FAILED';

export const HIDE_SUGGESTIONS_DROPDOWN = 'src/patientApp/CreateQuestion/HIDE_SUGGESTIONS_DROPDOWN';
export const SEARCH_QUESTION_CLEAR_RESULT = 'src/patientApp/CreateQuestion/SEARCH_QUESTION_CLEAR_RESULT';

export const COMMUNICATION_OPTION = {
  TWO_WAY: 'two_way',
  ONE_WAY: 'one_way',
  REQUEST_CALLBACK: 'request_callback',
  NO_COMMUNICATION: 'no_communication',
};
