import camelcaseKeys from 'change-case-object';
import { setAuthHeaders, deleteAuthHeaders } from 'services/base';
import { showToaster } from 'common/toasterActions';

import { SIGN_IN_URL } from 'services/constants';
import * as StorageService from 'services/storage';
import {
  registerUserService, signInUserService, signOutService, getTermsAndConditionUrlService,
} from 'services/deviceAuth';

import {
  getUserInfo as getUserInfoCall,
  updateUserProfile as updateUserProfileCall, updateUserEmailCall, updateUserProfilePicture as updateUserProfilePictureCall,
  deleteAccountService, getCurrentUserInfo as getCurrentUserInfoCall,
} from 'services/users';

import Actions from './authActions';
import { RESET_GLOBAL_REDUCERS } from '../constants';

// //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Redux Thunk actions:
// //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const registerUser = (userRegistrationDetails) => (dispatch) => {
  dispatch(Actions.registrationRequestSent());
  registerUserService(userRegistrationDetails)
    .then((response) => {
      setAuthHeaders(response.headers);
      dispatch(Actions.registrationRequestSucceeded(camelcaseKeys.camelCase(response.data.data)));
      StorageService.persistAuthHeadersInDeviceStorage(response.headers);
    })
    .catch((error) => {
      if (error.message === 'Network Error') {
        dispatch(showToaster({ message: 'No internet connection. Please check your internet settings', type: 'error' }));
      }
      dispatch(Actions.registrationRequestFailed());
    });
};

export const getTermsAndConditionsUrl = (emailOrContactNumber) => (dispatch) => {
  dispatch(Actions.getTermsAndConditionUrlRequestSent());
  getTermsAndConditionUrlService({ email_or_contact_number: emailOrContactNumber })
    .then((response) => {
      dispatch(Actions.getTermsAndConditionUrlRequestSucceeded(camelcaseKeys.camelCase(response.data)));
    })
    .catch((error) => {
      if (error.message === 'Network Error') {
        dispatch(showToaster({ message: 'Could not reach server. Please try again.', type: 'error' }));
      }
      dispatch(Actions.getTermsAndConditionUrlRequestFailed());
      let message = 'Something went wrong';
      if (error.response && error.response.data && error.response.data.error) {
        message = error.response.data.error;
      }
      dispatch(showToaster({ message, type: 'error' }));
    });
};

export const signInUser = (userSignInCredentials) => (dispatch) => {
  dispatch(Actions.signInRequestSent());
  signInUserService(userSignInCredentials)
    .then((response) => {
      setAuthHeaders(response.headers, response.data.access_token);
      StorageService.persistAuthHeadersInDeviceStorage(response.headers, response.data.access_token);
      const currentUser = camelcaseKeys.camelCase(response.data.data);
      StorageService.setItemToStorage('currentUser', JSON.stringify(currentUser));
      dispatch(Actions.signInRequestSucceeded(currentUser));
      dispatch(Actions.resetPatientLoginPrevented());
    })
    .catch((error) => {
      let message = 'Something went wrong';

      if (error.response && error.response.data) {
        if (error.response.data.errors) {
          message = error.response.data.errors.length > 0 ? error.response.data.errors[0] : 'Something went wrong';
        }
      }

      if (message == 'Patient web login is not allowed'){
        dispatch(Actions.patientLoginPrevented());
      }
      else{
        dispatch(Actions.resetPatientLoginPrevented());
      }

      dispatch(Actions.signInRequestFailed());

      if (error.message === 'Network Error') {
        message = 'No internet connection. Please check your internet settings';
      }

      if (message != 'Patient web login is not allowed'){
        dispatch(showToaster({ message, type: 'error' }));
      }
    });
};

export const hideLoginErrorModalAction = () => (dispatch) => {
  dispatch(Actions.resetPatientLoginPrevented());
};

export const signOutUser = () => (dispatch) => {
  dispatch(Actions.signOutRequestSent());
  signOutService()
    .then(() => {
      dispatch(handlePostLogoutAction());
      dispatch(Actions.resetPatientLoginPrevented());
    })
    .catch(() => {
      dispatch(Actions.signOutRequestFailed());
    });
};

export const getCurrentUserInformation = () => (dispatch) => {
  dispatch(Actions.getCurrentUserInfoRequestSent());
  getCurrentUserInfoCall()
    .then((response) => {
      StorageService.setItemToStorage('currentUser', JSON.stringify(camelcaseKeys.camelCase(response.data.data)));
      dispatch(Actions.getCurrentUserInfoRequestSucceeded(camelcaseKeys.camelCase(response.data.data)));
    })
    .catch((error) => {
      dispatch(Actions.getCurrentUserInfoRequestFailed());
    });
};

export const getCurrentUserInfo = (store) => {
  if (StorageService.getItemFromStorage('token')) {
    // const verificationParams = {
    //   'token': StorageService.getItemFromStorage('token'),
    //   client: StorageService.getItemFromStorage('client'),
    //   uid: StorageService.getItemFromStorage('uid'),
    // };
    store.dispatch(getCurrentUserInformation());
  } else {
    store.dispatch(Actions.setHasVerificationBeenAttempted(true));
  }
};

export const verifyExistingLocalToken = (store) => {
  if (StorageService.getItemFromStorage('expiry')) {
    if (StorageService.getItemFromStorage('expiry') >= new Date().getTime()) {
      StorageService.deleteAuthHeadersFromDeviceStorage();
      deleteAuthHeaders();
      store.dispatch(showToaster({ message: 'Session Expired. Please login again' }));
    } else {
      getCurrentUserInfo(store);
    }
  }
};


export const getUserInfo = () => (dispatch) => {
  dispatch(Actions.getProfileRequestSent());
  getUserInfoCall()
    .then((response) => {
      dispatch(Actions.getProfileRequestSucceeded((response.data)));
    })
    .catch(() => {
      dispatch(Actions.getProfileRequestFailed());
    });
};

export const updateUserInfo = (params) => (dispatch) => {
  dispatch(Actions.updateUserProfileRequestSent());
  updateUserProfileCall(params)
    .then((response) => {
      updateLocalUserStore(dispatch);
      dispatch(Actions.updateUserProfileRequestSucceeded((response.data)));
    })
    .catch((error) => {
      dispatch(Actions.updateUserProfileRequestFailed(error));
    });
};

export const updateUserProfilePicture = (params) => (dispatch) => {
  dispatch(Actions.updateUserProfilePictureRequestSent());
  updateUserProfilePictureCall(params)
    .then((response) => {
      updateLocalUserStore(dispatch);
      dispatch(getUserInfo());
      dispatch(Actions.updateUserProfilePictureRequestSucceeded((response.data)));
      dispatch(showToaster({ message: 'Profile picture updated successfully.' }));
    })
    .catch((error) => {
      dispatch(Actions.updateUserProfilePictureRequestFailed(error));
    });
};

export const updateUserEmail = (params) => (dispatch) => {
  dispatch(Actions.updateUserEmailRequestSent());
  updateUserEmailCall(params)
    .then((response) => {
      updateLocalUserStore(dispatch);
      dispatch(Actions.updateUserEmailRequestSucceeded((response.data)));
    })
    .catch(() => {
      dispatch(Actions.updateUserEmailRequestFailed());
    });
};


export const updateLocalUserStore = (dispatch) => {
  if (StorageService.getItemFromStorage('token')) {
    dispatch(getCurrentUserInformation());
  }
};

export const deleteUserAccount = () => (dispatch) => {
  dispatch(Actions.deleteAccountRequestSent());
  deleteAccountService()
    .then(() => {
      dispatch(Actions.deleteAccountRequestSucceeded());
      dispatch(handlePostLogoutAction());
    })
    .catch(() => {
      dispatch(Actions.deleteAccountRequestFailed());
    });
};

export const handlePostLogoutAction = (postLogoutRedirectUrl = null) => (dispatch) => {
  deleteAuthHeaders();
  StorageService.deleteAuthHeadersFromDeviceStorage();
  dispatch(Actions.signOutRequestSucceeded());
  StorageService.clearDefaultStorage();
  dispatch({ type: RESET_GLOBAL_REDUCERS });
};
