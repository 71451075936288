import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Modal from "react-bootstrap/lib/Modal";
import Button from "react-bootstrap/lib/Button";
import validate, { clearErrorsForField } from "common/validator";
import HelpBlock from "components/HelpBlock";
import {
  validationConfig,
} from "./validator";
import {
  getHCPOutcomesFilterResult,
  sendHCPOutcomesFiltersDataRequest,
} from "containers/HCPOutcomes/actions";
import "./styles.scss";
import Datetime from "react-datetime";
import { CustomMultiSelect } from "components/SelectV2";
import LightboxModal from "components/LightboxModal";
import PhoneInput from "components/PhoneInput";
import WarningPopup from "./warningPopup";
import Strings from "strings";

function areSelectionsEqual(oldSelections, newSelections) {
  return (
    oldSelections
      .map((x) => x.value)
      .sort()
      .join(",") ===
    newSelections
      .map((x) => x.value)
      .sort()
      .join(",")
  );
}

export default function ShareHCPOutcomesReportModal(props) {
  const [recipientDetails, setRecipientDetails] = useState([
    {
      first_name: "",
      last_name: "",
      email: "",
      contact_number: "",
    },
  ]);
  const [disclaimerError, setDisclaimerError] = useState([]);
  const [errors, setErrors] = useState({});
  const dispatch = useDispatch();
  const filters = useSelector((store) => store.hcpOutcomesReducer.filters);
  const isLoading = useSelector((store) => store.hcpOutcomesReducer.isLoading);
  const locations = useSelector((store) => store.hcpOutcomesReducer.locations);
  const country = useSelector((store) => store.currentUser.attributes.country);
  const [selectedDevices, setSelectedDevices] = useState([]);
  const [selectedHcp, setSelectedHcp] = useState([]);
  const [selectedTherapy, setSelectedTherapy] = useState([]);
  const [selectedIndication, setSelectedIndication] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState([]);
  const [selectedStartDate, setStartDate] = useState("");
  const [selectedEndDate, setEndDate] = useState("");
  const [message, setMessage] = useState("");
  const [showLocationChangeWarning, setShowLocationChangeWarning] =
    useState(false);
  const [tempSelectedLocations, setTempSelectedLocations] = useState([]);

  const extractValuesFromObject = (Object) => {
    return Object.map((o) => o.label);
  };

  const onShareReportModalClose = () => {
    props.hide();
    setRecipientDetails([
      {
        first_name: "",
        last_name: "",
        email: "",
        contact_number: "",
      },
    ]);
    setDisclaimerError([]);
    setErrors({});
    setSelectedDevices([]);
    setSelectedHcp([]);
    setSelectedIndication([]);
    setSelectedLocation([]);
    setTempSelectedLocations([]);
    setStartDate("");
    setEndDate("");
  };

  const addRecipient = () => {
    if (
      recipientDetails.length < process.env.REACT_APP_SHARE_REPORT_MAX_RECIPIENT
    ) {
      setRecipientDetails([
        ...recipientDetails,
        {
          first_name: "",
          last_name: "",
          email: "",
          contact_number: "",
        },
      ]);
    } else {
      setErrors({
        recipientDetails: `You can only add up to ${process.env.REACT_APP_SHARE_REPORT_MAX_RECIPIENT} recipients.`,
      });
    }
  };

  const changeNameFieldHandler = (e, index) => {
    let tempRecipientDetails = [...recipientDetails];
    tempRecipientDetails[index][e.target.name] = e.target.value;
    clearErrorsForField(errors, e.target.name);
    setRecipientDetails(tempRecipientDetails);
  };

  const changeEmailOrContactNumberHandler = (e, index) => {
    let tempRecipientDetails = [...recipientDetails];
    tempRecipientDetails[index][e.target.name] = e.target.value;
    clearErrorsForField(errors, e.target.name + index);
    disclaimerError[index] = false;
    setRecipientDetails(tempRecipientDetails);
    setDisclaimerError(disclaimerError);
  };

  const onRecipientFormValidationFailure = (errors) => {
    setErrors(errors);
  };

  const onLocationFilterFormValidationFailure = (errors) => {
    setErrors(errors);
  };

  const locationDropdownClosed = () => {
    if ((selectedLocation.length === 0 && tempSelectedLocations.length > 0) || 
        !areSelectionsEqual(selectedLocation, tempSelectedLocations) && areOtherFiltersEmpty() ) {
      setSelectedLocation(tempSelectedLocations);
      let locations = tempSelectedLocations.map((location) => location.value);
      dispatch(getHCPOutcomesFilterResult(locations));
    } else if (!areSelectionsEqual(selectedLocation, tempSelectedLocations) && !areOtherFiltersEmpty()) {
      setShowLocationChangeWarning(true);
    }
  };

  const onLocationFilterFormValidationSuccess = () => {
    if (recipientDetails[0].email !== "" || recipientDetails[0].contact_number !== "") {
      setDisclaimerError([]);
      setErrors({});

      let recipients = [];

      recipientDetails.map((recipient) => {
        let contact_number =
          country === "AU"
            ? recipient.contact_number.replace("+61", "")
            : recipient.contact_number.replace("+1", "");
        contact_number = contact_number.replace(/ /g, "");
        recipients.push({
          first_name: recipient.first_name,
          last_name: recipient.last_name,
          email: recipient.email,
          contact_number: contact_number,
        });
      });
      const data = {
        recipient_details: recipients,
        filters: {
          device_type: selectedDevices.length === filters.deviceTypes.length ? ["All"] : extractValuesFromObject(selectedDevices),
          physicians: selectedHcp.length === filters.physicians.length ? ["All"] : extractValuesFromObject(selectedHcp),
          // selected_therapy: extractValuesFromObject(selectedTherapy),
          indications: selectedIndication.length === filters.indications.length ? ["All"] : extractValuesFromObject(selectedIndication),
          account: selectedLocation.length === locations.length ? ["All"] : extractValuesFromObject(selectedLocation),
          start_date: selectedStartDate,
          end_date: selectedEndDate,
        },
        message: message,
      };
      dispatch(sendHCPOutcomesFiltersDataRequest(data));
      onShareReportModalClose();
    }
  };

  const onMessageChange = (e) => {
    setMessage(e.target.value);
  };

  const removeFromValidationConfig = (config, field) => {
    const index = config.fields.indexOf(field);
    if (index > -1) {
      config.fields.splice(index, 1);
    }
  }

  const onShareOutcomesReportSubmit = (e) => {
    setErrors({});
    if(recipientDetails[0].email === "" && recipientDetails[0].contact_number === "") {
      setDisclaimerError([true]);
    } else if(recipientDetails[0].contact_number === "") {
      delete validationConfig.rules.contact_number;
      removeFromValidationConfig(validationConfig, "contact_number");
    } else {
      delete validationConfig.rules.email;
      removeFromValidationConfig(validationConfig, "email");
    }

    validate(
      validationConfig,
      {
        locationFilter: tempSelectedLocations,
        startDate: selectedStartDate,
        endDate: selectedEndDate,
        first_name: recipientDetails[0].first_name,
        last_name: recipientDetails[0].last_name,
        email: recipientDetails[0].email,
        contact_number: recipientDetails[0].contact_number,
      },
      onLocationFilterFormValidationFailure,
      onLocationFilterFormValidationSuccess
    );
  };

  const onDeviceFilterChange = (selectedOption) => {
    setSelectedDevices(selectedOption);
  };

  const onHcpFilterChange = (selectedOption) => {
    setSelectedHcp(selectedOption);
  };

  const onIndicationFilterChange = (selectedOption) => {
    setSelectedIndication(selectedOption);
  };

  const onLocationFilterChange = (selectedOption) => {
    if (selectedOption.length > 0) {
      clearErrorsForField(errors, "locationFilter");
      setTempSelectedLocations(selectedOption);
    } else {
      setTempSelectedLocations([]);
    }
  };

  const removeRecipientFields = (index) => {
    if (recipientDetails.length > 1) {
      let tempRecipientDetails = [...recipientDetails];
      tempRecipientDetails.splice(index, 1);
      setRecipientDetails(tempRecipientDetails);
      setErrors({});
    }
  };

  const clearAllFilters = () => {
    setSelectedLocation(tempSelectedLocations);
    setShowLocationChangeWarning(false);
    setSelectedDevices([]);
    setSelectedIndication([]);
    setSelectedHcp([]);
    let locations = tempSelectedLocations.map((location) => location.value);
    dispatch(getHCPOutcomesFilterResult(locations));
  };

  const keepAllFilters = () => {
    setShowLocationChangeWarning(false);
    setTempSelectedLocations(selectedLocation);
  };

  const areOtherFiltersEmpty = () => {
    return (selectedDevices.length === 0 && selectedIndication.length === 0 && selectedHcp.length === 0)
  }

  return (
    <>
      <WarningPopup
        clearAllFilters={clearAllFilters}
        keepAllFilters={keepAllFilters}
        show={showLocationChangeWarning}
      />
      <LightboxModal show={isLoading} />
      <Modal
        show={props.show}
        onHide={onShareReportModalClose}
        container={document.body}
        autoFocus
        aria-labelledby="contained-modal-title"
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title">
            {`Share HCP Outcomes Report`}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="outcomes-sharing-modal form-group">
            <div className="report-recipients-wrapper">
              <div className="row filters-container">
                <div
                  className={`margin-bottom-12 col-xs-12 ${
                    errors.locationFilter ? "has-error" : ""
                  }`}
                >
                  <label className="field-label">
                    Account <sup className="app-red">*</sup>
                  </label>
                  <CustomMultiSelect
                    allowSelectAll
                    name="locationFilter"
                    id="select-location-filter"
                    onChange={onLocationFilterChange}
                    placeholder="Select Account"
                    value={tempSelectedLocations}
                    options={locations !== undefined ? locations : []}
                    onMenuClose={locationDropdownClosed}
                  />
                  <HelpBlock value={errors.locationFilter} />
                </div>
                {
                  selectedLocation.length ? 
                  (
                  <>
                    <div
                      className={`margin-bottom-12 col-xs-6 ${
                        errors.deviceFilter ? "has-error" : ""
                      }`}
                    >
                      <label className="field-label">Device Type</label>
                      <CustomMultiSelect
                        allowSelectAll
                        isDisabled={tempSelectedLocations.length > 0 ? false : true}
                        onChange={onDeviceFilterChange}
                        placeholder="Select Device Type"
                        value={selectedDevices}
                        options={
                          (filters && Object.keys(filters).includes("deviceTypes")) ? filters.deviceTypes : []
                        }
                      />
                      <HelpBlock className="help-block-info" value={errors.deviceFilter ? errors.deviceFilter : Strings.DEFAULT_ALL_FILTERS_SELECTED.replace('%filterName', "Device Types")} />
                    </div>
                    <div
                      className={`margin-bottom-12 col-xs-6 ${
                        errors.hcpFilter ? "has-error" : ""
                      }`}
                    >
                      <label className="field-label">Physician</label>
                      <CustomMultiSelect
                        isDisabled={tempSelectedLocations.length > 0 ? false : true}
                        allowSelectAll
                        id="select-hcp-filter"
                        onChange={onHcpFilterChange}
                        placeholder="Select Physician"
                        value={selectedHcp}
                        options={
                          (filters && Object.keys(filters).includes("physicians")) ? filters.physicians : []
                        }
                      />
                      <HelpBlock className="help-block-info" value={errors.hcpFilter ? errors.hcpFilter : Strings.DEFAULT_ALL_FILTERS_SELECTED.replace('%filterName', "Physicians")} />
                    </div>
                    <div
                      className={`margin-bottom-12 col-xs-12 ${
                        errors.indicationFilter ? "has-error" : ""
                      }`}
                    >
                      <label className="field-label">Indications</label>
                      <CustomMultiSelect
                        isDisabled={tempSelectedLocations.length > 0 ? false : true}
                        allowSelectAll
                        id="select-indication-filter"
                        onChange={onIndicationFilterChange}
                        placeholder="Select Indication"
                        value={selectedIndication}
                        options={
                          (filters && Object.keys(filters).includes("indications")) ? filters.indications : []
                        }
                      />
                      <HelpBlock className="help-block-info" value={errors.indicationFilter ? errors.indicationFilter : Strings.DEFAULT_ALL_FILTERS_SELECTED.replace('%filterName', "Indications")} />
                    </div>
                  </>
                  )
                  : null
                }
              </div>
              <div className="row" style={{marginTop: '12px'}}>
                <div
                  className={`report-date-picker col-xs-6 ${
                    errors ? "has-error" : ""
                  }`}
                >
                  <label htmlFor="startDate">
                    Start Date <sup className="app-red">*</sup>
                  </label>
                  <Datetime
                    timeFormat={false}
                    className="date-picker-field float-left date-field form-control"
                    inputProps={{
                      placeholder: "Start Date",
                      readOnly: true,
                    }}
                    closeOnSelect
                    closeOnTab
                    onChange={(date) => {
                      setStartDate(date.format("YYYY-MM-DD"));
                      clearErrorsForField(errors, "startDate");
                    }}
                  />
                  <HelpBlock value={errors.startDate} />
                </div>
                <div
                  className={`report-date-picker col-xs-6 ${
                    errors ? "has-error" : ""
                  }`}
                >
                  <label htmlFor="endDate">
                    End Date <sup className="app-red">*</sup>
                  </label>
                  <Datetime
                    timeFormat={false}
                    className="date-picker-field float-left date-field form-control"
                    inputProps={{
                      placeholder: "End Date",
                      readOnly: true,
                    }}
                    closeOnSelect
                    closeOnTab
                    onChange={(date) => {
                      setEndDate(date.format("YYYY-MM-DD"));
                      clearErrorsForField(errors, "endDate");
                    }}
                  />
                  <HelpBlock value={errors.endDate} />
                </div>
              </div>
              {recipientDetails.map((element, index) => (
                <div
                  className={
                    index
                      ? "report-recipients background-style"
                      : "report-recipients"
                  }
                >
                  <div className="row ">
                    <div>
                      <div className="col-xs-6">
                        {recipientDetails.length > 1 ? (
                          <p className="recipient-count">
                            Recipient: {index + 1}
                          </p>
                        ) : (
                          ""
                        )}
                      </div>
                      {index ? (
                        <div className="col-xs-6">
                          <i
                            className="icon icon-font-a-close float-right"
                            onClick={() => removeRecipientFields(index)}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div className="row">
                    <div
                      className={`form-group col-xs-6 ${
                        errors.first_name !== undefined
                          ? "has-error"
                          : ""
                      }`}
                    >
                      <label htmlFor="first_name">
                        First Name <sup className="app-red">*</sup>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="First Name"
                        name="first_name"
                        onChange={(e) => changeNameFieldHandler(e, index)}
                        value={element.first_name}
                      />
                      <HelpBlock value={errors.first_name} />
                    </div>
                    <div
                      className={`form-group col-xs-6 ${
                        errors.last_name ? "has-error" : ""
                      }`}
                    >
                      <label htmlFor="last_name">
                        Last Name <sup className="app-red">*</sup>{" "}
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Last Name"
                        onChange={(e) => changeNameFieldHandler(e, index)}
                        name="last_name"
                        value={element.last_name}
                      />
                      <HelpBlock value={errors.last_name} />
                    </div>
                  </div>
                  <div
                    className={`email-contactnumber-disclaimer ${
                      disclaimerError[index] ? "has-error" : ""
                    }`}
                  >
                    Note: Specify email or mobile phone number
                  </div>
                  <div className="row">
                    <div
                      className={`form-group col-xs-6 ${
                        errors.email !== undefined ? "has-error" : ""
                      }`}
                    >
                      <label htmlFor="email">Email</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Email"
                        name="email"
                        onChange={(e) =>
                          changeEmailOrContactNumberHandler(e, index)
                        }
                        value={element.email}
                      />
                      <HelpBlock value={errors.email} />
                    </div>
                    <div
                      className={`form-group col-xs-6 ${
                        errors.contact_number !== undefined
                          ? "has-error"
                          : ""
                      }`}
                    >
                      <label htmlFor="contact_number">Mobile Number</label>
                      <PhoneInput
                        className="form-control"
                        id="phone"
                        name="contact_number"
                        placeholder="Mobile Number"
                        value={element.contact_number}
                        onChange={(e) =>
                          changeEmailOrContactNumberHandler(e, index)
                        }
                        country={country}
                      />
                      <HelpBlock value={errors.contact_number} />
                    </div>
                  </div>
                </div>
              ))}
              {/* {recipientDetails.length < process.env.REACT_APP_SHARE_REPORT_MAX_RECIPIENT
                ? (
                <div className="add-recipient-holder">
                  <div className={recipientDetails.length < process.env.REACT_APP_SHARE_REPORT_MAX_RECIPIENT ? "add-recipient" : "add-recipient add-recipient-desabled"}
                    disabled={ recipientDetails.length < process.env.REACT_APP_SHARE_REPORT_MAX_RECIPIENT ? "" : "disabled"}
                    onClick={addRecipient}>
                    <i className="icon icon-font-a-add-caregiver" />
                    <span>Add Recipient</span>
                  </div>
                  <span className="recipient">
                    {`You can only add up to ${process.env.REACT_APP_SHARE_REPORT_MAX_RECIPIENT} recipients.`}
                  </span>
                </div>
                )
                : ""
              }
              {/* <div className="add-recipient-holder">
                <div className={recipientDetails.length < process.env.REACT_APP_SHARE_REPORT_MAX_RECIPIENT ? "add-recipient" : "add-recipient add-recipient-desabled"}
                  disabled={ recipientDetails.length < process.env.REACT_APP_SHARE_REPORT_MAX_RECIPIENT ? "" : "disabled"}
                  onClick={addRecipient}>
                  <i className="icon icon-font-a-add-caregiver" />
                  <span>Add Recipient</span>
                </div>
                <span className="recipient">
                  {errors.recipientDetails ? errors.recipientDetails : ""}
                </span>
              </div> */}
              <div className="messages-textbox form-group">
                <label htmlFor="message">Message to Recipient (optional)</label>
                <textarea
                  type="text"
                  name="message"
                  placeholder="Let the recipient know why you are sharing this report with them"
                  onChange={onMessageChange}
                />
              </div>
            </div>
            <div className="float-right action-button-container">
              <Button
                bsStyle="primary"
                onClick={() => onShareOutcomesReportSubmit()}
              >
                Share Outcomes Report
              </Button>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </>
  );
}