import 'mdn-polyfills/MouseEvent';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { MenuItem } from 'react-bootstrap/lib';
import { LinkContainer } from 'react-router-bootstrap';
// eslint-disable-next-line react/prefer-stateless-function
export default class DDMenuItem extends Component {
  onMenuItemClick = (e) => {
    if (this.props.onClick) {
      this.props.onClick(e);
    }
    document.dispatchEvent(new MouseEvent('click'));
  }

  render() {
    const {
      onClick, to, toBeReplaced, ...rest
    } = this.props;
    return (
      <LinkContainer to={to} replace={toBeReplaced}>
        <MenuItem {...rest} onClick={(e) => this.onMenuItemClick(e)} />
      </LinkContainer>
    );
  }
}

DDMenuItem.defaultProps = {
  toBeReplaced: false,
};

DDMenuItem.propTypes = {
  to: PropTypes.string.isRequired,
  toBeReplaced: PropTypes.bool,
};
