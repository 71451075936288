import React, { useState } from 'react';
import './styles.scss';

const ProgressBar = (props) => {
  const { totalBars, activeBar } = props;

  const renderBars = () => {
    let arr = []
    let constantMinus = (totalBars - 1) * totalBars
    for (let i = 1; i <= totalBars; i++) {
      arr.push(<span key = {i} className={`bar ${(i <= activeBar) ? 'active' : ''}`} style={{ width: `calc((100% - ${constantMinus}px)/4)` }} ></ span >)
    }
    return arr;
  }

  return (
    <div className="progress-bar-wrapper">
      {
        renderBars()
      }
    </div>
  )
}

export default ProgressBar;