import React from "react";
import EditShippingDetails from "./EditShippingDetails";
import ConfirmShippingDetails from "./ConfirmShippingDetails";

export default function ShippingDetails(props) {
  const { addressData, validateData, moveForward, editShippingDetailsData, statesOptions, title
  } = props;

  const { firstName,
    lastName,
    addressLine1,
    city,
    state,
    zipCode,
    country,
    phoneNumber } = addressData;

  const onSubmitShippingDetails = () => {
    let data = {
      firstName: firstName.value,
      lastName: lastName.value,
      addressLine1: addressLine1.value,
      city: city.value,
      state: state.value,
      zipCode: zipCode.value ? zipCode.value : "",
      country: country.value,
      phoneNumber: phoneNumber.value ? phoneNumber.value : "",
    }
    validateData(data, () => { moveForward(); editShippingDetailsData.setEditShippingDetails(false) }, () => { if (!editShippingDetailsData.editShippingDetails) editShippingDetailsData.setEditShippingDetails(true) })
  }

  return editShippingDetailsData.editShippingDetails ? (
    <EditShippingDetails
      title="Edit shipping details"
      addressData={addressData}
      validateData={validateData}
      moveForward={moveForward}
      onSubmitShippingDetails={onSubmitShippingDetails}
      setEditShippingDetails={editShippingDetailsData.setEditShippingDetails}
      statesOptions={statesOptions}
    />
  ) : (
    <ConfirmShippingDetails
      toggleEditShippingDetails={editShippingDetailsData.toggleEditShippingDetails}
      title={title}
      addressData={addressData}
      onSubmitShippingDetails={onSubmitShippingDetails}
    />
  );
}
