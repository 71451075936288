import {
  GET_USER_CONSENT_REQUEST_SENT,
  GET_USER_CONSENT_REQUEST_SUCCEED,
  GET_USER_CONSENT_REQUEST_FAILED,

  POST_USER_CONSENT_AGREED_REQUEST_SENT,
  POST_USER_CONSENT_AGREED_REQUEST_SUCCEED,
  POST_USER_CONSENT_AGREED_REQUEST_FAILED,

  POST_USER_CONSENT_DISAGREED_REQUEST_SENT,
  POST_USER_CONSENT_DISAGREED_REQUEST_SUCCEED,
  POST_USER_CONSENT_DISAGREED_REQUEST_FAILED,

  USER_CONSENT_CLEAR_STATE,
} from './constants';


const initialState = {
  isLoading: false,
  consentFormText: '',
  consentTitle: '',
  hasMultipleProcedures: false,
  userFullName: '',
  procedureId: null,
  isConsentFormSigned: false,
  isConsentFormDeclined: false,
  isConsentFormShown: false,
  isRequestFailed: false,
};

const userConsentReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_USER_CONSENT_REQUEST_SENT:
      return {
        ...state,
        isLoading: true,
      };
    case GET_USER_CONSENT_REQUEST_SUCCEED:
      return {
        ...state,
        isLoading: false,
        consentFormText: action.data.consentBody,
        consentTitle: action.data.consentTitle,
        hasMultipleProcedures: action.data.hasMultipleProcedures,
        userFullName: action.data.userFullName,
        procedureId: action.data.procedureId,
        isConsentFormShown: true,
      };
    case GET_USER_CONSENT_REQUEST_FAILED:
      return {
        ...state,
        isLoading: false,
        isRequestFailed: true,
      };

    case POST_USER_CONSENT_AGREED_REQUEST_SENT:
      return {
        ...state,
        isLoading: true,
      };
    case POST_USER_CONSENT_AGREED_REQUEST_SUCCEED:
      return {
        ...state,
        isLoading: false,
        isConsentFormSigned: true,
      };
    case POST_USER_CONSENT_AGREED_REQUEST_FAILED:
      return {
        ...state,
        isLoading: false,
        isRequestFailed: true,
      };

    case POST_USER_CONSENT_DISAGREED_REQUEST_SENT:
      return {
        ...state,
        isLoading: true,
      };
    case POST_USER_CONSENT_DISAGREED_REQUEST_SUCCEED:
      return {
        ...state,
        isLoading: false,
        isConsentFormDeclined: true,
      };
    case POST_USER_CONSENT_DISAGREED_REQUEST_FAILED:
      return {
        ...state,
        isLoading: false,
        isRequestFailed: true,
      };
    case USER_CONSENT_CLEAR_STATE:
      return initialState;
    default:
      return state;
  }
};

export default userConsentReducer;
