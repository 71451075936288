export const VERIFY_PIN_REQUEST_SENT = 'src/patientApp/ViewShareOutcomesRequest/VERIFY_PIN_REQUEST_SENT';
export const VERIFY_PIN_REQUEST_SUCCEED = 'src/patientApp/ViewShareOutcomesRequest/VERIFY_PIN_REQUEST_SUCCEED';
export const VERIFY_PIN_REQUEST_FAILED = 'src/patientApp/ViewShareOutcomesRequest/VERIFY_PIN_REQUEST_FAILED';

export const DOWNLOAD_SHARE_OUTCOMES_REPORT_FILE_REQUEST_SENT = 'src/patientApp/ViewShareOutcomesRequest/DOWNLOAD_SHARE_OUTCOMES_REPORT_FILE_REQUEST_SENT';
export const DOWNLOAD_SHARE_OUTCOMES_REPORT_FILE_REQUEST_SUCCEED = 'src/patientApp/ViewShareOutcomesRequest/DOWNLOAD_SHARE_OUTCOMES_REPORT_FILE_REQUEST_SUCCEED';
export const DOWNLOAD_SHARE_OUTCOMES_REPORT_FILE_REQUEST_FAILED = 'src/patientApp/ViewShareOutcomesRequest/DOWNLOAD_SHARE_OUTCOMES_REPORT_FILE_REQUEST_FAILED';

export const RESEND_PIN_REQUEST_SENT = 'src/patientApp/ViewShareOutcomesRequest/RESEND_PIN_REQUEST_SENT';
export const RESEND_PIN_REQUEST_SUCCEED = 'src/patientApp/ViewShareOutcomesRequest/RESEND_PIN_REQUEST_SUCCEED';
export const RESEND_PIN_REQUEST_FAILED = 'src/patientApp/ViewShareOutcomesRequest/RESEND_PIN_REQUEST_FAILED';

export const VERIFY_PIN_REQUEST_FOR_HCP_OUTCOME_SENT = 'src/patientApp/ViewShareOutcomesRequest/VERIFY_PIN_REQUEST_FOR_HCP_OUTCOME_SENT';
export const VERIFY_PIN_REQUEST_FOR_HCP_OUTCOME_SUCCEED = 'src/patientApp/ViewShareOutcomesRequest/VERIFY_PIN_REQUEST_FOR_HCP_OUTCOME_SUCCEED';
export const VERIFY_PIN_REQUEST_FOR_HCP_OUTCOME_FAILED = 'src/patientApp/ViewShareOutcomesRequest/VERIFY_PIN_REQUEST_FOR_HCP_OUTCOME_FAILED';

export const RESEND_PIN_REQUEST_FOR_HCP_OUTCOME_SENT = 'src/patientApp/ViewShareOutcomesRequest/RESEND_PIN_REQUEST_FOR_HCP_OUTCOME_SENT';
export const RESEND_PIN_REQUEST_FOR_HCP_OUTCOME_SUCCEED = 'src/patientApp/ViewShareOutcomesRequest/RESEND_PIN_REQUEST_FOR_HCP_OUTCOME_SUCCEED';
export const RESEND_PIN_REQUEST_FOR_HCP_OUTCOME_FAILED = 'src/patientApp/ViewShareOutcomesRequest/RESEND_PIN_REQUEST_FOR_HCP_OUTCOME_FAILED';

export const STATUSES = {
  PREPARED: 'sent',
  REQUESTED: 'submitted',
  INPROGESS: 'in_progress',
  FAILED: 'failed',
};
