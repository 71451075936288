import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/lib/Modal";
import PhoneInput from "components/PhoneInput";
import { useSelector, useDispatch } from "react-redux";
import validate, { clearErrorsForField } from "common/validator";
import HelpBlock from "components/HelpBlock";
import axios from "axios";
import { SEND_HCP_FILTERS_DATA_REQUEST, DEFAULT_URL } from "services/constants";
import { showToaster } from "common/toasterActions";
import { getAuthValues } from "utils/webContainerAuth";
import moment from "moment-timezone";
import { YYYY_MM_DD, MM_DD_YYYY } from "constants";

export default function ReportRecipientForm(props) {
  const { onClose, selectedFilters, closeReportRecipientForm } = props;
  const country = useSelector((store) => store.currentUser.attributes.country);
  const [errors, setErrors] = useState({});
  const dispatch = useDispatch();
  const [recipientDetails, setRecipientDetails] = useState({
    first_name: "",
    last_name: "",
    email: "",
    contact_number: "",
  });

  const [message, setMessage] = useState("");

  const validationConfig = {
    fields: ["first_name", "last_name", "email", "contact_number"],
    rules: {
      first_name: [{ rule: "isRequired", message: "First name is required" }],
      last_name: [{ rule: "isRequired", message: "Last name is required" }],
      email: [{ rule: "isEmail", message: "Email is invalid" }],
      contact_number: [{ rule: "isPhone", message: "Contact number is invalid" }],
    },
  };

  const onChangingNameField = (e) => {
    if (e.target.name === "email" || e.target.name === "contact_number") {
      clearErrorsForField(errors, "disclaimer_error");
    }

    clearErrorsForField(errors, e.target.name);
    let tempRecipientDetails = { ...recipientDetails };
    tempRecipientDetails[e.target.name] = e.target.value;
    setRecipientDetails({
      ...recipientDetails,
      [e.target.name]: e.target.value,
    });
  };

  const onShareOutcomesReportSubmit = (e) => {
    setErrors({});

    if (!recipientDetails["email"] && !recipientDetails["contact_number"]) {
      setErrors({ ...errors, ["disclaimer_error"]: true });
    }
    if (recipientDetails["email"] !== "" && recipientDetails["email"] !== undefined) {
      delete validationConfig.rules.contact_number;
      let index = validationConfig.fields.indexOf("contact_number")
      if (index > 1) validationConfig.fields.splice(index, 1)
    } else {
      delete validationConfig.rules.email;
      let index = validationConfig.fields.indexOf("email")
      if (index > 1) validationConfig.fields.splice(index, 1)
    }

    validate(
      validationConfig,
      recipientDetails,
      onRecipientFormValidationFailure,
      onRecipientFormValidationSuccess
    );
  };

  const onRecipientFormValidationFailure = (e) => {
    setErrors(e);
  };
  
  const shareReport = () => {
    const data = {
      recipient_details: [recipientDetails],
      filters: { ...selectedFilters, start_date: moment(selectedFilters.start_date, MM_DD_YYYY).format(YYYY_MM_DD), end_date: moment(selectedFilters.end_date, MM_DD_YYYY).format(YYYY_MM_DD)},
      message: message,
    };

    return axios.post(`${DEFAULT_URL + SEND_HCP_FILTERS_DATA_REQUEST}`, data, { headers: getAuthValues() });
  };

  const onMessageChange = (e) => {
    setMessage(e.target.value);
  };

  const clearShareReportForm = () => {
    setRecipientDetails({
      first_name: "",
      last_name: "",
      email: "",
      contact_number: "",
    });
    setMessage("");
  }

  const onRecipientFormValidationSuccess = () => {
    if (recipientDetails["email"] || recipientDetails["contact_number"]) {
      shareReport()
        .then((response) => {
          dispatch(showToaster({ message: response.data.message }));
          clearShareReportForm();
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  return (
    <Modal
      show={true}
      onHide={onClose}
      container={document.body}
      autoFocus
      aria-labelledby="contained-modal-title"
      backdrop="static"
      className="hcp-report-sharing-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title" className="recipient-detail-modal-title">
          Share Clinical Overview Report
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="report-recipient-form-wrapper">
          <div
            className={`form-group ${errors["first_name"] ? "has-error" : ""}`}
          >
            <label htmlFor="first_name">
              First Name <sup className="app-red">*</sup>
            </label>
            <input
              type="text"
              className="form-control"
              placeholder="First Name"
              name="first_name"
              onChange={(e) => onChangingNameField(e)}
              value={recipientDetails.first_name}
            />
            <HelpBlock value={errors["first_name"] ? errors["first_name"] : null} />
          </div>
          <div
            className={`form-group ${errors["last_name"] ? "has-error" : ""} `}
          >
            <label htmlFor="last_name">
              Last Name <sup className="app-red">*</sup>
            </label>
            <input
              type="text"
              className="form-control"
              placeholder="Last Name"
              onChange={(e) => onChangingNameField(e)}
              name="last_name"
              value={recipientDetails.last_name}
            />
            <HelpBlock value={errors["last_name"] ? errors["last_name"] : null} />
          </div>

          <div
            className={`email-contactnumber-disclaimer ${errors["disclaimer_error"] ? "has-error" : ""
              }`}
          >
            Note: Specify email or mobile phone number
          </div>

          <div className={`form-group ${errors["contact_number"] ? "has-error" : ""}`}>
            <label htmlFor="contact_number">Mobile phone number</label>
            <PhoneInput
              type="number"
              className="form-control"
              id="phone"
              name="contact_number"
              placeholder="Mobile phone number"
              value={recipientDetails.contact_number}
              onChange={(e) => onChangingNameField(e)}
              country={country}
            />
            <HelpBlock value={errors["contact_number"] ? errors["contact_number"] : null} />
          </div>
          <div className={`form-group ${errors["email"] ? "has-error" : ""}`}>
            <label htmlFor="email">Email</label>
            <input
              type="email"
              className="form-control"
              placeholder="Email"
              name="email"
              onChange={(e) => onChangingNameField(e)}
              value={recipientDetails.email}
            />
            <HelpBlock value={errors["email"] ? errors["email"] : null} />
          </div>
          <div className="messages-textbox form-group">
            <label htmlFor="message">Message to Recipient (optional)</label>
            <textarea
              type="text"
              name="message"
              placeholder="Enter a description..."
              onChange={onMessageChange}
              value={message}
            />
          </div>
        </div>
        <div className="share-actions">
          <button
            className="report-modal-button share-report-button"
            onClick={onShareOutcomesReportSubmit}
          >
            Share Report
          </button>
          <button
            className="report-modal-button report-modal-hollow-button"
            onClick={closeReportRecipientForm}
          >
            Return to Selection Review
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
}
