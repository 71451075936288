import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import queryString from 'query-string';
import { DEFAULT_URL } from 'services/constants';
import PassiveModeFooter from 'patientApp/components/PassiveModeFooter';
import FloatingLabelInput from 'components/FloatingLabelInput';
import LightboxModal from 'components/LightboxModal';
import './styles.scss';
import { getFormatedPhoneNumber } from 'utils/stringUtils';
import validate from 'common/validator';
import HelpBlock from 'components/HelpBlock';
import { getVerificationCode, verifyCode } from './actions';

const validationConfig = {
  fields: ['verificationCode'],
  rules: {
    verificationCode: [{ rule: 'isRequired', message: 'Verification Code is required' }],
  },
};

class PassiveModeInfoWithOTP extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {},
      verificationCode: '',
      termsAndConditionAcceptanceState: false,
      showErrorForNotSelectingTermsAndConditions: false,
      timer: 60,
    };
  }

  componentDidMount() {
    this.props.onGetVerificationCode(this.getLinkKey());
    this.startTimer();
  }

  setTermAndCondition = () => {
    this.setState({
      termsAndConditionAcceptanceState: !this.state.termsAndConditionAcceptanceState,
    });
  };

  startTimer = () => {
    setInterval(this.updateTimer, 1000);
  }

  stopTimer = () => {
    clearInterval(this.interval);
  }

  updateTimer = () => {
    const newTimerValue = this.state.timer - 1;
    if (newTimerValue < 0) {
      return;
    }
    this.setState({
      timer: newTimerValue,
    });
  }

  getContactNumber = () => this.props.contactNumber

  getLinkKey = () => this.props.redirectUri.substring('/passive-mode/interactions/'.length)

  handleResend = () => {
    this.props.onGetVerificationCode(this.getLinkKey());
    this.setState({
      timer: 60,
    });
  }

  onNext = (event) => {
    event.preventDefault();
    if (!this.state.termsAndConditionAcceptanceState) {
      this.setState({
        showErrorForNotSelectingTermsAndConditions: true,
      });
      return;
    }
    validate(
      validationConfig,
      this.state,
      this.onFormValidationFailure,
      this.onFormValidationSuccess
    );
  }

  onVerificationCodeChange = (e) => {
    this.setState({
      verificationCode: e.target.value.trim(),
    });
  };

  onFormValidationFailure = (errors) => {
    this.setState({ errors });
  };

  onFormValidationSuccess = () => {
    this.setState({
      errors: {},
    });
    this.props.onVerifyCode(this.getLinkKey(), this.state.verificationCode, this.state.termsAndConditionAcceptanceState, this.props.redirectUri);
  }

  render() {
    if (this.props.isLoading) {
      return <LightboxModal show={this.props.isLoading} />;
    }
    return (
      <div className="passive-mode-info-containter-otp">
        <div className="row">
          <div className="col-lg-offset-4 col-md-offset-4  col-sm-offset-3 col-lg-4 col-md-4 col-sm-6 col-xs-12 login-form-container no-padding">
            <div className="login-form-view text-center">
              <div className="text-info">
                We&apos;ve sent a verification code to:
                <br />
                {getFormatedPhoneNumber(this.getContactNumber(), this.props.userInfo.country)}
                <br />
              </div>
              <div className="text-info-small">
                <span>
                  Didn&apos;t get the code? You can request another
                  {this.state.timer > 0 ?
                    `${' in  '}
                  ${this.state.timer}
                  ${'  seconds '}`
                    : ' now '}
                </span>
                <br />
                <span className={`link cursor-pointer resend-link ${this.state.timer > 0 ? 'not-active' : ''}`} onClick={this.handleResend}>
                  Resend Code
                </span>
              </div>

              <form name="form" onSubmit={this.onNext}>
                <div
                  className={`form-group pos-rel ${this.state.errors.verificationCode !== undefined ? 'has-error' : ''}`}
                >
                  <FloatingLabelInput
                    type="text"
                    className="form-control"
                    value={this.state.verificationCode}
                    label="Verification Code"
                    name="verificationCode"
                    onChange={this.onVerificationCodeChange}
                  />
                  <HelpBlock value={this.state.errors.verificationCode} />
                </div>

                <div className="clearfix"></div>

                <div className="term-and-condition-container">
                  <span onClick={this.setTermAndCondition}>
                    <i
                      className={`icon ${
                        this.state.termsAndConditionAcceptanceState
                          ? 'icon icon-font-a-tick-unfilled'
                          : 'icon-font-a-check-box'
                      }`}
                    >
                    </i>
                  </span>
                  &nbsp;
                  <span className="term-and-condition-label">
                    I have read and agree to the&nbsp;
                    <a
                      rel="noopener"
                      href={`${DEFAULT_URL}/user/terms_and_conditions`}
                      target="_blank"
                    >
                      <span className="term-and-condition-link">
                        Terms and Conditions
                      </span>
                    </a>
                  </span>
                  {!this.state.termsAndConditionAcceptanceState && this.state.showErrorForNotSelectingTermsAndConditions && (
                    <span className="error-for-term">
                      <br />
                      You need to accept Terms and Conditions
                      <br />
                      <br />
                    </span>
                  )}
                </div>
                <div className="text-center">
                  <button
                    className="btn btn-primary"
                    value="Next"
                  >
                    Continue
                  </button>
                </div>
              </form>
            </div>
          </div>
          {/* for row div */}

        </div>
        <PassiveModeFooter />
      </div>
    );
  }
}


PassiveModeInfoWithOTP.propTypes = {
  onGetVerificationCode: PropTypes.func,
  onVerifyCode: PropTypes.func,
  isLoading: PropTypes.bool,
  redirectUri: PropTypes.string,
  contactNumber: PropTypes.string,
};

const mapStateToProps = (state, props) => ({
  userInfo: state.passiveMode.metadata.userInfo,
  isLoading: state.passiveMode.isLoading,
  linkKey: state.passiveMode.metadata.linkKey,
  redirectUri: queryString.parse(props.location.search).redirect_uri,
  contactNumber: queryString.parse(props.location.search).number,
});

const mapDispatchToProps = (dispatch) => ({
  onGetVerificationCode: (linkKey) => dispatch(getVerificationCode(linkKey)),
  onVerifyCode: (linkKey, code, hasAcceptedTnC, redirectUri) => dispatch(verifyCode(linkKey, code, hasAcceptedTnC, redirectUri)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PassiveModeInfoWithOTP);
