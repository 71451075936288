export const HCP_OUTCOMES_CHART_REQUEST_SENT = 'src/containers/HCPOUTCOMES/HCP_OUTCOMES_CHART_REQUEST_SENT';
export const HCP_OUTCOMES_CHART_REQUEST_SUCCEED = 'src/containers/HCPOUTCOMES/HCP_OUTCOMES_CHART_REQUEST_SUCCEED';
export const HCP_OUTCOMES_CHART_REQUEST_FAILED = 'src/containers/HCPOUTCOMES/HCP_OUTCOMES_CHART_REQUEST_FAILED';

export const GET_HCP_OUTCOMES_FILTERS_REQUEST_SENT = 'src/containers/HCPOUTCOMES/GET_HCP_OUTCOMES_FILTERS_REQUEST_SENT';
export const GET_HCP_OUTCOMES_FILTERS_REQUEST_SUCCEED = 'src/containers/HCPOUTCOMES/GET_HCP_OUTCOMES_FILTERS_REQUEST_SUCCEED';
export const GET_HCP_OUTCOMES_FILTERS_REQUEST_FAILED = 'src/containers/HCPOUTCOMES/GET_HCP_OUTCOMES_FILTERS_REQUEST_FAILED';

export const GET_HCP_OUTCOMES_LOCATIONS_FILTER_REQUEST_SENT = 'src/containers/HCPOUTCOMES/GET_HCP_OUTCOMES_LOCATIONS_FILTER_REQUEST_SENT';
export const GET_HCP_OUTCOMES_LOCATIONS_FILTER_REQUEST_SUCCEED = 'src/containers/HCPOUTCOMES/GET_HCP_OUTCOMES_LOCATIONS_FILTER_REQUEST_SUCCEED';
export const GET_HCP_OUTCOMES_LOCATIONS_FILTER_REQUEST_FAILED = 'src/containers/HCPOUTCOMES/GET_HCP_OUTCOMES_LOCATIONS_FILTER_REQUEST_FAILED';

export const SEND_HCP_OUTCOMES_FILTERS_DATA_REQUEST_SENT = 'src/containers/HCPOUTCOMES/SEND_HCP_OUTCOMES_FILTERS_DATA_REQUEST_SENT';
export const SEND_HCP_OUTCOMES_FILTERS_DATA_REQUEST_SUCCEED = 'src/containers/HCPOUTCOMES/SEND_HCP_OUTCOMES_FILTERS_DATA_REQUEST_SUCCEED';
export const SEND_HCP_OUTCOMES_FILTERS_DATA_REQUEST_FAILED = 'src/containers/HCPOUTCOMES/SEND_HCP_OUTCOMES_FILTERS_DATA_REQUEST_FAILED';
