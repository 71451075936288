import DevicePart from "./Views/DevicePart";
import DeviceType from "./Views/DeviceType";
import LostAndDamagedParts from "./Views/LostAndDamagedParts";
import ShippingDetails from "./Views/ShippingDetails/ShippingDetails";
import OrderReview from "./Views/OrderReview";
import SubmitOrder from "./Views/SubmitOrder";
import DeviceNumberDOB from './Views/DeviceNumberDOB'
import usePatsOrderData from "./Model/usePatsOrderData";
import { changeSearchParams } from "utils/changeSearchParams";
import { STATE } from 'enums';
import { useEffect } from "react";

export const useOrderScreens = () => {
  const { dobData,
    serialNumberData,
    providerNameData,
    deviceTypeData,
    devicePartOptions,
    devicePartData,
    damageNoticedDateData,
    reasonsOptions,
    reasonForReplacementData,
    reasonCommentData,
    addressData,
    editShippingDetailsData,
    isLoading,
    validateData,
    resetData,
    submitOrderData,
    statesOptions,
    } = usePatsOrderData();

  const jumpBetweenPages = (jumpTo, jumpLogic) => {
    jumpLogic(jumpTo);
  }

  return {
    orderScreens: [
      {
        id: 1,
        showProgress: true,
        showHeader: true,
        component: DeviceNumberDOB,
        data: {
          title: "Please confirm your date of birth and implanted device serial number.",
          dobData,
          serialNumberData,
          validateData
        }
      },
      {
        id: 2,
        showProgress: true,
        showHeader: true,
        component: DeviceType,
        data: {
          title: "Confirm your device type and provider.",
          providerNameData,
          deviceTypeData,
          validateData
        },

      },
      {
        id: 3,
        showProgress: true,
        showHeader: true,
        component: DevicePart,
        data: {
          title: "Which part do you need to order?",
          devicePartOptions,
          devicePartData,
          validateData
        }
      },
      {
        id: 4,
        showProgress: true,
        showHeader: true,
        component: LostAndDamagedParts,
        data: {
          title: "Provide details around the damaged or lost/stolen part.",
          reasonsOptions,
          reasonForReplacementData,
          damageNoticedDateData,
          reasonCommentData,
          validateData
        }
      },
      {
        id: 5,
        showProgress: true,
        showHeader: true,
        component: ShippingDetails,
        data: {
          title: "Is this the correct shipping address?",
          addressData,
          validateData,
          editShippingDetailsData,
          statesOptions
        },
        onBack: () => {
          editShippingDetailsData.setEditShippingDetails(false)
        }
      },
      {
        id: 6,
        showHeader: true,
        component: OrderReview,
        data: {
          title: "Order Review",
          deviceTypeData,
          devicePartOptions,
          devicePart: devicePartOptions.find((part) => part.value === devicePartData.devicePart),
          addressData,
          editShippingDetailsData,
          onEditShippingDetails: (jumpLogic) => jumpBetweenPages(5, jumpLogic),
          onEditDevicePartDetails: (jumpLogic) => jumpBetweenPages(3, jumpLogic),
          submitOrderData
        }
      },
      {
        id: 7,
        showHeader: false,
        component: SubmitOrder,
        data: {
          title: "Submit Order",
          resetData
        }
      },
    ],
    isLoading,
  }
}
