import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { MobileDatePicker as MUIDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { IconButton } from '@mui/material';
import AccessibleIcon from "@mui/icons-material/Accessible";
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import { makeStyles } from '@mui/styles';
import calendarIcon from 'assets/iconImages/calendar_today.svg'
import './styles.scss';

const MobileDatePicker = (props) => {

  const useStyles = makeStyles({
    root: {
      "& .MuiOutlinedInput-input": {
        fontSize: "14px !important",
        color: "black !important",
        '&::placeholder': {
          textOverflow: 'ellipsis !important',
          color: "#999",
          fontSize: "14px",
          fontWeight: 400,
          lineHeight: "24px",
          borderRadius: "6px"
        }
      },
      "& .Mui-error": {
        fontSize: "1.2rem !important",
        color: "#F4787D !important",
        "& .MuiOutlinedInput-notchedOutline": {
          border: "1px solid #F4787D !important",
          borderRadius: "6px"
        },
      },
      "& .MuiOutlinedInput-notchedOutline": {
        border: "1px solid #999 !important",
        borderRadius: "6px",
        "& .Mui-error": {
          border: "1px solid #F4787D !important",
        }
      },
    }
  });

  const classes = useStyles();

  const theme = createTheme({
    typography: {
      htmlFontSize: 10
    }
  });

  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <div className="mobile-date-picker-wrapper">
          <div className={`input-label ${props.required ? 'required' : ''}`}>{props.overHeadLabel}</div>
          <MUIDatePicker
            onOpen={() => { if (props.onOpen) props.onOpen() }}
            inputFormat={props.format || "MM/dd/yyyy"}
            label={props.floatingLabel}
            disableFuture={props.disableFuture}
            renderInput={(params) => <TextField
              {...params}
              className={classes.root}
              error={props.error ? true : false}
              helperText={props.error}
              placeholder={props.placeholder || "Select Date"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton edge="end">
                      <img src={calendarIcon} />
                      {/* <EventIcon sx={{ color: "#999" }} /> */}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              sx={{ ...props.sx }}
            />}
            value={props.value}
            onChange={(newValue) => props.onChange(newValue)}
          />
        </div>
      </LocalizationProvider>
    </ThemeProvider>
  )
}

export default MobileDatePicker;
