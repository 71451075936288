import React from "react";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import "./component-style.scss";

export default function Header(props) {
  const { name, procedureDate, onBackClick, isTrialProcedure } = props;
  let subHeading = isTrialProcedure ? "Trial Summary QuickView" : "Post-Implant Summary QuickView";

  return (
    <div className="summary-page__heading-wrapper">
      <div className="summary-page__user-info">
        <ArrowBackIosIcon className="summary-page__user-info__back-icon" sx={{ fontSize: "16px" }} onClick={onBackClick}/>
        <div className="summary-page__user-info__heading">
          <p className="summary-page__user-info__heading__user-name">{name}</p>
          <p className="summary-page__user-info__heading__procedure">
            {subHeading}
          </p>
        </div>
      </div>
      <p className="summary-page__procedure-name no-margin">{subHeading}</p>
      <p className="summary-page__procedure-date">
        {(isTrialProcedure ? `Trial Period: ` : `Implant Date: `) +
          procedureDate}
      </p>
    </div>
  );
}
