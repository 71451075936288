import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import queryString from 'query-string';
import { getUserInfo } from 'common/authThunkActions';
import { redirectToMainPage } from 'common/authGuard';
import LightboxModal from 'components/LightboxModal';
import AddProcedure from 'patientApp/containers/AddProcedure';
import InviteCode from 'patientApp/containers/InviteCode';
import { clearAddProcedureStates } from 'common/actions';
import { clearInviteCodeStates } from '../../common/actions';

import Strings from '../../../strings';

import './style.scss';

class CompletedProcedure extends Component {
  componentDidMount() {
    if (!this.props.userDetails.info) {
      this.props.history.replace('/select-procedure');
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.isInviteCodeValid && !this.props.isInviteCodeValid) {
      this.props.history.push('/post-complete-procedure?inviteCode=1');
    }
    if (nextProps.isProceduredAdded && !this.props.isProceduredAdded) {
      this.props.fetchUserInfo();
    }
    if (nextProps.userDetails.info && !nextProps.userDetails.info.isProcedureCompleted) {
      redirectToMainPage();
    }
  }

  componentWillUnmount() {
    this.props.clearInviteCodeState();
    this.props.clearAddProcedureState();
  }

  render() {
    if (this.props.isRequestInProgress) {
      return <LightboxModal show={this.props.isRequestInProgress} />;
    }
    if (this.props.isProfileRequestFailed) {
      // TODO show retry
      return null;
    }
    return (
      this.props.isInviteCodeValid && this.props.location.search && queryString.parse(this.props.location.search).inviteCode ? (
        <div className="col-sm-offset-2 col-sm-8 col-md-offset-3 col-md-6 col-xs-12 completed-procedure-container no-padding">
          <AddProcedure procedures={this.props.inviteCodeProcedures} />
        </div>
      ) :
        (
          <div className="col-sm-offset-3 col-sm-6 col-xs-12">
            <div className="completed-procedure-welcome-header">Welcome Back</div>
            <div className="completed-procedure-invite-code-container">
              <div className="text">
                <b>
                  Hello
                  {this.props.userDetails.info ? this.props.userDetails.info.firstName : ''}
                </b>
                ,
                {Strings.MSG_PROCEDURE_COMPLETED}
              </div>
              <div className="invite-code-block">
                <InviteCode isForAddProcedure header={Strings.INVITE_CODE_CREATE_PROCEDURE} />
              </div>
            </div>
          </div>
        )
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  clearInviteCodeState: () => dispatch(clearInviteCodeStates()),
  clearAddProcedureState: () => dispatch(clearAddProcedureStates()),
  fetchUserInfo: () => dispatch(getUserInfo()),
});

const mapStateToProps = (state) => ({
  userDetails: state.profile.userDetails,
  isRequestInProgress: state.profile.isRequestInProgress,
  isDeleteRequestInProgress: state.patientProcedures.isDeleteProcedureRequestInProgress,
  compositeProcedures: state.patientProcedures.compositeProcedures,
  inviteCodeProcedures: state.patientProcedures.inviteCodeProcedures,
  isInviteCodeValid: state.patientProcedures.isInviteCodeValid,
  isProceduredAdded: state.addProcedureRequest.isAdded,
  isProfileRequestFailed: state.profile.isRequestFailed,
});


CompletedProcedure.propTypes = {
  userDetails: PropTypes.object,
  location: PropTypes.object,
  inviteCodeProcedures: PropTypes.array,
  isRequestInProgress: PropTypes.bool,
  isInviteCodeValid: PropTypes.bool,
  history: PropTypes.func,
  clearInviteCodeState: PropTypes.func,
  isProceduredAdded: PropTypes.bool,
  clearAddProcedureState: PropTypes.func,
  fetchUserInfo: PropTypes.func,
  isProfileRequestFailed: PropTypes.bool,
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withRouter, withConnect)(CompletedProcedure);
