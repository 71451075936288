import * as Constants from './constants';
import { SEARCHABLE_QUESTION_QUERY_LENGTH } from '../../../constants';

export default (state = { procedures: [] }, action) => {
  switch (action.type) {
    case Constants.SET_PROCEDURES_DETAILS:
      return {
        ...state,
        procedures: action.data,
      };
    case Constants.RESET_PROCEDURES_DETAILS:
      return {
        ...state,
        procedures: [],
      };
    default:
      return state;
  }
};

const searchQuestionInitialState = {
  isLoading: false,
  questions: [],
  total: 0,
  lastPage: false,
  firstPage: true,
  totalPages: 0,
  currentCursor: '',
  nextPageCursor: '',
  showSuggestions: false,
  showNoResults: false,
};

export const questionSearchReducer = (state = searchQuestionInitialState, action) => {
  let result = null;
  let questions = [];
  switch (action.type) {
    case Constants.SEARCH_QUESTION_REQUEST_SENT:
      return {
        ...state,
        isLoading: true,
        lastPage: false,
        questions: action.isScrolled ? state.questions : [],
        // showSuggestions: true,
        showNoResults: false,
      };
    case Constants.SEARCH_QUESTION_REQUEST_SUCCEED:
      result = action.payload.data;
      questions = action.payload.isScrolled ? state.questions.concat(result.questions) : result.questions || [];
      return {
        ...state,
        isLoading: false,
        questions,
        total: result.total,
        lastPage: result.lastPage,
        firstPage: result.firstPage,
        totalPages: result.totalPages,
        currentCursor: result.currentCursor,
        nextPageCursor: result.totalPages === 1 ? '*' : result.nextPageCursor,
        showSuggestions: state.questions.length >= SEARCHABLE_QUESTION_QUERY_LENGTH || action.payload.data.questions.length > 0,
        showNoResults: questions.length === 0,
      };
    case Constants.SEARCH_QUESTION_REQUEST_FAILED:
      return {
        ...state,
        isLoading: false,
        showSuggestions: false,
        showNoResults: false,
      };
    case Constants.HIDE_SUGGESTIONS_DROPDOWN:
      return {
        ...state,
        showSuggestions: false,
      };
    case Constants.SEARCH_QUESTION_CLEAR_RESULT:
      return searchQuestionInitialState;
    default:
      return state;
  }
};
