import React, { useState, useEffect } from "react";
import BarChart from "./barChart";
import "./component-style.scss";

const unInitializedValues = ["-1", null, undefined, -1];

function PainReductionChart(props) {
  const { data, labels, getChartData, getChartColors } = props;

  const options = {
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          font: {
            size: 8,
          },
          autoSkip: false,
        },
        categorySpacing: 0.1,
      },
      y: {
        beginAtZero: true,
        min: 0,
        max: 100,
        ticks: {
          stepSize: 20,
          callback: (value) => {
            return value + "%";
          },
          padding: 1,
          autoSkip: false,
          font: {
            size: 10,
          },
        },
      },
    },
  };

  const chartData = {
    labels: labels ?? [],
    datasets: [
      {
        label: "",
        data:
          getChartData(data?.pain_reduction?.pain_reduction_percentages) ?? [],
        backgroundColor: getChartColors(
          data?.pain_reduction?.pain_reduction_percentages
        ),
        datalabels: {
          display: true,
        },
      },
    ],
  };

  return (
    <>
      <div className="summary-page-divider" />
      <BarChart options={options} data={chartData} chartTitle={"Best Reported Pain Reduction"}/>
      
      {data?.pain_reduction?.highest_pain_reduction_percentage >= 0 && (
        <div className="highest-pain-reduction">
          <p className="title-font-regular">Highest Pain Reduction</p>
          <p className="highlighted-text-font" style={{ color: data.pain_reduction.highest_pain_reduction_color ?? "#323232",}}>
            {unInitializedValues.includes(data.pain_reduction.highest_pain_reduction_percentage)
              ? "N/A"
              : data.pain_reduction.highest_pain_reduction_percentage + "%"}
          </p>
        </div>
      )}
    </>
  );
}

export default PainReductionChart;
