/*
 *
 * PatientDetails constants
 *
 */

export const GET_PATIENT_DETAILS_REQUEST_SENT = 'src/PatientDetails/GET_PATIENT_DETAILS_REQUEST_SENT';
export const GET_PATIENT_DETAILS_REQUEST_SUCCEED = 'src/PatientDetails/GET_PATIENT_DETAILS_REQUEST_SUCCEED';
export const GET_PATIENT_DETAILS_REQUEST_FAILED = 'src/PatientDetails/GET_PATIENT_DETAILS_REQUEST_FAILED';

export const REMOVE_PATIENT_REQUEST_SENT = 'src/PatientDetails/REMOVE_PATIENT_REQUEST_SENT';
export const REMOVE_PATIENT_REQUEST_SUCCEED = 'src/PatientDetails/REMOVE_PATIENT_REQUEST_SUCCEED';
export const REMOVE_PATIENT_REQUEST_FAILED = 'src/PatientDetails/REMOVE_PATIENT_REQUEST_FAILED';

export const UPDATE_NEEDS_ATTENTION_REQUEST_SENT = 'src/PatientDetails/UPDATE_NEEDS_ATTENTION_REQUEST_SENT';
export const UPDATE_NEEDS_ATTENTION_REQUEST_SUCCEED = 'src/PatientDetails/UPDATE_NEEDS_ATTENTION_REQUEST_SUCCEED';
export const UPDATE_NEEDS_ATTENTION_REQUEST_FAILED = 'src/PatientDetails/UPDATE_NEEDS_ATTENTION_REQUEST_FAILED';
export const RESET_NEEDS_ATTENTION = 'src/PatientDetails/RESET_NEEDS_ATTENTION';

export const GET_NOT_MOVING_FORWARD_REASONS_REQUEST_SENT = 'src/PatientDetails/GET_NOT_MOVING_FORWARD_REASONS_REQUEST_SENT';
export const GET_NOT_MOVING_FORWARD_REASONS_REQUEST_SUCCEED = 'src/PatientDetails/GET_NOT_MOVING_FORWARD_REASONS_REQUEST_SUCCEED';
export const GET_NOT_MOVING_FORWARD_REASONS_REQUEST_FAILED = 'src/PatientDetails/GET_NOT_MOVING_FORWARD_REASONS_REQUEST_FAILED';

export const UPDATE_NOT_MOVING_FORWARD_REQUEST_SENT = 'src/PatientDetails/UPDATE_NOT_MOVING_FORWARD_REQUEST_SENT';
export const UPDATE_NOT_MOVING_FORWARD_REQUEST_SUCCEED = 'src/PatientDetails/UPDATE_NOT_MOVING_FORWARD_REQUEST_SUCCEED';
export const UPDATE_NOT_MOVING_FORWARD_REQUEST_FAILED = 'src/PatientDetails/UPDATE_NOT_MOVING_FORWARD_REQUEST_FAILED';

export const RESEND_INVITE_REQUEST_SENT = 'src/PatientDetails/RESEND_INVITE_REQUEST_SENT';
export const RESEND_INVITE_REQUEST_SUCCEED = 'src/PatientDetails/RESEND_INVITE_REQUEST_SUCCEED';
export const RESEND_INVITE_REQUEST_FAILED = 'src/PatientDetails/RESEND_INVITE_REQUEST_FAILED';

export const SET_PHASE_ID = 'src/PatientDetails/SET_PHASE_ID';

export const UPDATE_PATIENT_INFO_REQUEST_SENT = 'src/PatientDetails/UPDATE_PATIENT_INFO_REQUEST_SENT';
export const UPDATE_PATIENT_INFO_REQUEST_SUCCEED = 'src/PatientDetails/UPDATE_PATIENT_INFO_REQUEST_SUCCEED';
export const UPDATE_PATIENT_INFO_REQUEST_FAILED = 'src/PatientDetails/UPDATE_PATIENT_INFO_REQUEST_FAILED';
export const RESET_PATIENT_DETAILS = 'src/PatientDetails/RESET_PATIENT_DETAILS';


export const TERMINATE_PROCEDURE_TRIAL_REQUEST_SENT = 'src/PatientDetails/TERMINATE_PROCEDURE_TRIAL_REQUEST_SENT';
export const TERMINATE_PROCEDURE_TRIAL_REQUEST_SUCCEED = 'src/PatientDetails/TERMINATE_PROCEDURE_TRIAL_REQUEST_SUCCEED';
export const TERMINATE_PROCEDURE_TRIAL_REQUEST_FAILED = 'src/PatientDetails/TERMINATE_PROCEDURE_TRIAL_REQUEST_FAILED';


export const CREATE_IN_NLINK_REQUEST_SENT = 'src/PatientDetails/CREATE_IN_NLINK_REQUEST_SENT';
export const CREATE_IN_NLINK_REQUEST_SUCCEED = 'src/PatientDetails/CREATE_IN_NLINK_REQUEST_SUCCEED';
export const CREATE_IN_NLINK_REQUEST_FAILED = 'src/PatientDetails/CREATE_IN_NLINK_REQUEST_FAILED';

export const TRACK_PATIENT_AS_VIEWED_REQUEST_SENT = 'src/PatientDetails/TRACK_PATIENT_AS_VIEWED_REQUEST_SENT';
export const TRACK_PATIENT_AS_VIEWED_REQUEST_SUCCEED = 'src/PatientDetails/TRACK_PATIENT_AS_VIEWED_REQUEST_SUCCEED';
export const TRACK_PATIENT_AS_VIEWED_REQUEST_FAILED = 'src/PatientDetails/TRACK_PATIENT_AS_VIEWED_REQUEST_FAILED';
