import { useEffect } from 'react';
import './styles.scss';
import {useToastStore} from "../../../hooks/mobileHooks/ToastStore/useToastStore";
import Toast from './Toast';
import {ToastType} from "../../../hooks/mobileHooks/ToastStore/useToastStore";
type ToastersProps ={

}

const Toasters: React.FC<ToastersProps> = (props: ToastersProps) => {
  const toasters: ToastType[] = useToastStore((state) => state.toasters);
  const removeToaster = useToastStore((state) => state.removeToaster);

    useEffect(() => {
        toasters.forEach((toast: ToastType) =>{
            setTimeout(()=>{
                    removeToaster(toast.id)
        }, toast.duration || 5000);
    }, [toasters, removeToaster])});


  return(
      <div>
      {
        toasters.map((toast:ToastType) => (
            <div key={toast.id}>
                <Toast type={toast.type} message={toast.message} />
            </div>
        ))
      }
      </div>
  )
}

export default  Toasters;
