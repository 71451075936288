import { useState, useEffect, useRef } from "react"
import { useLocation } from "react-router-dom";
import { validationRules } from '../validationConfig'
import validate from "common/validator";
import { getOrderSummaryAndData, getDeviceParts, placeNewDeviceOrder } from "../service";
import { changeSearchParams } from "utils/changeSearchParams";
import { STATE } from "enums";
import { dateStringFormatForServer } from "utils/dateUtils";
import { useToastStore } from "../../../hooks/mobileHooks/ToastStore/useToastStore";

const addressDataTemplate = {
  firstName: null,
  lastName: null,
  addressLine1: null,
  addressLine2: null,
  city: null,
  state: null,
  zipCode: null,
  country: 'United States',
  phoneNumber: null
}

const usePatsOrderData = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({})
  const [reasonsOptions, setReasonsOptions] = useState([])
  const [devicePartOptions, setDevicePartOptions] = useState([])
  const [patientDOB, setPatientDOB] = useState(null);
  const [deviceSerialNumber, setDeviceSerialNumber] = useState(null);
  const [deviceType, setDeviceType] = useState("Intellis");
  const [providerFirstName, setProviderFirstName] = useState(null);
  const [providerLastName, setProviderLastName] = useState(null);
  const [devicePart, setDevicePart] = useState(null);
  const [damageNoticedDate, setDamageNoticedDate] = useState(null);
  const [reasonForReplacement, setReasonForReplacement] = useState(null);
  const [reasonComment, setReasonComment] = useState(null);
  const [address, setAddress] = useState(addressDataTemplate)
  const [editShippingDetails, setEditShippingDetails] = useState(false);
  const [statesOptions, setStatesOptions] = useState([]);

  const location = useLocation();
  const addToaster = useToastStore(state => state.addToaster);



  const searchParams = new URLSearchParams(location.search);
  const toggleEditShippingDetails = () => {
    setEditShippingDetails(!editShippingDetails);
  };

  const createValidationConfig = (data) => {
    let rules = {}
    Object.keys(data).forEach((field) => {
      rules[field] = validationRules[field]
    })
    return {
      fields: Object.keys(data),
      rules
    }
  }

  useEffect(() => {
    if (!searchParams.get("state")) {
      setIsLoading(true)
      getOrderSummaryAndData()
        .then((response) => {
          setDeviceType(response.data.device_type)
          setPatientDOB(response.data.dob ? new Date(response.data.dob) : null)
          setProviderFirstName(response.data.primary_physician.first_name)
          setProviderLastName(response.data.primary_physician.last_name)
          setReasonsOptions(response.data.replacement_reasons)
          setAddress({
            ...address,
            firstName: response.data.shipping_address.first_name,
            lastName: response.data.shipping_address.last_name,
            addressLine1: response.data.shipping_address.address_line1,
            addressLine2: response.data.shipping_address.address_line2,
            city: response.data.shipping_address.city,
            state: response.data.shipping_address.state,
            zipCode: response.data.shipping_address.zip_code,
            phoneNumber: response.data.shipping_address.phone_number
          })
          setStatesOptions(response.data.states)
          setIsLoading(false)

        })
        .catch((e) => {
          console.log(JSON.stringify(e));
          setIsLoading(false)
          changeSearchParams(STATE.ERROR, e.message || null);
        })
      setIsLoading(true)
      getDeviceParts({ device_type: deviceType })
        .then((response) => {
          setDevicePartOptions(response.data)
          setIsLoading(false)
        })
        .catch((e) => {
          console.log(JSON.stringify(e));
          setIsLoading(false)
          changeSearchParams(STATE.ERROR, e.message || null);
        })
    }

  }, []);


  const submitOrderData = (callBack = null) => {
    setIsLoading(true)
    let data = {
      device_type: deviceType,
      patient_dob: dateStringFormatForServer(patientDOB),
      device_serial_number: deviceSerialNumber,
      provider_first_name: providerFirstName.trim(),
      provider_last_name: providerLastName.trim(),
      device_part_id: devicePart,
      damage_noticed_date: dateStringFormatForServer(damageNoticedDate),
      reason: reasonForReplacement,
      comment: reasonComment?.trim(),
      shipping_first_name: address.firstName.trim(),
      shipping_last_name: address.lastName.trim(),
      shipping_address_line1: address.addressLine1.trim(),
      shipping_address_line2: address.addressLine2?.trim(),
      shipping_city: address.city.trim(),
      shipping_state: address.state,
      shipping_zip_code: address.zipCode.trim(),
      shipping_country: address.country,
      shipping_phone_number: address.phoneNumber ? address.phoneNumber.replace('+1', '').replace(/ /g, '').replace(/-/g, '') : address.phoneNumber
    }
    placeNewDeviceOrder(data)
      .then(() => {
        setIsLoading(false)
        if (callBack)
          callBack();
      })
      .catch((e) => {
        addToaster({type: "error", message: e.response.data.error});
        console.log(JSON.stringify(e))
        setIsLoading(false)
      })
  }


  const validateData = (data, onSuccess, onError = null) => {
    validate(createValidationConfig(data), data, (errors) => {
      setErrors(errors)
      if (onError)
        onError();
    }, () => {
      setErrors({}); onSuccess()
    });
  }

  const modifySetValueFunction = (field, modifyFunction) => {
    return (value) => {
      setErrors({ ...errors, [field]: null })
      modifyFunction(value)
    }
  }

  const resetData = () => {
    setErrors({})
    setPatientDOB(null)
    setDeviceSerialNumber(null)
    setDeviceType(null)
    setProviderFirstName(null)
    setProviderLastName(null)
    setDevicePart(null)
    setDamageNoticedDate(null)
    setReasonForReplacement(null)
    setReasonComment(null)
    setAddress(addressDataTemplate)
  }

  const generateAddressReturnObject = () => {
    let addressData = {};
    Object.keys(address).forEach((add) => {
      addressData[add] = {
        value: address[add],
        setValue: (value) => {
          setAddress({ ...address, [add]: value })
          setErrors({ ...errors, [add]: null })
        },
        error: errors[add],
        ref: useRef()
      }
    })
    return addressData;
  }


  return {
    dobData: { patientDOB, setPatientDOB: modifySetValueFunction('patientDOB', setPatientDOB), error: errors["patientDOB"] },
    serialNumberData: { deviceSerialNumber, setDeviceSerialNumber: modifySetValueFunction('deviceSerialNumber', setDeviceSerialNumber), error: errors['deviceSerialNumber'] },
    providerNameData: {
      firstName: { providerFirstName, setProviderFirstName: modifySetValueFunction('providerFirstName', setProviderFirstName), error: errors['providerFirstName'] },
      lastName: { providerLastName, setProviderLastName: modifySetValueFunction('providerLastName', setProviderLastName), error: errors['providerLastName'] }
    },
    deviceTypeData: { deviceType, setDeviceType },
    devicePartData: { devicePart, setDevicePart: modifySetValueFunction('devicePart', setDevicePart), error: errors['devicePart'] },
    damageNoticedDateData: { damageNoticedDate, setDamageNoticedDate: modifySetValueFunction('damageNoticedDate', setDamageNoticedDate), error: errors['damageNoticedDate'] },
    reasonForReplacementData: { reasonForReplacement, setReasonForReplacement: modifySetValueFunction('reasonForReplacement', setReasonForReplacement), error: errors['reasonForReplacement'] },
    reasonCommentData: { reasonComment, setReasonComment: modifySetValueFunction('reasonComment', setReasonComment), error: errors['reasonComment'] },
    addressData: generateAddressReturnObject(),
    editShippingDetailsData: { editShippingDetails, setEditShippingDetails, toggleEditShippingDetails },
    devicePartOptions,
    reasonsOptions,
    validateData,
    resetData,
    submitOrderData,
    isLoading,
    statesOptions,
  }
}

export default usePatsOrderData;
