import React, { useState, useEffect } from "react";
import PhysicalIndicators from "./PhysicalIndicators";
import PainReductionChart from "./PainReductionChart";
import StimulatorFrequencyChart from "./StimulatorFrequencyChart";

function PermSummaryQuickView(props) {
  const { data, getChartColors, getChartData } = props;

  const getXLabelsOfChart = () => {
    const pain_reduction_percentages = data?.pain_reduction?.pain_reduction_percentages;
    const labels = pain_reduction_percentages?.map((el) => {
      if ("x_label" in el) {
        return el.x_label;
      }
      return "";
    });
    return labels;
  };

  return (
    <>
      <PainReductionChart data={data} labels={getXLabelsOfChart()} getChartData={getChartData} getChartColors={getChartColors} />
      <StimulatorFrequencyChart data={data} labels={getXLabelsOfChart()} getChartData={getChartData} getChartColors={getChartColors} />
      <PhysicalIndicators physicalIndications={data.other_params} />
    </>
  );
}

export default PermSummaryQuickView;
