import { registerNewUser, generateOtp } from 'services/patient/authentication';
import {
  GENERATE_OTP_REQUEST_SENT,
  GENERATE_OTP_REQUEST_SUCCEED,
  GENERATE_OTP_REQUEST_FAILED,
  CREATE_USER_REQUEST_SENT,
  CREATE_USER_REQUEST_SUCCEED,
  CREATE_USER_REQUEST_FAILED,
  NEXT_PAGE,
  PREV_PAGE,
  SUCCESS_VIEW,
} from './constants';
import { showToaster } from 'common/toasterActions';

export const generateOtpRequestSent = () => ({
  type: GENERATE_OTP_REQUEST_SENT,
});

export const generateOtpRequestSucceeded = () => ({
  type: GENERATE_OTP_REQUEST_SUCCEED,
});

export const generateOtpRequestFailed = () => ({
  type: GENERATE_OTP_REQUEST_FAILED,
});

export const createUserRequestSent = () => ({
  type: CREATE_USER_REQUEST_SENT,
});

export const createUserSucceeded = () => ({
  type: CREATE_USER_REQUEST_SUCCEED,
});

export const createUserRequestFailed = () => ({
  type: CREATE_USER_REQUEST_FAILED,
});

export const nextPage = (page) => ({
  type: NEXT_PAGE,
  page,
});

export const prevPage = (page) => ({
  type: PREV_PAGE,
  page,
});

export const generateOtpAction = (phone) => (dispatch) => {
  dispatch(generateOtpRequestSent());
  generateOtp(phone)
    .then((response) => {
      dispatch(generateOtpRequestSucceeded());
      dispatch(nextPage(SUCCESS_VIEW));
    })
    .catch((e) => {
      dispatch(generateOtpRequestFailed());
    });
};

export const createUserAction = (params) => ((dispatch) => {
  dispatch(createUserRequestSent());
  registerNewUser(params)
    .then((response) => {
      if (response.data.isExistingUser) {
        dispatch(showToaster({ message: 'User is already registered. Please try another mobile number', type: 'error' }));
        dispatch(createUserRequestFailed());
      } else {
        dispatch(createUserSucceeded());
        dispatch(nextPage(SUCCESS_VIEW));
      }
    })
    .catch(() => {
      dispatch(createUserRequestFailed());
    });
});

export const nextPageAction = (page) => ((dispatch) => {
  dispatch(nextPage(page));
});

export const prevPageAction = (page) => ((dispatch) => {
  dispatch(prevPage(page));
});
