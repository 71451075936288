import { useEffect } from "react";
import { ClientInterface } from "utils/NativeNavigation/ClientInterface";

const useBackHandler = (title: string, listener: ClientInterface) => {
  useEffect(() => {
    document.title = title;

    const popstateHandler = () => {
      listener.onCancelled();
    };

    window.history.pushState(null, document.title, window.location.href);
    window.addEventListener("popstate", popstateHandler);

    return () => {
      window.removeEventListener("popstate", popstateHandler);
    };
  }, []);
};

export default useBackHandler;
