export const locationAndDateFilterValidationConfig = {
  fields: ["locationFilter", "startDate", "endDate"],
  rules: {
    locationFilter: [{rule: "isRequired", message: "Account filter is required"}],
    startDate: [{rule: "isRequired", message: "Start date is required"}],
    endDate: [{rule: "isRequired", message: "End date is required"}],
  }
}

export const validationConfig =  {
  fields: ["first_name", "last_name", "locationFilter", "startDate", "endDate", "email", "contact_number"],
  rules: {
    first_name: [{ rule: "isRequired", message: "First name is required" }],
    last_name: [{ rule: "isRequired", message: "Last name is required" }],
    email: [{ rule: "isEmail", message: "Email is invalid" }],
    contact_number: [{ rule: "isPhone", message: "Contact number is invalid" }],
    locationFilter: [{rule: "isRequired", message: "Account filter is required"}],
    startDate: [{rule: "isRequired", message: "Start date is required"}],
    endDate: [{rule: "isRequired", message: "End date is required"}]
  },
};