import { fetchDeviceOrdersCall, fetchDeviceOrderDetailsCall, archiveDeviceOrderCall } from "services/patientordersupport/dashboardApiCalls"
import {
  FETCH_PATIENT_DEVICE_ORDERS_DETAILS_REQUEST_FAILED,
  FETCH_PATIENT_DEVICE_ORDERS_DETAILS_REQUEST_SENT,
  FETCH_PATIENT_DEVICE_ORDERS_DETAILS_REQUEST_SUCCEEDED,
  FETCH_PATIENT_DEVICE_ORDERS_REQUEST_FAILED,
  FETCH_PATIENT_DEVICE_ORDERS_REQUEST_SENT,
  FETCH_PATIENT_DEVICE_ORDERS_REQUEST_SUCCEEDED,
  ARCHIVE_PATIENT_DEVICE_ORDERS_REQUEST_FAILED,
  ARCHIVE_PATIENT_DEVICE_ORDERS_REQUEST_SENT,
  ARCHIVE_PATIENT_DEVICE_ORDERS_REQUEST_SUCCEEDED,
  RESET_ORDER_DETAILS_DATA,
  SET_ACTIVE_TAB_ON_SIDEBAR,
  SET_SIDE_BAR_OPEN_STATUS,
  UPDATE_ORDER_DATA_AFTER_SORTING,
  SET_SEARCH_STRING
} from "./constants"

const fetchDeviceOrdersRequestSent = {
  type: FETCH_PATIENT_DEVICE_ORDERS_REQUEST_SENT
}

const fetchDeviceOrdersRequestSucceeded = (data, pagination) => ({
  type: FETCH_PATIENT_DEVICE_ORDERS_REQUEST_SUCCEEDED,
  data,
  pagination
})

const fetchDeviceOrdersRequestFailed = {
  type: FETCH_PATIENT_DEVICE_ORDERS_REQUEST_FAILED
}

const fetchDeviceOrderDetailRequestSent = {
  type: FETCH_PATIENT_DEVICE_ORDERS_DETAILS_REQUEST_SENT
}

export const resetOrderDetailsData = {
  type: RESET_ORDER_DETAILS_DATA
}

export const setSideBarOpenStatus = (status) => ({
  type: SET_SIDE_BAR_OPEN_STATUS,
  barOpenStatus: status
})

const fetchDeviceOrderDetailRequestSucceeded = (data, pagination) => ({
  type: FETCH_PATIENT_DEVICE_ORDERS_DETAILS_REQUEST_SUCCEEDED,
  data,
  pagination
})

const fetchDeviceOrderDetailRequestFailed = {
  type: FETCH_PATIENT_DEVICE_ORDERS_DETAILS_REQUEST_FAILED
}


const archiveDeviceOrdersRequestSent = {
  type: ARCHIVE_PATIENT_DEVICE_ORDERS_REQUEST_SENT
}

const archiveDeviceOrdersRequestSucceeded = (id) => ({
  type: ARCHIVE_PATIENT_DEVICE_ORDERS_REQUEST_SUCCEEDED,
  id
})

const archiveDeviceOrdersRequestFailed = {
  type: ARCHIVE_PATIENT_DEVICE_ORDERS_REQUEST_FAILED
}

export const setActiveTabForSideBar = (activeTabId, activeTabKey) => ({
  type: SET_ACTIVE_TAB_ON_SIDEBAR,
  activeTabId,
  activeTabKey
})

export const updateOrderDataAferSorting = (data) => ({
  type: UPDATE_ORDER_DATA_AFTER_SORTING,
  data
})


export const setSearchString = (data) => ({
  type: SET_SEARCH_STRING,
  data
})
export const fetchPatientOrders = (page, activeTabKey, search_string = null) => (dispatch) => {
  dispatch(fetchDeviceOrdersRequestSent)
  fetchDeviceOrdersCall({ page, "orders_type": activeTabKey, search_string })
    .then((response) => {
      dispatch(fetchDeviceOrdersRequestSucceeded(response.data.data, response.data.pagination))
    })
    .catch(() => {
      dispatch(fetchDeviceOrdersRequestFailed);
    })
}

export const fetchPatientOrderDetail = (id, call_back = null) => (dispatch) => {
  dispatch(fetchDeviceOrderDetailRequestSent)
  fetchDeviceOrderDetailsCall({ id })
    .then((response) => {
      dispatch(fetchDeviceOrderDetailRequestSucceeded(response.data.orderDetails))
      if (call_back)
        call_back();
    })
    .catch(() => {
      dispatch(fetchDeviceOrderDetailRequestFailed);
    })
}


export const archivePatientOrder = (id, call_back = null) => (dispatch) => {
  dispatch(archiveDeviceOrdersRequestSent);
  archiveDeviceOrderCall(id)
    .then((response) => {
      dispatch(archiveDeviceOrdersRequestSucceeded(id));
      if (call_back)
        call_back()
    })
    .catch(() => {
      dispatch(archiveDeviceOrdersRequestFailed);
    })

}