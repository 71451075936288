const ValueContainer = props => {
  // Only interested in rendering one/first multiValue
  if (props.index > 0) return null;
  const { isPill } = props.selectProps;


  const { length } = props.getValue();
  return isPill ? `${props.selectProps.placeholder} (${length})` : props.selectProps.inputValue == "" ? `${length} selected` : "";
}

export default ValueContainer;
