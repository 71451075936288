import { PATIENT_QUICK_VIEW_SUMMARY, PATIENT_PAIN_SCORE, DEFAULT_URL } from "services/constants";
import { getAuthValues } from "utils/webContainerAuth";
import axios from "axios";

export const getPatientQuickViewSummary = (userProcedureId) => {
  const url = DEFAULT_URL + PATIENT_QUICK_VIEW_SUMMARY.replace(":user_procedure_id", userProcedureId);
  return axios.get(`${url}`, {
    headers: getAuthValues(),
  });
};


export const getPatientPainScore = (userProcedureId) => {
  const url = DEFAULT_URL + PATIENT_PAIN_SCORE.replace(":user_procedure_id", userProcedureId);

  return axios.get(`${url}`, {
    headers: getAuthValues(),
  });
};
