import React from 'react';
import PropTypes from 'prop-types';

import '../patientRegister.scss';
import FloatingLabelInput from 'components/FloatingLabelInput';
import NextButton from 'containers/PatientRegister/components/NextButton';
import BackButton from 'containers/PatientRegister/components/BackButton';
import HeroImage from 'assets/images/patient-register-hero.png';

export default function OtpView(props) {
  return (
    <div className="otp-view-container">
      <div className="image-container form-hero">
        <img src={HeroImage} alt="Patient Register Hero" />
      </div>
      <div className="row patient-register-section contents-container text-align-center">
        <div className="col-xs-10 col-xs-offset-1">
          <h2>
            Enter your code.
          </h2>
          <p>We’ve sent a verification code to {props.phone}.</p>
        </div>
        <div className="clearfix"></div>
        <div className="col-xs-12 col-md-6 col-md-offset-3 phone-container">
          <div
            className={`form-group ${props.errors.otp !== undefined ? 'has-error' : ''}`}
          >
            <FloatingLabelInput
              type="text"
              name="otp"
              className="form-control"
              value={props.otp}
              label="Verification Code"
              onChange={props.onChange}
            />
          </div>
          <div>
            <NextButton onNext={props.onNext} />
            <BackButton onBack={props.onBack} />
          </div>
        </div>
        <div className="clearfix"></div>
      </div>
    </div>
  );
}

OtpView.propTypes = {
  onChange: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
  errors: PropTypes.object,
  otp: PropTypes.string,
  phone: PropTypes.string,
};

OtpView.defaultProps = {
  errors: {},
  otp: '',
};
