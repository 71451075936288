import React, { useState } from "react";
import Modal from "react-bootstrap/lib/Modal";
import "./mobile-style.scss";
import ReportRecipientForm from "./ReportRecipientForm";

// If the response keys from server are changed or new keys are ADDED we need to change this Map also. 
// This map is created to avoid capitalization and sentence casing of keys for displaying the title .
const titleMap = {
  "account" : "Account",
  "start_date": "Start Date",
  "device_type": "Device Types",
  "physicians": "Physicians",
  "indications": "Indications",
  "end_date": "End Date",
  "therapy": "Therapy",
}

export default function ReviewSelectionPopup(props) {
  const { selectedFilterValues, onDownloadReportClicked, onClose } = props;
  const [shareClinicalReport, setShareClinicalReport] = useState(false);
  const disableDownload = process.env.REACT_APP_DISABLE_PREVIEW_TAB === "true";

  const getKeysFromData = () => {
    return Object.keys(selectedFilterValues);
  };

  const shareReportClicked = () => {
    setShareClinicalReport(true);
  };

  const closeShareReportForm = () => {
    setShareClinicalReport(false);
  };

  const getTitle = (fieldName) => {
    if(titleMap.hasOwnProperty(fieldName)) {
      return titleMap[fieldName];
    } else {
      console.error(`The key '${fieldName}' is missing in the titleMap. Please add/update it`)
    }
  }

  const getSelectedValuesForDisplaying = (item) => {
    const values = selectedFilterValues[item];

    if(Array.isArray(values) && values.length > 0 && values[0] !== "All") {
      return values.join(",");
    } else if(Array.isArray(values)) {
      return `All ${titleMap[item]} selected`
    } else {
      return values;
    }
  } 
  
  return shareClinicalReport ? (
    <ReportRecipientForm
      onClose={closeShareReportForm}
      selectedFilters={selectedFilterValues}
      closeReportRecipientForm={closeShareReportForm}
    />
  ) : (
    <Modal
      show={true}
      onHide={onClose}
      container={document.body}
      autoFocus
      aria-labelledby="contained-modal-title"
      backdrop="static"
      className="hcp-report-sharing-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title">
          Selection Review
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="review-selection-wrapper">
          <div className="selected-data">
            {getKeysFromData().map((item, index) => {
              return (
                <div className="selected-filter-overview-wrapper" key={index}>
                  <p className="review-selection-filter-title">
                    {getTitle(item)}
                  </p>
                  <p className="review-selection-filter-values">
                    {getSelectedValuesForDisplaying(item)}
                  </p>
                </div>
              );
            })}
          </div>
          <div className="share-actions">
            <button className="report-modal-button share-report-button" onClick={shareReportClicked}>
              Enter Recipient Details
            </button>
            {!disableDownload && (
              <button className="report-modal-button report-modal-hollow-button" onClick={onDownloadReportClicked}>
                Download Report
              </button>
            )}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
