import React from 'react';
import '../patientRegister.scss';
import PropTypes from 'prop-types';
import FloatingLabelInput from 'components/FloatingLabelInput';
import NextButton from 'containers/PatientRegister/components/NextButton';
import BackButton from 'containers/PatientRegister/components/BackButton';
import HeroImage from 'assets/images/patient-register-hero.png';
export default function NameView(props) {
  return (
    <div className="name-view-container">
      <div className="image-container form-hero">
        <img src={HeroImage} alt="Patient Register Hero" />
      </div>
      <div className="row patient-register-section contents-container text-align-center">
        <div className="col-xs-12 col-sm-10 col-sm-offset-1">
          <h2>Great!</h2>
          <p>First, what can we call you?</p>
        </div>
        <div className="clearfix"></div>
        <div className="col-xs-6 col-sm-5 col-sm-offset-1">
          <div className={`form-group ${props.errors.firstName !== undefined ? 'has-error' : ''}`}>
            <FloatingLabelInput
              type="text"
              name="firstName"
              className="form-control"
              value={props.firstName}
              label="First Name"
              onChange={props.onChange}
            />
          </div>
        </div>
        <div className="col-xs-6 col-sm-5">
          <div className={`form-group ${props.errors.lastName !== undefined ? 'has-error' : ''}`}>
            <FloatingLabelInput
              type="text"
              name="lastName"
              className="form-control"
              value={props.lastName}
              label="Last Name"
              onChange={props.onChange}
            />
          </div>
        </div>
        <div className="clearfix"></div>
        <NextButton onNext={props.onNext} />
        <BackButton onBack={props.onBack} />
      </div>
    </div>
  );
}

NameView.propTypes = {
  errors: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
};

NameView.defaultProps = {
  firstName: '',
  lastName: '',
};
