import defaultAxios from 'axios';
import { getAuthHeaders, getItemFromStorage } from 'services/storage';
import {
  GET_DOWNLOAD_DATA_REQUEST,
  POST_DOWNLOAD_DATA_REQUEST,
  GET_DOWNLOAD_DATA_FILE,
  SHOW_DOWNLOAD_DATA_REQUEST,
  RESEND_DOWNLOAD_DATA_REQUEST_PIN,
} from '../constants';
import axios from '../base';

export const getDownloadDataRequest = () => axios.get(GET_DOWNLOAD_DATA_REQUEST);

export const postDownloadDataRequest = () => axios.post(POST_DOWNLOAD_DATA_REQUEST);

export const getDownloadFileRequest = () => axios.get(GET_DOWNLOAD_DATA_FILE);

export const showDownloadDataRequest = (params) => defaultAxios.post(SHOW_DOWNLOAD_DATA_REQUEST, params);

export const resendDownloadDataRequestPin = (params) => defaultAxios.post(RESEND_DOWNLOAD_DATA_REQUEST_PIN, params);

export const downloadFile = () => {
  const headers = { 'Authorization': `Bearer ${getItemFromStorage('token')}` };
  return defaultAxios.get(GET_DOWNLOAD_DATA_FILE, { headers });
};
