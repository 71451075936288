// Page orders
export const WELCOME_VIEW = 1;
export const NAME_VIEW = 2;
export const CONTACT_VIEW = 3;
export const OTP_VIEW = 4;
export const SUCCESS_VIEW = 5;

export const GENERATE_OTP_REQUEST_SENT = 'src/PatientRegister/GENERATE_OTP_REQUEST_SENT';
export const GENERATE_OTP_REQUEST_SUCCEED = 'src/PatientRegister/GENERATE_OTP_REQUEST_SUCCEED';
export const GENERATE_OTP_REQUEST_FAILED = 'src/PatientRegister/GENERATE_OTP_REQUEST_FAILED';

export const CREATE_USER_REQUEST_SENT = 'src/PatientRegister/CREATE_USER_REQUEST_SENT';
export const CREATE_USER_REQUEST_SUCCEED = 'src/PatientRegister/CREATE_USER_REQUEST_SUCCEED';
export const CREATE_USER_REQUEST_FAILED = 'src/PatientRegister/CREATE_USER_REQUEST_FAILED';

export const NEXT_PAGE = 'src/PatientRegister/NEXT_PAGE';
export const PREV_PAGE = 'src/PatientRegister/PREV_PAGE';
