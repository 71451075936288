import React, { useEffect } from "react";
import ParcelDelivery from "../../../assets/images/parcel_delivery.png";
import PatsBodyWrapper from "../Components/PatsOrderBodyWrapper";
import { changeSearchParams } from "utils/changeSearchParams";
import { STATE } from "enums";

function SubmitOrder(props) {
  const { moveForward, resetData } = props;

  const popstateHandler = (e) => {
    changeSearchParams(STATE.DEFERRED)
  }

  useEffect(() => {
    window.addEventListener('popstate', popstateHandler);
    return () => {
      window.removeEventListener('popstate', popstateHandler);
    };
  }, [])

  const getBody = () => {
    return (
      <div className="submit-order-content">
        <p className="title-font center-align-text">Thank you!</p>
        <div className="delivery-image-wrapper center-align-text">
          <img src={ParcelDelivery} alt="parcel-delivery" />
        </div>

        <div className="order-placed-msg">
          <div className="title-font">
            Your order has been placed!
          </div>
          <div className="content-font-1 success-text">
            Once the order is completed, it will be shipped with standard 2
            business day delivery through FedEx (delivery times may vary due to
            any potential delays with FedEx). Should you have any further
            questions our staff in Patient Services is available at
            <strong> 1-800-510-6735</strong>, Monday through Friday, 8AM-5PM,
            Central Standard Time.
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="submit-order-wrapper">
      <PatsBodyWrapper
        body={getBody()}
        buttons={[
          <button
            className="pats-btn-primary submit-order-button"
            onClick={() => { resetData(); changeSearchParams(STATE.DEFERRED) }}
          >
            Back to Home
          </button>
        ]
        }
      />
    </div >
  );
}

export default SubmitOrder;
