import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import shortLogo from 'assets/images/cgp_logo_short.png';
import bigLogo from 'assets/images/cgp_logo_big.png'
import SearchBar from '../SearchBar';
import LogoutIcon from '@mui/icons-material/Logout';
import useHeaderViewModel from './viewModel';
import './header.scss';


const Header = (props) => {
  const history = useHistory();


  const barOpenStatus = useSelector((store) => store.sideBarReducer.barOpenStatus)
  const [showShortIcon, setShowShortIcon] = useState(barOpenStatus);

  const { onSearchSubmit } = useHeaderViewModel();

  const onLogout = () => {
    history.push('/logout')
  }

  useEffect(() => {
    if (barOpenStatus) {
      setTimeout(() => {
        setShowShortIcon(barOpenStatus)
      }, 300)
    }
    else {
      setShowShortIcon(barOpenStatus)
    }
  }, [barOpenStatus])

  return (
    <div className="header-section">
      <div className={`logo-section ${barOpenStatus ? 'header-close' : 'header-open'}`}>
        {
          showShortIcon ? <img className="logo" style={{ width: "fit-content", height: "38px", objectFit: "contain" }} src={shortLogo}></img> :
            <img className="logo" style={{ width: "100%", height: "38px", objectFit: "cover", objectPosition: "left center" }} src={bigLogo}></img>
        }
      </div>
      <div className="search-bar-section">
        <SearchBar placeholder="Search by name or part" onSubmit={onSearchSubmit} />
      </div>

      <div className="logout-section" onClick={() => onLogout()}>
        <span className="logout-icon"><LogoutIcon sx={{ fontSize: "32px", color: "#A3001E" }} /></span>
        <span className="logout-text">Sign out</span>
      </div >
    </div >
  )
}

export default Header;