import React from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import './styles.scss'

const Header = (props) => {

  return (
    <div className={`pats-orders-header  ${props.className}`}>
      <div className="back-button">
        <ArrowBackIcon className="back-icon" onClick={props.onBack} sx={{ fontSize: "24px", width: "24px", height: "24px" }} />
      </div>
      <div className="title-section">
        <span className="title">{props.title}</span>
      </div>
    </div>
  )
}

export default Header;