import { PATS_DATE_FORMAT } from "constants"

export const validationRules = {
  firstName: [
    { rule: 'isRequired', message: 'First Name is required' },
  ],
  lastName: [
    { rule: 'isRequired', message: 'Last Name is required' },
  ],
  addressLine1: [
    { rule: 'isRequired', message: 'Address Line 1 Name is required' },
  ],
  city: [
    { rule: 'isRequired', message: 'City is required' },
  ],
  state: [
    { rule: 'isRequired', message: 'State is required' },
  ],
  country: [
    { rule: 'isRequired', message: 'Country is required' },
  ],
  zipCode: [
    { rule: 'isRequired', message: 'Zip Code is required' },
    { rule: 'isZipCode', message: 'Invalid Zip Code is provided' }
  ],
  phoneNumber: [
    { rule: 'isRequired', message: 'Phone Number is required' },
    { rule: 'isPhone', message: 'Invalid Phone Number is provided' },
  ],
  patientDOB: [
    { rule: 'isRequired', message: 'Patient Date Of Birth is required' },
    { rule: 'isValidDate', message: 'Invalid Date is Provided', format: PATS_DATE_FORMAT },
    { rule: 'isFutureDate', message: 'The input date is in the future. Please enter a past date.' }
  ],
  deviceSerialNumber: [
    { rule: 'isValidDeviceSerialNumber', message: 'Incorrect Device Serial Number is provided' }
  ],
  providerFirstName: [
    { rule: 'isRequired', message: 'First Name is required' },
  ],
  providerLastName: [
    { rule: 'isRequired', message: 'Last Name is required' },
  ],
  devicePart: [
    { rule: 'isRequired', message: 'Device Part is required' },
  ],
  reasonForReplacement: [
    { rule: 'isRequired', message: 'Replacement Reason is required' },
  ],
  damageNoticedDate: [
    { rule: 'isRequired', message: 'Damage Noticed Date is required' },
    { rule: 'isValidDate', message: 'Invalid Date is Provided', format: PATS_DATE_FORMAT },
  ],
  reasonComment: [
    { rule: 'isRequired', message: 'Reason Comment is required' },
  ]
}
