import inboxIcon from 'assets/iconImages/inbox.svg'
import inboxIconSelected from 'assets/iconImages/inbox_selected.svg'
import archiveIcon from 'assets/iconImages/archive.svg'
import archiveIconSelected from 'assets/iconImages/archive_selected.svg'

import './dashboard.scss';


export const SORTING_STATUSES = {
  ASC: -1,
  NONE: 0,
  DESC: 1
}

const dataTableHeadersCommon = [{
  id: 1,
  displayName: "Name",
  sortable: true,
  defaultSort: SORTING_STATUSES.NONE,
  key: "fullName",
  classes: "full-name-style"
},
{
  id: 2,
  displayName: "Order #",
  sortable: true,
  defaultSort: SORTING_STATUSES.NONE,
  key: "orderNumber"
},
{
  id: 3,
  displayName: "Part",
  sortable: true,
  defaultSort: SORTING_STATUSES.NONE,
  key: "devicePartName"
}, {
  id: 4,
  displayName: "Date Order Submitted",
  sortable: true,
  defaultSort: SORTING_STATUSES.DESC,
  key: "orderPlacedOn",
  isDateField: true
}]

const dataTableHeadersInbox = [...dataTableHeadersCommon]
const dataTableHeadersArchive = [...dataTableHeadersCommon.map((obj) => {
  if (obj.id === 4) {
    return ({
      ...obj,
      defaultSort: SORTING_STATUSES.NONE,
    })
  }
  return obj
}), {
  displayName: "Date Order Processed",
  sortable: true,
  defaultSort: SORTING_STATUSES.DESC,
  key: "orderProcessedOn"
}]



export const sideBarMenu = [
  {
    id: 1,
    key: "inbox",
    name: "Inbox",
    isDefault: true,
    icon: <img src={inboxIcon} />,
    selectedIcon: <img src={inboxIconSelected} />,
    onClick: () => { },
    tableHeaders: dataTableHeadersInbox
  },
  {
    id: 2,
    key: "archive",
    name: "Archive",
    icon: <img src={archiveIcon} />,
    selectedIcon: <img src={archiveIconSelected} />,
    onClick: () => { },
    tableHeaders: dataTableHeadersArchive
  }
]
