/*
 *
 * ManagePatients reducer
 *
 */

import {
  GET_ALL_PATIENT_FOR_PATIENT_ADVOCATE_REQUEST_SENT,
  GET_ALL_PATIENT_FOR_PATIENT_ADVOCATE_REQUEST_SUCCEED,
  GET_ALL_PATIENT_FOR_PATIENT_ADVOCATE_REQUEST_FAILED,
} from './constants';


const initialState = {
  isRequestInProgress: false,
  patients: [],
  patientAdvocateName: '',
  error: '',
  pagination: {}
};

export const managePatientsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_PATIENT_FOR_PATIENT_ADVOCATE_REQUEST_SENT:
      return {
        ...state,
        isRequestInProgress: true,
        patients: [],
        patientAdvocateName: '',
        error: '',
      };
    case GET_ALL_PATIENT_FOR_PATIENT_ADVOCATE_REQUEST_SUCCEED:
      return {
        ...state,
        isRequestInProgress: false,
        patients: action.data.patients,
        patientAdvocateName: action.data.patientAdvocate,
        pagination: action.data.pagination
      };
    case GET_ALL_PATIENT_FOR_PATIENT_ADVOCATE_REQUEST_FAILED:
      return {
        ...state,
        isRequestInProgress: false,
        error: 'Error',
      };
    default:
      return state;
  }
};
