import { showToaster } from 'common/toasterActions';
import { resendDownloadDataRequestPin, showDownloadDataRequest } from 'services/patient/downloadData';
import { setAuthHeaders } from 'services/base';
import * as StorageService from 'services/storage';
import * as Constants from './constants';

export const verifyPinRequestSent = () => ({
  type: Constants.VERIFY_PIN_REQUEST_SENT,
});

export const verifyPinRequestSucceeded = (data) => ({
  type: Constants.VERIFY_PIN_REQUEST_SUCCEED,
  data,
});

export const verifyPinRequestFailed = (data) => ({
  type: Constants.VERIFY_PIN_REQUEST_FAILED,
  data,
});

export const verifyPinRequest = (requestKey, requestPin) => ((dispatch) => {
  dispatch(verifyPinRequestSent());
  const params = { request_key: requestKey, request_pin: requestPin };
  showDownloadDataRequest(params)
    .then((response) => {
      dispatch(verifyPinRequestSucceeded(response.data));
      StorageService.deleteAuthHeadersFromDeviceStorage();
      StorageService.clearDefaultStorage();
      setAuthHeaders(response.headers);
      StorageService.persistAuthHeadersInDeviceStorage(response.headers);
    })
    .catch((error) => {
      dispatch(verifyPinRequestFailed(error.response && error.response.data ? error.response.data : null));
      dispatch(showToaster({ message: error.response.data.message, type: 'error' }));
    });
});

export const resendPinRequestSent = () => ({
  type: Constants.RESEND_PIN_REQUEST_SENT,
});

export const resendPinRequestSucceeded = (data) => ({
  type: Constants.RESEND_PIN_REQUEST_SUCCEED,
  data,
});

export const resendPinRequestFailed = (data) => ({
  type: Constants.RESEND_PIN_REQUEST_FAILED,
  data,
});

export const resendPinRequest = (requestKey) => ((dispatch) => {
  dispatch(resendPinRequestSent());
  const params = { request_key: requestKey };
  resendDownloadDataRequestPin(params)
    .then((response) => {
      dispatch(resendPinRequestSucceeded(response.data));
      StorageService.deleteAuthHeadersFromDeviceStorage();
      StorageService.clearDefaultStorage();
      setAuthHeaders(response.headers);
      StorageService.persistAuthHeadersInDeviceStorage(response.headers);
    })
    .catch((error) => {
      dispatch(resendPinRequestFailed(error.response && error.response.data ? error.response.data : null));
      dispatch(showToaster({ message: error.response.data.message, type: 'error' }));
    });
});