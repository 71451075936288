import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import validate, { clearErrorsForField } from "common/validator";
import HelpBlock from "components/HelpBlock";
import { locationAndDateFilterValidationConfig } from "./validator";
import "./styles.scss";
import Datetime from "react-datetime";
import axios from "axios";
import {
  GET_HCP_OUTCOMES_LOCATIONS_FILTERS,
  GET_HCP_OUTCOMES_FILTERS,
  GET_HCP_OUTCOMES_PREVIEW_URL,
  DEFAULT_URL,
} from "services/constants";
import "./mobile-style.scss";
import ReviewSelectionPopup from "./ReviewSelectionPopup";
import { getAuthTokens } from "utils/getAuthToken";
import { changeSearchParams } from "utils/changeSearchParams";
import WarningPopup from "./warningPopup";
import { STATE } from 'enums';
import LightboxModal from "components/LightboxModal";
import MobileCustomMultiSelect from "./mobileCustomMultiSelect";
import { showToaster } from "common/toasterActions";
import Strings from "strings"
import ShareReportErrorBoundary from 'containers/ShareReportErrorBoundary';
import moment from "moment-timezone";
import { YYYY_MM_DD, MM_DD_YYYY } from "constants";
import { getAuthValues } from "utils/webContainerAuth";

function areSelectionsEqual(oldSelections, newSelections) {
  return oldSelections.sort().join(",") === newSelections.sort().join(",");
}

function getKeyFromValue(array, value) {
  let result = array.find((item) => item.value === value);
  return result ? result.label : null;
}

export default function ShareHCPOutcomesReportModalMobile(props) {

  const ACCOUNT = "Account";
  const PHYSICIANS = "Physicians";
  const DEVICE_TYPES = "Device Types";
  const INDICATIONS = "Indications";

  const [selectedLocation, setSelectedLocation] = useState([]);
  const [errorsForLocationFilter, setErrorsForLocationFilter] = useState([]);
  const [locations, setLocations] = useState([]);
  const [filters, setFilters] = useState("");
  const [showReviewSelection, setShowReviewSelection] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState({
    physicians: [],
    indications: [],
    startDate: "",
    endDate: "",
    deviceType: [],
  });

  const dispatch = useDispatch();
  const [showLocationChangeWarning, setShowLocationChangeWarning] =useState(false);

  const [tempSelectedLocations, setTempSelectedLocations] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const [showCustomDropdown, setShowCustomDropdown] = useState(false);
  const [showFiltersCustomDropdown, setShowFiltersCustomDropdown] = useState({
    location: false,
    physicians: false,
    indications: false,
    deviceType: false,
  });

  const [dropdownData, setDropdownData] = useState({
    selectedOptions: [],
    allOptions: [],
    currentSelectedField: "",
  });

  useEffect(() => {
    (async function () {
      try {
        setShowLoader(true);
        const { data } = await getAllLocations();
        setLocations(data.locations);
        setShowLoader(false);
      } catch (e) {
        console.log(JSON.stringify(e));
        changeSearchParams(STATE.ERROR, e.message || null);
      }
    })();
  }, []);

  const getAllLocations = () => {
    return axios.get(`${DEFAULT_URL + GET_HCP_OUTCOMES_LOCATIONS_FILTERS}`, { headers: getAuthValues() });
  };

  const getFormattedSelectedFilterData = () => {
    const filtersData = {
      account: selectedLocation.length === locations.length ? ["All"] : getValuesFromId(selectedLocation, locations),
      device_type: selectedFilters.deviceType.length === filters.device_types.length ? ["All"] : getValuesFromId(
        selectedFilters.deviceType,
        filters.device_types
      ),
      physicians: selectedFilters.physicians.length === filters.physicians.length ? ["All"] : getValuesFromId(
        selectedFilters.physicians,
        filters.physicians
      ),
      indications: selectedFilters.indications.length === filters.indications.length ? ["All"] : getValuesFromId(
        selectedFilters.indications,
        filters.indications
      ),
      start_date: selectedFilters.startDate,
      end_date: selectedFilters.endDate,
    };
    return filtersData;
  };

  const getValuesFromId = (selectedValueIds, allValues) => {
    let arr = [];
    selectedValueIds.map((value) => {
      arr.push(getKeyFromValue(allValues, value));
    });
    return arr;
  };

  const sendDownloadReportRequest = () => {
    let params = getFormattedSelectedFilterData();
    const data = {
      parameters: {...params, start_date: moment(selectedFilters.startDate, MM_DD_YYYY).format(YYYY_MM_DD), end_date: moment(selectedFilters.endDate, MM_DD_YYYY).format(YYYY_MM_DD)},
    };

    return axios.post(`${DEFAULT_URL + GET_HCP_OUTCOMES_PREVIEW_URL}`, data, { headers: getAuthValues() });
  };

  const getRemainingFilters = (locations) => {
    return axios.get(`${DEFAULT_URL + GET_HCP_OUTCOMES_FILTERS}`, { params: { location_ids: locations }, headers: getAuthValues() });
  };

  const onLocationFilterFormValidationFailure = (errors) => {
    setErrorsForLocationFilter(errors);
  };

  const onLocationFilterFormValidationSuccess = () => {
    setShowReviewSelection(true);
  };

  const onReviewSelection = (e) => {
    setErrorsForLocationFilter([]);
    validate(
      locationAndDateFilterValidationConfig,
      {
        locationFilter: selectedLocation,
        startDate: selectedFilters.startDate,
        endDate: selectedFilters.endDate,
      },
      onLocationFilterFormValidationFailure,
      onLocationFilterFormValidationSuccess
    );
  };

  const onDateChange = (date, isStartDate) => {
    if (isStartDate) {
      setSelectedFilters({
        ...selectedFilters,
        startDate: date.format("MM-DD-YYYY"),
      });
    } else {
      setSelectedFilters({
        ...selectedFilters,
        endDate: date.format("MM-DD-YYYY"),
      });
    }
  };

  const fetchAllRemainingFilters = async (selectedOption) => {
    try {
      setShowLoader(true);
      const { data } = await getRemainingFilters(selectedOption);
      setFilters(data);
      setSelectedFilters({
        physicians: [],
        indications: [],
        startDate: "",
        endDate: "",
        deviceType: [],
      });
    } catch (error) {
      console.log(JSON.stringify(error));
      changeSearchParams(STATE.ERROR, error.message || null);
    } finally {
      setShowLoader(false);
    }
  };

  const downloadReport = async () => {
    setShowLoader(true);
    try {
      const { data } = await sendDownloadReportRequest();
      setShowLoader(false);
      dispatch(showToaster({ message: data.message }));
    } 
    catch (error) {
      console.log(JSON.stringify(error));
      changeSearchParams(STATE.ERROR, error.message || null);
    }
  };

  const backButtonClicked = () => {
    if (showCustomDropdown) {
      setShowCustomDropdown(false);
      setShowFiltersCustomDropdown({
        ...showFiltersCustomDropdown,
        physicians: false,
        indications: false,
        deviceType: false,
        location: false,
      });
    } else {
      changeSearchParams(STATE.COMPLETED);
    }
  };

  const clearAllFilters = () => {
    setSelectedLocation(tempSelectedLocations);
    setShowLocationChangeWarning(false);
    if(tempSelectedLocations) fetchAllRemainingFilters(tempSelectedLocations);
  };

  const keepAllFilters = () => {
    setShowLocationChangeWarning(false);
  };

  const onAccountConfirmSelection = (options) => {
    if (options.length > 0) {
      setErrorsForLocationFilter([]);
    }
    if ((selectedLocation.length === 0 && options.length > 0) || 
        (!areSelectionsEqual(selectedLocation, options) && areOtherFiltersEmpty())) {
      setSelectedLocation(options);
      fetchAllRemainingFilters(options);
    } else if (!areSelectionsEqual(options, selectedLocation) && !areOtherFiltersEmpty()) {
      setTempSelectedLocations(options);
      setShowLocationChangeWarning(true);
    }
  };

  const onDeviceTypeConfirmSelection = (options) => {
    setSelectedFilters({ ...selectedFilters, deviceType: options });
  };

  const onIndicationConfirmSelection = (options) => {
    setSelectedFilters({ ...selectedFilters, indications: options });
  };

  const onReferringPhysicianConfirmSelection = (options) => {
    setSelectedFilters({ ...selectedFilters, physicians: options });
  };

  const clearSpecificFilter = (filterName) => {
    if (filterName === 'selectedLocation' && areOtherFiltersEmpty() ) {
      setTempSelectedLocations([]);
      setSelectedLocation([]);
    }
    else if(filterName === 'selectedLocation' && !areOtherFiltersEmpty()) {
      setTempSelectedLocations([]);
      setShowLocationChangeWarning(true);
    }
    else {
      setSelectedFilters({ ...selectedFilters, [filterName]: [] });
    }
  }

  const datePickerOpened = (event) => {
    let dropdownElement = document.getElementById(event.target.id).nextSibling;

    if (dropdownElement.getBoundingClientRect().bottom > window.innerHeight) {
      dropdownElement.style.bottom = "100%";
    }
  }

  const datePickerClosed = (id) => {
    let dropdownElement = document.getElementById(id) ? document.getElementById(id).nextSibling : "";
    dropdownElement && dropdownElement.style.removeProperty('bottom');
  }
 
  const areOtherFiltersEmpty = () => {
    return (selectedFilters.deviceType.length === 0 && selectedFilters.physicians.length === 0 && selectedFilters.indications.length === 0)
  }
  
  const fallbackUI = () => {
    return (
      <div className="share-report-mobile-fallback-wrapper">
        <div className="share-report-mobile-fallback-wrapper__error-text">
          <h4>Error</h4>
          <h4>Something Went Wrong...</h4>
        </div>
      </div>
    )
  }

  const onDropdownClicked = (event = null) => {
    setShowCustomDropdown(true);
    let selectedOptions;
    let allOptions;
    let currentSelectedField;

    switch (event.target.name) {
      case ACCOUNT:
        selectedOptions = selectedLocation
        allOptions = locations ?? []
        currentSelectedField = ACCOUNT
        break;
    
      case PHYSICIANS:
        selectedOptions = selectedFilters.physicians
        allOptions = filters ? filters.physicians ?? [] : []
        currentSelectedField = PHYSICIANS
        break;

      case DEVICE_TYPES:
        selectedOptions = selectedFilters.deviceType
        allOptions = filters ? filters.device_types ?? [] : []
        currentSelectedField = DEVICE_TYPES
        break;

      case INDICATIONS:
        selectedOptions = selectedFilters.indications
        allOptions = filters ? filters.indications ?? [] : []
        currentSelectedField = INDICATIONS
        break; 

      default:
        selectedOptions = []
        allOptions = []
        currentSelectedField = ""
        break;
    }
    setDropdownData({
      selectedOptions: selectedOptions,
      allOptions: allOptions,
      currentSelectedField: currentSelectedField,
    })
  }

  const updateSelectedOptions = (selectedOptions, fieldName) => {

    switch (fieldName) {
      case ACCOUNT:
        onAccountConfirmSelection(selectedOptions);
        break;
      case DEVICE_TYPES:
        onDeviceTypeConfirmSelection(selectedOptions);
        break;
      case INDICATIONS:
        onIndicationConfirmSelection(selectedOptions);
        break;
      case PHYSICIANS:
        onReferringPhysicianConfirmSelection(selectedOptions)
        break;
      default:
        break;
    }
  }
  
  // This config is only for Optional filters.
  const textfieldConfig = [
    {
      label: "Device Types",
      placeholder_value: selectedFilters.deviceType,
      clearFilterKey: "deviceType",
    },
    {
      label: "Indications",
      placeholder_value: selectedFilters.indications,
      clearFilterKey: "indications",
    },
    {
      label: "Physicians",
      placeholder_value: selectedFilters.physicians,
      clearFilterKey: "physicians",
    },
  ];

  const computePlaceholderValue = (selectedFilterValues, filterTitle) => {
    if(selectedFilterValues.length > 0) {
      return `${selectedFilterValues.length} Selected`
    }
    else {
      return `Select ${filterTitle}`
    }
  }
  
  return (
    <ShareReportErrorBoundary fallback={fallbackUI()} isLoading={showLoader}>
    <>
      <WarningPopup
        clearAllFilters={clearAllFilters}
        keepAllFilters={keepAllFilters}
        show={showLocationChangeWarning}
      />
      <div className='mobile-navigation-bar'>
        <span
          onClick={backButtonClicked}
          className="icon icon-font-a-left-chevron"
        />
        <h5>Clinical Overview Report</h5>
        <span></span>
      </div>

      <div className='outcomes-sharing-form-wrapper'>
        <div className="report-recipients-wrapper">
          <div className="row filters-container">
            <div
              className={`custom-dropdown-wrapper ${
                showCustomDropdown ? "hide" : ""
              }`}
            >
              <div className="custom-dropdown-wrapper-filters">
                <div
                  className={`field-control ${
                    errorsForLocationFilter.locationFilter ? "has-error" : ""
                  }`}
                >
                  <label className="field-label">
                    Account <sup className="app-red">*</sup>
                  </label>
                  <input
                    type="text"
                    name="Account"
                    className="field-control__input"
                    id="input-control"
                    placeholder={`${
                      selectedLocation.length > 0
                        ? `${selectedLocation.length} Selected`
                        : `Select Accounts`
                    }`}
                    onClick={(event) => {
                      onDropdownClicked(event);
                    }}
                  />
                  <span className="icon icon-font-a-clear-all" onClick={() => clearSpecificFilter("selectedLocation")}></span>
                  <span role="button" tabIndex="0" className='icon icon-font-a-right-chevron chevron-gray-color cursor-pointer'></span>
                  <div
                    className={`selected-accounts-list-wrapper ${ selectedLocation.length > 0 ? "" : "hide" }`}>
                    { selectedLocation.length > 0 && (
                        <>
                          <h5 className="selected-accounts-header">
                            Selected Accounts
                          </h5>
                          <div className="selected-accounts-list">
                            {selectedLocation.map((location, index) => {
                              return index < 4 ? (
                                <p key={index} className="selected-account-list-item">
                                  {getKeyFromValue(locations, location)}
                                </p>
                              ) : (
                                ""
                              );
                            })}
                            <p>
                              {selectedLocation.length > 4 && (
                                <span className="selected-accounts-list-more-items">
                                  + {selectedLocation.length - 4} more
                                </span>
                              )}
                            </p>
                          </div>
                        </>
                    )}
                  </div>
                  <HelpBlock value={errorsForLocationFilter.locationFilter} />
                </div>
                {
                  textfieldConfig.map((config, index) => {
                    return (
                      <div className={`field-control ${ selectedLocation.length > 0 ? "" : "hide"}`} key={config.label + index}>
                        <label className="field-label">{config.label}</label>
                        <input
                          type="text"
                          name={config.label}
                          className="field-control__input"
                          id="input-control"
                          placeholder={computePlaceholderValue(config.placeholder_value, config.label)}
                          onClick={(event) => {
                            onDropdownClicked(event);
                          }}
                        />
                        <span className="icon icon-font-a-clear-all" onClick={() => clearSpecificFilter(config.clearFilterKey)}></span>
                        <span role="button" tabIndex="0" className='icon icon-font-a-right-chevron chevron-gray-color cursor-pointer'></span>
                        <span className="help-block-info">{Strings.DEFAULT_ALL_FILTERS_SELECTED.replace('%filterName', config.label)}</span>
                      </div>
                    )
                  })
                }
                <div
                  className={`start-date-filter report-filter report-date-picker ${
                    errorsForLocationFilter.startDate ? "has-error" : ""
                  }`}
                >
                  <label htmlFor="startDate" className="field-label">
                    Start Date <sup className="app-red">*</sup>
                  </label>
                  <Datetime
                    onFocus={datePickerOpened}
                    onBlur={datePickerClosed("start-date")}
                    timeFormat={false}
                    className="date-picker-field float-left date-field form-control"
                    inputProps={{
                      placeholder: "Start Date",
                      readOnly: true,
                      id:"start-date"
                    }}
                    closeOnSelect
                    closeOnTab
                    onChange={(date) => {
                      onDateChange(date, true);
                      clearErrorsForField(errorsForLocationFilter, "startDate");
                    }}
                    value={selectedFilters.startDate}
                  />
                  <HelpBlock value={errorsForLocationFilter.startDate} />
                </div>
                <div
                  className={`end-date-filter report-filter report-date-picker ${
                    errorsForLocationFilter.endDate ? "has-error" : ""
                  }`}
                >
                  <label htmlFor="endDate" className="field-label">
                    End Date <sup className="app-red">*</sup>
                  </label>
                  <Datetime
                    onFocus={datePickerOpened}
                    onBlur={datePickerClosed(("end-date"))}
                    timeFormat={false}
                    className="date-picker-field float-left date-field form-control"
                    inputProps={{
                      placeholder: "End Date",
                      readOnly: true,
                      id:"end-date"
                    }}
                    closeOnSelect
                    closeOnTab
                    onChange={(date) => {
                      onDateChange(date, false);
                      clearErrorsForField(errorsForLocationFilter, "endDate");
                    }}
                    value={selectedFilters.endDate}
                  />
                  <HelpBlock value={errorsForLocationFilter.endDate} />
                </div>
              </div>
              <div className="report-modal-button-wrapper">
                <button className="report-modal-button review-selection-button" onClick={onReviewSelection}>
                  Review Selection
                </button>
              </div>
            </div>
            <MobileCustomMultiSelect
              onConfirmSelection={(options) => {
                setShowCustomDropdown(false);
                updateSelectedOptions(options, dropdownData.currentSelectedField);
              }}
              options={dropdownData.allOptions}
              selectedOptions={dropdownData.selectedOptions}
              showOptions={showCustomDropdown}
              fieldName={dropdownData.currentSelectedField}
            />
          </div>
        </div>
      </div>

      {showReviewSelection && (
        <ReviewSelectionPopup
          selectedFilterValues={getFormattedSelectedFilterData()}
          onDownloadReportClicked={downloadReport}
          onClose={() => {setShowReviewSelection(false)}}
        />
      )}
    </>
    </ShareReportErrorBoundary>
  );
}
