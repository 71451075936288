import { searchQuestionsService } from 'services/patient/questions';
import * as Constants from './constants';

export const setProcedureDetails = (data) => ({
  type: Constants.SET_PROCEDURES_DETAILS,
  data,
});

export const resetProcedureDetails = () => ({
  type: Constants.RESET_PROCEDURES_DETAILS,
});

export const searchQuestionRequestSent = (isScrolled) => ({
  type: Constants.SEARCH_QUESTION_REQUEST_SENT,
  isScrolled,
});

export const searchQuestionRequestSucceeded = (data, isScrolled) => {
  const payload = {
    data, isScrolled,
  };
  return {
    type: Constants.SEARCH_QUESTION_REQUEST_SUCCEED,
    payload,
  };
};

export const searchQuestionRequestFailed = () => ({
  type: Constants.SEARCH_QUESTION_REQUEST_FAILED,
});

export const hideSuggestionDropdown = () => ({
  type: Constants.HIDE_SUGGESTIONS_DROPDOWN,
});

export const clearSearchResults = () => ({
  type: Constants.SEARCH_QUESTION_CLEAR_RESULT,
});


// //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Redux Thunk actions:
// //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export const searchQuestionsRequest = (params, isScrolled) => (dispatch) => {
  dispatch(searchQuestionRequestSent(isScrolled));
  searchQuestionsService(params)
    .then((response) => {
      dispatch(searchQuestionRequestSucceeded(response.data, isScrolled));
    })
    .catch(() => {
      dispatch(searchQuestionRequestFailed());
    });
};

export const clearSearchResultsRequest = () => (dispatch) => {
  dispatch(clearSearchResults());
};
