import React from "react";
import PatsBodyWrapper from "../Components/PatsOrderBodyWrapper";
import MobileInfoBlock from "components/MobileComponents/MobileInfoBlock";
import HelpBlock from "components/HelpBlock";

const DevicePart = (props) => {
  const { title, devicePartOptions, devicePartData, moveForward, validateData } = props;

  const getBody = () => {
    return (
      <div className="device-part-wrapper">

        <div className="device-part-info">
          <div className="heading-font required">Select a device part</div>
          <MobileInfoBlock
            text={<span>
              Don’t see the device part you need? Please contact our Patient Services team at{' '}
              <a href="tel:+1-8005106735" className="pats-team-contact-text__number">
                1-800-510-6735
              </a>
            </span>}
          />
        </div>

        <div className="device-part-tiles">
          {devicePartOptions.map((part) => (
            <div className="device-part-tile" key={part.value} onClick={() => {
              devicePartData.setDevicePart(part.value)
            }}>
              <img
                src={part.image_url}
                className={`image ${devicePartData.devicePart === part.value ? 'selected' : ''}`}
                alt={part.display_name} />
              <span
                className={`${devicePartData.devicePart === part.value ? 'selected-display-name' : 'display-name'}`}>
                {part.display_name}
              </span>
            </div>
          ))}
        </div>
        <HelpBlock value={devicePartData.error} className="no-part-selected-error"></HelpBlock>

      </div>
    )
  };

  return (
    <PatsBodyWrapper
      title={<h4 className="title-font">{title}</h4>}
      body={getBody()}
      buttons={[
        <button
          disabled={!devicePartData.devicePart}
          className="pats-btn-primary"
          onClick={() => validateData({ devicePart: devicePartData.devicePart }, moveForward)}
        >
          Next: Lost/Damaged Details
        </button>,
      ]}
    />
  );
};
export default DevicePart;
