import { showToaster } from 'common/toasterActions';
import { resendShareOutcomesRequestPin, showShareOutcomesRequest, downloadShareOutcomesReport, showHcpShareOutcomesRequest, resendHcpShareOutcomesRequestPin } from 'services/patient/shareOutcomes';
import { setAuthHeaders } from 'services/base';
import * as StorageService from 'services/storage';
import * as Constants from './constants';

export const verifyPinRequestSent = () => ({
  type: Constants.VERIFY_PIN_REQUEST_SENT,
});

export const verifyPinRequestSucceeded = (data) => ({
  type: Constants.VERIFY_PIN_REQUEST_SUCCEED,
  data,
});

export const verifyPinRequestFailed = (data) => ({
  type: Constants.VERIFY_PIN_REQUEST_FAILED,
  data,
});

export const verifyPinRequest = (requestKey, requestPin) => ((dispatch) => {
  dispatch(verifyPinRequestSent());
  const params = { request_key: requestKey, request_pin: requestPin };
  showShareOutcomesRequest(params)
    .then((response) => {
      dispatch(verifyPinRequestSucceeded(response.data));
    })
    .catch((error) => {
      dispatch(verifyPinRequestFailed(error.response && error.response.data ? error.response.data : null));
      dispatch(showToaster({ message: error.response.data.message, type: 'error' }));
    });
});

export const downloadShareOutcomesReportFileRequestSent = () => ({
  type: Constants.DOWNLOAD_SHARE_OUTCOMES_REPORT_FILE_REQUEST_SENT,
});

export const downloadShareOutcomesReportFileRequestSucceeded = (data) => ({
  type: Constants.DOWNLOAD_SHARE_OUTCOMES_REPORT_FILE_REQUEST_SUCCEED,
  data,
});

export const downloadShareOutcomesReportFileRequestFailed = (data) => ({
  type: Constants.DOWNLOAD_SHARE_OUTCOMES_REPORT_FILE_REQUEST_FAILED,
  data,
});

export const downloadShareOutcomesReportFileRequest = (requestKey, requestPin) => ((dispatch) => {
  dispatch(downloadShareOutcomesReportFileRequestSent());
  const params = { request_key: requestKey, request_pin: requestPin };
  downloadShareOutcomesReport(params)
    .then((response) => {
      dispatch(downloadShareOutcomesReportFileRequestSucceeded(response.data));
    })
    .catch((error) => {
      dispatch(downloadShareOutcomesReportFileRequestFailed(error.response && error.response.data ? error.response.data : null));
      dispatch(showToaster({ message: error.response.data.message, type: 'error' }));
    });
});

export const resendPinRequestSent = () => ({
  type: Constants.RESEND_PIN_REQUEST_SENT,
});

export const resendPinRequestSucceeded = (data) => ({
  type: Constants.RESEND_PIN_REQUEST_SUCCEED,
  data,
});

export const resendPinRequestFailed = (data) => ({
  type: Constants.RESEND_PIN_REQUEST_FAILED,
  data,
});

export const resendPinRequest = (requestKey) => ((dispatch) => {
  dispatch(resendPinRequestSent());
  const params = { request_key: requestKey };
  resendShareOutcomesRequestPin(params)
    .then((response) => {
      dispatch(resendPinRequestSucceeded(response.data));
      StorageService.deleteAuthHeadersFromDeviceStorage();
      StorageService.clearDefaultStorage();
      setAuthHeaders(response.headers);
      StorageService.persistAuthHeadersInDeviceStorage(response.headers);
    })
    .catch((error) => {
      dispatch(resendPinRequestFailed(error.response && error.response.data ? error.response.data : null));
      dispatch(showToaster({ message: error.response.data.message, type: 'error' }));
    });
});

export const verifyPinForHcpRequestSent = () => ({
  type: Constants.VERIFY_PIN_REQUEST_FOR_HCP_OUTCOME_SENT,
});

export const verifyPinForHcpRequestSucceeded = (data) => ({
  type: Constants.VERIFY_PIN_REQUEST_FOR_HCP_OUTCOME_SUCCEED,
  data,
});

export const verifyPinForHcpRequestFailed = (data) => ({
  type: Constants.VERIFY_PIN_REQUEST_FOR_HCP_OUTCOME_FAILED,
  data,
});

export const verifyPinForHcpRequest = (requestKey, requestPin) => ((dispatch) => {
  dispatch(verifyPinForHcpRequestSent());
  const params = { request_key: requestKey, request_pin: requestPin };
  showHcpShareOutcomesRequest(params)
    .then((response) => {
      dispatch(verifyPinForHcpRequestSucceeded(response));
    })
    .catch((error) => {
      dispatch(verifyPinForHcpRequestFailed(error.response && error.response.data ? error.response.data : null));
      dispatch(showToaster({ message: error.response.data.message, type: 'error' }));
    });
});

export const resendPinForHcpRequestSent = () => ({
  type: Constants.RESEND_PIN_REQUEST_FOR_HCP_OUTCOME_SENT,
});

export const resendPinForHcpRequestSucceeded = (data) => ({
  type: Constants.RESEND_PIN_REQUEST_FOR_HCP_OUTCOME_SUCCEED,
  data,
});

export const resendPinForHcpRequestFailed = (data) => ({
  type: Constants.RESEND_PIN_REQUEST_FOR_HCP_OUTCOME_FAILED,
  data,
});

export const resendPinForHcpRequest = (requestKey) => ((dispatch) => {
  dispatch(resendPinForHcpRequestSent());
  const params = { request_key: requestKey };
  resendHcpShareOutcomesRequestPin(params)
    .then((response) => {
      dispatch(resendPinForHcpRequestSucceeded(response.data));
      dispatch(showToaster({ message:  response.data.message}));
    })
    .catch((error) => {
      dispatch(resendPinForHcpRequestFailed(error.response && error.response.data ? error.response.data : null));
      dispatch(showToaster({ message: error.response.data.message, type: 'error' }));
    });
});