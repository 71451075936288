import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import DataTable from 'patientOrderSupport/components/DataTable';
import CollapsingSideBar from 'patientOrderSupport/components/CollapsingSideBar';
import withPatientOrderSupportComponent from 'services/patientordersupport/withPatientOrderSupportComponent'
import { dataTableHeadersInbox, dataTableHeadersArchive, sideBarMenu } from './configs';
import LightboxModal from 'components/LightboxModal';
import OrderArchiveModal from './components/OrderArchiveModal';
import noOrdersImage from 'assets/images/no_survey.png'
import Emptyview from 'components/Emptyview';
import usePatientSupportDashboardViewModel from './viewModel';
import { getUnreadNotificationsCount } from 'containers/NavNotificationMenu/actions';
import './dashboard.scss';
import { useDispatch } from 'react-redux';

const PatientDeviceSupport = () => {

  const { activeTab,
    updateActiveTabData,
    data,
    pagination,
    isLoading,
    showArchiveModal,
    emptyDatMessage,
    handlePagination,
    onArchiveModalHide } = usePatientSupportDashboardViewModel();

  const location = useLocation();
  const locSearch = new URLSearchParams(location.search);
  const dispatch = useDispatch();

  useEffect(() => {
    const refreshInterval = setInterval(() => {
      dispatch(getUnreadNotificationsCount());
    }, 30000);
    return () => {
      clearInterval(refreshInterval)
    }
  }, []);

  return (
    <div className="dashboard-wrapper">
      <CollapsingSideBar menu={sideBarMenu} barStatusChangedCallBack={(status) => setBarOpenStatus(status)} activeTab={activeTab.activeTabId} updateActiveTabData={updateActiveTabData} />
      <DataTable defaultPage="1" emptyDataMessage={emptyDatMessage} dataTableHeaders={activeTab.activeTabTableHeaders} data={data} pagination={pagination} handlePagination={handlePagination} />
      <LightboxModal show={isLoading} />
      <OrderArchiveModal show={showArchiveModal} title="Order Details" onHide={onArchiveModalHide} />
    </div>
  )
}

export default withPatientOrderSupportComponent({ component: PatientDeviceSupport, isHeaderNeeded: true })