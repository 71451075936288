import React from 'react';
import PropTypes from 'prop-types';

import '../patientRegister.scss';
import FloatingPhoneInput from 'components/FloatingPhoneInput';
import NextButton from 'containers/PatientRegister/components/NextButton';
import BackButton from 'containers/PatientRegister/components/BackButton';
import HeroImage from 'assets/images/patient-register-hero.png';

export default function ContactView(props) {
  const handlePhoneKeyPress = (e) => {
    if (e.key === 'Enter') {
      props.onNext();
    }
  };

  return (
    <div className="contact-view-container">
      <div className="image-container form-hero">
        <img src={HeroImage} alt="Patient Register Hero" />
      </div>
      <div className="row patient-register-section contents-container text-align-center">
        <div className="col-xs-10 col-xs-offset-1">
          <h2>
            Thank you,
            {' '}
            {props.firstName}
            !
          </h2>
          <p>Again, CareGuidePro&trade; is a free app for potential spinal cord stimulation (SCS) patients. You will have access to educational resources so learn about SCS and message a Medtronic Pain Management Specialist directly to get all your questions answered.</p>
          <p>What mobile number would you like to use to log in to the app?</p>
          <strong className="contact-info">Your number will not be shared with any third parties.</strong>
        </div>
        <div className="clearfix"></div>
        <div className="col-xs-12 col-md-6 col-md-offset-3 phone-container">
          <div
            className={`form-group ${props.errors.phone !== undefined ? 'has-error' : ''}`}
          >
            <FloatingPhoneInput
              type="tel"
              name="phone"
              className="form-control"
              value={props.phone}
              label="Mobile Number"
              onChange={props.onChange}
              onKeyPress={handlePhoneKeyPress}
            />
          </div>
          <div>
            <NextButton onNext={props.onNext} />
            <BackButton onBack={props.onBack} />
          </div>
        </div>
        <div className="clearfix"></div>
      </div>
    </div>
  );
}

ContactView.propTypes = {
  firstName: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
  errors: PropTypes.object,
  phone: PropTypes.string,
};

ContactView.defaultProps = {
  errors: {},
  phone: '',
};
