import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import queryString from 'query-string';
import PassiveModeFooter from 'patientApp/components/PassiveModeFooter';
import SetPassword from '../../../assets/images/passive_mode_set_password.png';
import './styles.scss';

class PassiveModeInfo extends Component {// eslint-disable-line react/prefer-stateless-function
  redirectToSignIn = () => {
    const { history, redirectUri, emailId } = this.props;
    history.replace(`/signin?redirect_uri=${redirectUri}`, { emailId });
  }

  render() {
    const { userInfo } = this.props;
    return (
      <div className="passive-mode-info-containter">
        <div className="welcome-header">
          <span className="">Welcome Back, &nbsp;</span>
          {
            userInfo && <span style={{ display: 'inline-block' }}>{userInfo.firstName}</span>
          }
        </div>
        <div className="padding-20">
          <img src={SetPassword} alt="password" />
        </div>
        <div className="text-info">
          We have just sent you a text message containing a temporary password to your registered phone number.
          <br />
          Go to Sign in once you receive the temporary password.
        </div>
        <button className="btn btn-primary" onClick={this.redirectToSignIn}>Sign in</button>
        <PassiveModeFooter />
      </div>
    );
  }
}


PassiveModeInfo.propTypes = {
  userInfo: PropTypes.object,
  emailId: PropTypes.string,
  history: PropTypes.object,
  redirectUri: PropTypes.string,
};
const mapStateToProps = (state, ownProps) => ({
  userInfo: state.passiveMode.metadata.userInfo,
  emailId: ownProps.location.state.emailId ? ownProps.location.state.emailId : null,
  redirectUri: ownProps.location.search ? queryString.parse(ownProps.location.search).redirect_uri : null,
});

export default connect(mapStateToProps, null)(PassiveModeInfo);
