import React, { useState, useEffect } from 'react';
import arrowDownUp from 'assets/images/arrows_down_up.svg';
import arrowDown from 'assets/iconImages/arrow_down.svg';
import arrowUp from 'assets/iconImages/arrow_up.svg';
import { generateUUID, sortData } from 'utils/common';
import { SORTING_STATUSES } from 'patientOrderSupport/containers/Dashboard/configs';

const useDataTableViewModel = (props) => {
  const [sortingStatus, setSortingStatus] = useState({});
  const [page, setPage] = useState(props.defaultPage || 1);
  const [data, setData] = useState(props.data);

  const getSortIconImage = (status) => {
    switch (status) {
      case SORTING_STATUSES.DESC:
        return arrowDown
      case SORTING_STATUSES.ASC:
        return arrowUp
      case SORTING_STATUSES.NONE:
        return arrowDownUp
      default:
        return null
    }
  }

  const getTheSortingPath = (currentStatus) => {
    switch (currentStatus) {
      case SORTING_STATUSES.DESC:
        return SORTING_STATUSES.ASC
      case SORTING_STATUSES.ASC:
        return SORTING_STATUSES.DESC
      case SORTING_STATUSES.NONE:
        return SORTING_STATUSES.ASC
      default:
        return SORTING_STATUSES.ASC
    }
  }

  const sortTheData = (data) => {
    let dataToSort = data;
    Object.keys(sortingStatus).forEach((key) => {
      if (sortingStatus[key] !== SORTING_STATUSES.NONE) {
        let isDateField = props.dataTableHeaders.find((header) => header.key === key)?.isDateField
        dataToSort = sortData(dataToSort, key, sortingStatus[key], isDateField)
      }
    })
    return dataToSort;
  }

  const updateTheSortingStatuses = (header) => {
    let updatedStatuses = {}
    Object.keys(sortingStatus).forEach((key) => {
      if (key === header.key) {
        updatedStatuses = { ...updatedStatuses, [key]: getTheSortingPath(sortingStatus[header.key]) }
      }
      else {
        updatedStatuses = { ...updatedStatuses, [key]: SORTING_STATUSES.NONE }
      }
    })
    return updatedStatuses;
  }

  const handlePagination = (page) => {
    setPage(page);
    props.handlePagination(page);
  }

  useEffect(() => {
    setData(sortTheData(props.data));
  }, [props.data])

  useEffect(() => {
    let sortingStatus = {}
    props.dataTableHeaders.map((header) => {
      if (header.sortable) {
        sortingStatus = { ...sortingStatus, [header.key]: header.defaultSort }
      }
    })
    setSortingStatus(sortingStatus)
  }, [props.dataTableHeaders])

  useEffect(() => {
    if (Object.keys(sortingStatus).length) {
      setData(sortTheData(data));
    }
  }, [sortingStatus])



  const getHeaderColumn = (header) => {
    return <th className={`${sortingStatus[header.key] !== SORTING_STATUSES.NONE ? 'sorted-header' : ''}`} key={generateUUID()} onClick={() => {
      setSortingStatus(updateTheSortingStatuses(header))
    }
    }>{header.displayName} <img src={getSortIconImage(sortingStatus[header.key])}
    ></img></th >
  }


  return ({
    dataTableHeaders: props.dataTableHeaders,
    data: data,
    pagination: props.pagination,
    handlePagination: props.handlePagination,
    getHeaderColumn,
    handlePagination
  })
}

export default useDataTableViewModel;