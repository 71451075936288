export default (state) => (
  (state.appointments && state.appointments.appointments.isLoading) ||
  (state.appointments && state.appointments.appointments.isEventRequestInProgress) ||
  (state.tasks && state.tasks.isLoading) ||
  (state.activeProcedureDetails && state.activeProcedureDetails.isLoading) ||
  (state.patientProcedures && state.patientProcedures.isLoading) ||
  (state.tasks && state.tasks.isLoading) ||
  (state.tasks && state.tasks.isDeleteRequestInProgress) ||
  (state.tasks && state.tasks.isMarkTaskCompleteRequestInProgress) ||
  (state.questions && state.questions.faqs.isLoading) ||
  (state.questions && state.questions.myQuestions.isLoading) ||
  (state.caregivers && state.caregivers.caregiverReducer.isRequestInProgress)
);
