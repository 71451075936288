import React, { PureComponent } from 'react';
import './style.scss';

class PassiveModeFooter extends PureComponent {// eslint-disable-line react/prefer-stateless-function
  render() {
    const supportEmail = process.env.REACT_APP_SUPPORT_EMAIL;
    return (
      <div className="passive-mode-footer">
        <div className="title">The CareGuidePro Team</div>
        <div className="subtitle">Questions and technical support:</div>
        <div>
          <a href="tel:1-866-704-4447">1-866-704-4447</a>
          &nbsp;|&nbsp;
          <a href={`mailto:${supportEmail}`}>{supportEmail}</a>
        </div>
      </div>
    );
  }
}


export default PassiveModeFooter;
