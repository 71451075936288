import React from "react";
import PatsBodyWrapper from "../Components/PatsOrderBodyWrapper";
import MobileInfoBlock from "components/MobileComponents/MobileInfoBlock";
import MobileTextField from "components/MobileComponents/MobileTextField";
import { SPECIAL_CHARS_TO_SKIP_FOR_NAME } from "constants";

const DeviceType = (props) => {
  const { title, deviceTypeData, providerNameData, moveForward, validateData } = props;
  const { firstName, lastName } = providerNameData;
  const { providerFirstName, setProviderFirstName } = firstName;
  const { providerLastName, setProviderLastName } = lastName;

  const getBody = () => {
    return (
      <div className="device-type-wrapper">

        <div className="device-details">
          <div className="device-info">
            <div className="heading-font required">Device Type</div>
            <div className="content-font-1">{deviceTypeData.deviceType}</div>
          </div>
          <MobileInfoBlock
            text={

              <span>
                Incorrect or missing device type? Please contact our Patient Services team at{' '}
                <a href="tel:+1-8005106735" className="pats-team-contact-text__number">
                  1-800-510-6735
                </a>.
              </span>}
          />
        </div>

        <div className="provider-name-wrapper">
          <div className="heading-font required">Provider</div>
          <div className="provider-name-fields">
            <MobileTextField
              specialCharsToSkip={SPECIAL_CHARS_TO_SKIP_FOR_NAME}
              placeholder="First Name"
              maxLength={100}
              error={firstName.error}
              value={providerFirstName}
              onChange={setProviderFirstName} />

            <MobileTextField
              specialCharsToSkip={SPECIAL_CHARS_TO_SKIP_FOR_NAME}
              placeholder="Last Name"
              error={lastName.error}
              maxLength={100}
              value={providerLastName}
              onChange={setProviderLastName} />
          </div>
          <MobileInfoBlock style={{ marginTop: "8px" }} text="Your provider is the physician who manages your implanted device." />
        </div>

      </div >
    );
  };

  return (
    <PatsBodyWrapper
      title={<h4 className="title-font">{title}</h4>}
      body={getBody()}
      buttons={[
        <button
          disabled={!providerFirstName?.trim() || !providerLastName?.trim}
          className="pats-btn-primary"
          onClick={() => validateData({ providerFirstName, providerLastName }, moveForward)}
        >
          Next: Choose Part
        </button>,
      ]}
    />
  );
};

export default DeviceType;