
export default {
  control: (base) => ({
    ...base,
    minHeight: 34,
  }),
  dropdownIndicator: (base) => ({
    ...base,
    padding: 4,
  }),
  clearIndicator: (base) => ({
    ...base,
    padding: 4,
  }),
  valueContainer: (base) => ({
    ...base,
    padding: '0px 6px',
  }),
  multiValue: (base) => ({
    ...base,
    border: '1px solid  #4A90E2',
    backgroundColor: '#fff',
    borderRadius: '20px',
    color: '#00B2D0',
  }),
  input: (base) => ({
    ...base,
    margin: 0,
    padding: 0,
  }),
};
