import { TextareaAutosize } from '@mui/base/TextareaAutosize';
import { FormHelperText } from '@mui/material';
import { makeStyles } from '@mui/styles';
import './styles.scss'

const MobileTextAreaField = (props) => {

  const useStyles = makeStyles({
    root: {
      "& .MuiOutlinedInput-input": {
        fontSize: "14px !important"

      }
    }
  });

  const classes = useStyles();

  return (
    <div className="text-area-field-wrapper">
      <div className="input-label">{props.overHeadLabel}</div>
      <TextareaAutosize error={props.error} sx={{ ...props.sx }} maxLength={props.maxLength} id="outlined-basic" className={classes.root}
        placeholder={props.placeholder}
        value={props.value ? props.value : ""} onChange={(e) => {
          if (e.target.value?.length <= props.maxLength)
            props.onChange(e.target.value)
        }} variant="outlined" />
      <div className="character-limit-text"><span>Character limit</span><span>{props.value ? props.value.length : 0}/{props.maxLength}</span></div>
      <FormHelperText sx={{ fontSize: "1.2rem", color: "#d32f2f" }}>{props.error}</FormHelperText>
    </div>
  );
}

export default MobileTextAreaField;
